/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SDWPermissions } from '../models/SDWPermissions';
import type { SDWSubscriptionPermissions } from '../models/SDWSubscriptionPermissions';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SdwOrderPermissionsControllerService {

    /**
     * Get SDW order permissions for user
     * @param scopeId
     * @returns SDWPermissions Successful request
     * @throws ApiError
     */
    public static getSdwUserPermissions(
        scopeId: string,
    ): CancelablePromise<SDWPermissions> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/admin/{scopeId}',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal server error`,
                503: `SDW service unavailable`,
            },
        });
    }

    /**
     * Get SDW order permissions for a user and subscription
     * @param scopeId
     * @param subscriptionId
     * @returns SDWSubscriptionPermissions Successful request
     * @throws ApiError
     */
    public static getSdwUserPermissionsOnSubscription(
        scopeId: string,
        subscriptionId: number,
    ): CancelablePromise<SDWSubscriptionPermissions> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/admin/{scopeId}/{subscriptionId}',
            path: {
                'scopeId': scopeId,
                'subscriptionId': subscriptionId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal server error`,
                503: `SDW service unavailable`,
            },
        });
    }

}