/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateInviteDTO } from '../models/CreateInviteDTO';
import type { CreateInviteResponseDTO } from '../models/CreateInviteResponseDTO';
import type { GetInvitesResponseDTO } from '../models/GetInvitesResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InviteControllerService {

    /**
     * Get unaccepted invites
     * @param scopeId
     * @param customergroup Customer Group ID
     * @returns GetInvitesResponseDTO OK
     * @throws ApiError
     */
    public static getInvitesOnGroup(
        scopeId: string,
        customergroup: number,
    ): CancelablePromise<GetInvitesResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{customergroup}/invites',
            path: {
                'scopeId': scopeId,
                'customergroup': customergroup,
            },
        });
    }

    /**
     * Create an invite
     * @param scopeId
     * @param customergroup Customer Group ID
     * @param requestBody
     * @returns CreateInviteResponseDTO invite id and version
     * @throws ApiError
     */
    public static createInvite1(
        scopeId: string,
        customergroup: number,
        requestBody: CreateInviteDTO,
    ): CancelablePromise<CreateInviteResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/{scopeId}/customergroups/{customergroup}/invites',
            path: {
                'scopeId': scopeId,
                'customergroup': customergroup,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Updates the invite to status 'CANCELED'
     * @param scopeId
     * @param customergroup groupId
     * @param inviteId Invite id
     * @returns any OK
     * @throws ApiError
     */
    public static cancelInvite(
        scopeId: string,
        customergroup: number,
        inviteId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/public/{scopeId}/customergroups/{customergroup}/invites/{inviteId}',
            path: {
                'scopeId': scopeId,
                'customergroup': customergroup,
                'inviteId': inviteId,
            },
        });
    }

}