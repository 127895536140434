/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TopupOptionUIV2 } from '../models/TopupOptionUIV2';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileTopupsControllerV2Service {

    /**
     * Perform a given Topup on the given subscription
     * User must have 'PRODUCT_ADMINISTRATION' to access this resource
     * @param scopeId
     * @param c2Buid
     * @param topupCode
     * @param sendSms If set to true, will send a confirmation SMS when the topup has been performed.
     * @returns any The Topup was successful. However, an SMS failed to be sent, the payment has been delayed, or similar effect.Look to translation for details
     * @throws ApiError
     */
    public static buyTopup(
        scopeId: string,
        c2Buid: string,
        topupCode: string,
        sendSms: boolean = true,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/v2/scope/{scopeId}/subscriptions/{c2buid}/topups/{topupCode}/',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
                'topupCode': topupCode,
            },
            query: {
                'sendSMS': sendSms,
            },
            errors: {
                400: `Bad request. If a translation is provided in the response, this translation will have details aboutwhat application error occurred`,
            },
        });
    }

    /**
     * Get a list of available Topup Options
     * User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param c2Buid
     * @param includeProactive Set to True to get Proactive Topups
     * @param includeReactive Set to True to get Reactive Topups
     * @param includeCompensation Set to True to get Compensation Topups
     * @returns TopupOptionUIV2 Successful request
     * @throws ApiError
     */
    public static getTopupOptions(
        scopeId: string,
        c2Buid: string,
        includeProactive: boolean,
        includeReactive: boolean,
        includeCompensation: boolean,
    ): CancelablePromise<TopupOptionUIV2> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/v2/scope/{scopeId}/subscriptions/{c2buid}/topups/',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            query: {
                'includeProactive': includeProactive,
                'includeReactive': includeReactive,
                'includeCompensation': includeCompensation,
            },
            errors: {
                400: `Bad RequestCan be thrown if no domestic Topup type is included (includeProactive, includeReactive, etc).`,
                401: `Unauthorized request`,
                500: `Internal Server Error`,
            },
        });
    }

}