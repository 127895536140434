/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TrafficHistoryUI } from '../models/TrafficHistoryUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorporateInstalledbaseProlaneService {

    /**
     * Returns traffic history on prolane subscription
     * @param interval
     * @param scopeId Customer identification number
     * @param uId
     * @param sourceSystem
     * @returns TrafficHistoryUI Success
     * @throws ApiError
     */
    public static getTrafficHistory(
        interval: 'day' | 'week' | 'month' | 'year',
        scopeId: string,
        uId: string,
        sourceSystem: 'MUSE' | 'BENGZHAN' = 'MUSE',
    ): CancelablePromise<TrafficHistoryUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscription/{uId}/traffichistory',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
            query: {
                'interval': interval,
                'sourceSystem': sourceSystem,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}