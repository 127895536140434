/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessResponse } from '../models/SuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class QlikSessionControllerService {

    /**
     * Creates and returns a sessionId (as a SET-COOKIE response + responseBody) that shall be used to access the enterprise analytics (Qlik) for this user.
     * @param scopeId ScopeId
     * @param sessionType sessionType
     * @returns SuccessResponse Request has succeeded
     * @throws ApiError
     */
    public static createQlikSessionUsingPost(
        scopeId: string,
        sessionType: 'CONFIDENTIAL' | 'DEFAULT' | 'FINANCIAL' | 'QUALITY' | 'USAGE' = 'DEFAULT',
    ): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accessprofiles/{scopeId}/qlik/session',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'sessionType': sessionType,
            },
            errors: {
                400: `INVALID_ACCESSPROFILE_ID`,
                403: `ACCESS_DENIED`,
                500: `INTERNAL_SERVER_ERROR`,
            },
        });
    }

    /**
     * Deletes current qlik sessionId (set as a cookie X-Qlik-Session-portal) so its no longer available in Qlik
     * @param scopeId ScopeId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteSessionUsingDelete(
        scopeId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/accessprofiles/{scopeId}/qlik/session',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                400: `INVALID_ACCESSPROFILE_ID`,
                403: `ACCESS_DENIED`,
                500: `INTERNAL_SERVER_ERROR`,
            },
        });
    }

    /**
     * Creates and returns a sessionId (as a SET-COOKIE response + responseBody) that shall be used to access the enterprise analytics (Qlik) for this user.
     * @param scopeId ScopeId
     * @param sessionType sessionType
     * @returns SuccessResponse Request has succeeded
     * @throws ApiError
     */
    public static createQlikSessionUsingPost1(
        scopeId: string,
        sessionType: 'CONFIDENTIAL' | 'DEFAULT' | 'FINANCIAL' | 'QUALITY' | 'USAGE' = 'DEFAULT',
    ): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scopeId/{scopeId}/qlik/session',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'sessionType': sessionType,
            },
            errors: {
                400: `INVALID_ACCESSPROFILE_ID`,
                403: `ACCESS_DENIED`,
                500: `INTERNAL_SERVER_ERROR`,
            },
        });
    }

    /**
     * Deletes current qlik sessionId (set as a cookie X-Qlik-Session-portal) so its no longer available in Qlik
     * @param scopeId ScopeId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteSessionUsingDelete1(
        scopeId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/scopeId/{scopeId}/qlik/session',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                400: `INVALID_ACCESSPROFILE_ID`,
                403: `ACCESS_DENIED`,
                500: `INTERNAL_SERVER_ERROR`,
            },
        });
    }

}