/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderDetailsUI } from "../models/OrderDetailsUI";
import type { OrderUI } from "../models/OrderUI";
import type { OrderStatusUI } from "../models/OrderStatusUI";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class PublicControllerService {
  /**
   * getOrderHistory
   * @param scopeId scopeId
   * @param length length
   * @param start start
   * @param status status
   * @returns OrderUI OK
   * @throws ApiError
   */
  public static getOrderHistoryUsingGet(
    scopeId: string,
    length?: number,
    start?: number,
    status?: OrderStatusUI | OrderStatusUI[]
  ): CancelablePromise<Array<OrderUI>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/corp-order-history/customers/{scopeId}/orders",
      path: {
        scopeId: scopeId,
      },
      query: {
        length: length,
        start: start,
        status: status,
      },
    });
  }

  /**
   * getOrderDetails
   * @param scopeId scopeId
   * @param uId uId
   * @returns OrderDetailsUI OK
   * @throws ApiError
   */
  public static getOrderDetailsUsingGet(
    scopeId: string,
    uId: string
  ): CancelablePromise<OrderDetailsUI> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/corp-order-history/customers/{scopeId}/orders/{uId}",
      path: {
        scopeId: scopeId,
        uId: uId,
      },
    });
  }
}
