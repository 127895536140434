/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganisationVO } from '../models/OrganisationVO';
import type { SearchOrganisationRequest } from '../models/SearchOrganisationRequest';
import type { SearchOrganisationResponse } from '../models/SearchOrganisationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganisationControllerService {

    /**
     * @param requestBody
     * @returns SearchOrganisationResponse OK
     * @throws ApiError
     */
    public static searchOrganisation(
        requestBody: SearchOrganisationRequest,
    ): CancelablePromise<SearchOrganisationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/search-organisation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OrganisationVO OK
     * @throws ApiError
     */
    public static getSuggestedOrganisations(): CancelablePromise<Array<OrganisationVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/organisations/suggested',
        });
    }

}