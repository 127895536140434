/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ITDaaSContractResponse } from '../models/ITDaaSContractResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ItdaasControllerImplService {

    /**
     * Returns IT DaaS Contracts on given Subscription
     * @param scopeId Customer identification number
     * @param uId uId
     * @returns ITDaaSContractResponse Success
     * @throws ApiError
     */
    public static getItDaaSContractsUsingGet(
        scopeId: string,
        uId: string,
    ): CancelablePromise<ITDaaSContractResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscription/{uId}/contractserviceunits',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
        });
    }

}