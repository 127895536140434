/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessProfileInfoListDTO } from '../models/AccessProfileInfoListDTO';
import type { ExistingUserOrInviteDTO } from '../models/ExistingUserOrInviteDTO';
import type { GetIsUserFederatedResponseDTO } from '../models/GetIsUserFederatedResponseDTO';
import type { ProfileDTO } from '../models/ProfileDTO';
import type { UserInfoDTO } from '../models/UserInfoDTO';
import type { UserProfileListDTO } from '../models/UserProfileListDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserControllerService {

    /**
     * @deprecated
     * Get user info for all users with a profile connected to a customergroup. [Use /json instead!]
     * @param scopeId
     * @param groupId Customer Group ID
     * @returns UserProfileListDTO OK
     * @throws ApiError
     */
    public static getUsersInCustomerGroup(
        scopeId: string,
        groupId: number,
    ): CancelablePromise<UserProfileListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/users',
            path: {
                'scopeId': scopeId,
                'groupId': groupId,
            },
        });
    }

    /**
     * Returns information about a user fetched from EAM
     * @param scopeId
     * @param groupId Customer Group ID
     * @param tcwssid TCWSSID for the user
     * @returns UserInfoDTO OK
     * @throws ApiError
     */
    public static getUserInfo(
        scopeId: string,
        groupId: number,
        tcwssid: string,
    ): CancelablePromise<UserInfoDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/users/{tcwssid}',
            path: {
                'scopeId': scopeId,
                'groupId': groupId,
                'tcwssid': tcwssid,
            },
        });
    }

    /**
     * @deprecated
     * Get the accessprofile associated with the profile specified by TCWSSID and groupId
     * @param scopeId
     * @param tcwssid User ID
     * @param groupId Customer Group ID
     * @returns ProfileDTO Successful response
     * @throws ApiError
     */
    public static getUserProfile(
        scopeId: string,
        tcwssid: string,
        groupId: number,
    ): CancelablePromise<ProfileDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/users/{tcwssid}/profile',
            path: {
                'scopeId': scopeId,
                'tcwssid': tcwssid,
                'groupId': groupId,
            },
        });
    }

    /**
     * Remove a user profile and its associated access profiles
     * @param scopeId
     * @param tcwssid TCWSSID
     * @param groupId Customer Group ID
     * @returns string OK
     * @throws ApiError
     */
    public static removeUserProfile(
        scopeId: string,
        tcwssid: string,
        groupId: number,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/public/{scopeId}/customergroups/{groupId}/users/{tcwssid}/profile',
            path: {
                'scopeId': scopeId,
                'tcwssid': tcwssid,
                'groupId': groupId,
            },
        });
    }

    /**
     * Return if user is federated
     * @param scopeId
     * @param tcwssid User ID
     * @param groupId Customer Group ID
     * @returns GetIsUserFederatedResponseDTO OK
     * @throws ApiError
     */
    public static getIsUserFederated(
        scopeId: string,
        tcwssid: string,
        groupId: number,
    ): CancelablePromise<GetIsUserFederatedResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/users/{tcwssid}/isfederated',
            path: {
                'scopeId': scopeId,
                'tcwssid': tcwssid,
                'groupId': groupId,
            },
        });
    }

    /**
     * Search for user information based on email address or mobile phone number
     * @param scopeId
     * @param groupId Customer Group ID
     * @param email The email address to search for
     * @param phone The phone number to search for
     * @returns ExistingUserOrInviteDTO OK
     * @throws ApiError
     */
    public static searchUserByEmailOrPhone(
        scopeId: string,
        groupId: number,
        email?: string,
        phone?: string,
    ): CancelablePromise<ExistingUserOrInviteDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/users/search',
            path: {
                'scopeId': scopeId,
                'groupId': groupId,
            },
            query: {
                'email': email,
                'phone': phone,
            },
        });
    }

    /**
     * Get user info for all visible users with a profile connected to a customer group
     * @param scopeId
     * @param groupId Customer Group ID
     * @returns AccessProfileInfoListDTO OK
     * @throws ApiError
     */
    public static getUsersInCustomerGroupJson(
        scopeId: string,
        groupId: number,
    ): CancelablePromise<AccessProfileInfoListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/users/json',
            path: {
                'scopeId': scopeId,
                'groupId': groupId,
            },
        });
    }

}