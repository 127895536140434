/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetPartyIdResponseDTO } from '../models/GetPartyIdResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SyncPartyForOrderFlowControllerService {

    /**
     * @deprecated
     * Fetch partyId
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param tcwssid
     * @returns GetPartyIdResponseDTO Returns partyId
     * @throws ApiError
     */
    public static oldGetPartyId(
        scopeId: string,
        tscid: string,
        tcwssid: string,
    ): CancelablePromise<GetPartyIdResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/scope/{scopeId}/organizations/{tscid}/tcwss/{tcwssid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'tcwssid': tcwssid,
            },
        });
    }

    /**
     * Sync TCWSS user with PIM and return partyId
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @returns GetPartyIdResponseDTO Returns partyId
     * @throws ApiError
     */
    public static syncTcwssUserAndGetPartyid(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<GetPartyIdResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/scope/{scopeId}/organizations/{tscid}/sync',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

}