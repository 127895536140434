/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddSubscriberRoleRequest } from '../models/AddSubscriberRoleRequest';
import type { CreateSubscriberRequest } from '../models/CreateSubscriberRequest';
import type { EditSubscriberRequest } from '../models/EditSubscriberRequest';
import type { EditSubscriberResponse } from '../models/EditSubscriberResponse';
import type { SelfSubscriberDetailsResponse } from '../models/SelfSubscriberDetailsResponse';
import type { SubscriberDetailsResponse } from '../models/SubscriberDetailsResponse';
import type { SubscriberListResponse } from '../models/SubscriberListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriberService {

    /**
     * Returns list of subscribers filtered by role Subscriber
     * @param xAuthScopeId
     * @param tscId
     * @returns SubscriberListResponse OK
     * @throws ApiError
     */
    public static getSubscribers(
        xAuthScopeId: any,
        tscId?: string,
    ): CancelablePromise<SubscriberListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscribers',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'tscId': tscId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Creates a new subscriber
     * @param xAuthScopeId
     * @param requestBody
     * @returns SubscriberDetailsResponse Created
     * @throws ApiError
     */
    public static createSubscriber(
        xAuthScopeId: any,
        requestBody: CreateSubscriberRequest,
    ): CancelablePromise<SubscriberDetailsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/subscribers',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Add subscriber role to party
     * @param partyId
     * @param xAuthScopeId
     * @param requestBody
     * @returns SubscriberDetailsResponse Created
     * @throws ApiError
     */
    public static addSubscriberRole(
        partyId: string,
        xAuthScopeId: any,
        requestBody: AddSubscriberRoleRequest,
    ): CancelablePromise<SubscriberDetailsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/subscribers/{partyId}/role',
            path: {
                'partyId': partyId,
            },
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Returns a specific subscriber by partyId
     * @param partyId
     * @param tscId
     * @param xAuthScopeId
     * @returns SubscriberDetailsResponse OK
     * @throws ApiError
     */
    public static getSubscriber(
        partyId: string,
        tscId: string,
        xAuthScopeId: any,
    ): CancelablePromise<SubscriberDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscribers/{partyId}',
            path: {
                'partyId': partyId,
            },
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'tscId': tscId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Deletes subscriber and related notifications
     * @param partyId
     * @param tscId
     * @param xAuthScopeId
     * @returns void
     * @throws ApiError
     */
    public static deleteSubscriber(
        partyId: string,
        tscId: string,
        xAuthScopeId: any,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/subscribers/{partyId}',
            path: {
                'partyId': partyId,
            },
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'tscId': tscId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Edits existing subscriber
     * @param partyId
     * @param xAuthScopeId
     * @param requestBody
     * @returns EditSubscriberResponse OK
     * @throws ApiError
     */
    public static editSubscriber(
        partyId: string,
        xAuthScopeId: any,
        requestBody: EditSubscriberRequest,
    ): CancelablePromise<EditSubscriberResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/subscribers/{partyId}',
            path: {
                'partyId': partyId,
            },
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Returns self subscriber details
     * @param xAuthScopeId
     * @returns SelfSubscriberDetailsResponse OK
     * @throws ApiError
     */
    public static getSelfSubscriberDetails(
        xAuthScopeId: any,
    ): CancelablePromise<SelfSubscriberDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscribers/SELF',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

}