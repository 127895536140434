/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CategoriesOverviewUI } from '../models/CategoriesOverviewUI';
import type { StreamingResponseBody } from '../models/StreamingResponseBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FetchingSubscriptionReportsService {

    /**
     * @param scopeId
     * @param productCategory
     * @param language
     * @param tscid
     * @returns StreamingResponseBody OK
     * @throws ApiError
     */
    public static streamReportExcel(
        scopeId: string,
        productCategory: string,
        language: 'sv' | 'en',
        tscid?: string,
    ): CancelablePromise<StreamingResponseBody> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/reports/{productCategory}.xlsx',
            path: {
                'scopeId': scopeId,
                'productCategory': productCategory,
            },
            query: {
                'language': language,
                'tscid': tscid,
            },
        });
    }

    /**
     * @param scopeId
     * @param productCategory
     * @param language
     * @param tscid
     * @returns StreamingResponseBody OK
     * @throws ApiError
     */
    public static streamReportCsv(
        scopeId: string,
        productCategory: string,
        language: 'sv' | 'en',
        tscid?: string,
    ): CancelablePromise<StreamingResponseBody> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/reports/{productCategory}.csv',
            path: {
                'scopeId': scopeId,
                'productCategory': productCategory,
            },
            query: {
                'language': language,
                'tscid': tscid,
            },
        });
    }

    /**
     * @param scopeId
     * @returns CategoriesOverviewUI OK
     * @throws ApiError
     */
    public static getReportsCategories(
        scopeId: string,
    ): CancelablePromise<CategoriesOverviewUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/reports/categories',
            path: {
                'scopeId': scopeId,
            },
        });
    }

}