/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasketDTO } from '../models/BasketDTO';
import type { CheckoutBasketRequestDTO } from '../models/CheckoutBasketRequestDTO';
import type { CheckoutResponseDTO } from '../models/CheckoutResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BasketControllerService {

    /**
     * Checkout a basket
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param id
     * @param requestBody
     * @returns CheckoutResponseDTO Successful checkout
     * @throws ApiError
     */
    public static checkoutBasket(
        scopeId: string,
        tscid: string,
        id: string,
        requestBody: CheckoutBasketRequestDTO,
    ): CancelablePromise<CheckoutResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/organizations/{tscid}/basket/{id}/checkout',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Fetch a basket
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param lang
     * @returns BasketDTO Returns basket
     * @throws ApiError
     */
    public static getBasket(
        scopeId: string,
        tscid: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<BasketDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/basket',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'lang': lang,
            },
        });
    }

    /**
     * Fetch a basket
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param basketId
     * @param lang
     * @returns BasketDTO Returns basket
     * @throws ApiError
     */
    public static getBasketById(
        scopeId: string,
        tscid: string,
        basketId: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<BasketDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/basket/{basketId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'basketId': basketId,
            },
            query: {
                'lang': lang,
            },
        });
    }

    /**
     * Delete an item in the basket
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param basketId
     * @param itemId
     * @param lang
     * @returns BasketDTO Returns basket
     * @throws ApiError
     */
    public static deleteItemInBasket(
        scopeId: string,
        tscid: string,
        basketId: string,
        itemId: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<BasketDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/public/scope/{scopeId}/organizations/{tscid}/basket/{basketId}/item/{itemId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'basketId': basketId,
                'itemId': itemId,
            },
            query: {
                'lang': lang,
            },
        });
    }

}