/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthInfoDTO } from '../models/AuthInfoDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IsLoggedInControllerService {

    /**
     * Check if the user is logged in and return information regarding the users authentication
     * @returns AuthInfoDTO Success
     * @throws ApiError
     */
    public static isLoggedIn(): CancelablePromise<AuthInfoDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/isloggedin',
        });
    }

}