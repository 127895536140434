/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetRolesResponseDTO } from '../models/GetRolesResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RolesControllerService {

    /**
     * Get all Roles
     * @param groupId Customer Group ID
     * @param scopeId Scope ID
     * @returns GetRolesResponseDTO OK
     * @throws ApiError
     */
    public static getRolesByGroup(
        groupId: number,
        scopeId: string,
    ): CancelablePromise<GetRolesResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/roles',
            path: {
                'groupId': groupId,
                'scopeId': scopeId,
            },
        });
    }

    /**
     * @deprecated
     * Get all Roles
     * @param accessprofileId Accessprofile ID
     * @param scopeId Scope ID
     * @returns GetRolesResponseDTO OK
     * @throws ApiError
     */
    public static getRoles(
        accessprofileId: number,
        scopeId: string,
    ): CancelablePromise<GetRolesResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/accessprofiles/{accessprofileId}/roles',
            path: {
                'accessprofileId': accessprofileId,
                'scopeId': scopeId,
            },
        });
    }

}