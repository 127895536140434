/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrderDataRequestBody } from '../models/CreateOrderDataRequestBody';
import type { CreateOrderResponse } from '../models/CreateOrderResponse';
import type { EligibilityResponse } from '../models/EligibilityResponse';
import type { ListGoodiesPerSubscriptionResponse } from '../models/ListGoodiesPerSubscriptionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GoodiesControllerService {

    /**
     * listGoodiesPerSubscription
     * @param scopeId scopeId
     * @param tscid tscid
     * @returns ListGoodiesPerSubscriptionResponse Successful request
     * @throws ApiError
     */
    public static listGoodiesPerSubscriptionUsingGet(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<ListGoodiesPerSubscriptionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-goodies-service/v1/{scopeId}/list/{tscid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

    /**
     * addBenefit
     * @param body body
     * @param scopeId scopeId
     * @param tscid tscid
     * @returns CreateOrderResponse Successful request
     * @throws ApiError
     */
    public static addBenefitUsingPost(
        body: CreateOrderDataRequestBody,
        scopeId: string,
        tscid: string,
    ): CancelablePromise<CreateOrderResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-goodies-service/{scopeId}/add-benefit/{tscid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: body,
        });
    }

    /**
     * addBenefit
     * @param body body
     * @param scopeId scopeId
     * @param tscid tscid
     * @returns CreateOrderResponse Successful request
     * @throws ApiError
     */
    public static addBenefitUsingPost1(
        body: CreateOrderDataRequestBody,
        scopeId: string,
        tscid: string,
    ): CancelablePromise<CreateOrderResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-goodies-service/{scopeId}/createOrder/{tscid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: body,
        });
    }

    /**
     * deleteBenefit
     * @param body body
     * @param scopeId scopeId
     * @param tscid tscid
     * @returns CreateOrderResponse Successful request
     * @throws ApiError
     */
    public static deleteBenefitUsingPost(
        body: CreateOrderDataRequestBody,
        scopeId: string,
        tscid: string,
    ): CancelablePromise<CreateOrderResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-goodies-service/{scopeId}/delete-benefit/{tscid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: body,
        });
    }

    /**
     * isEligible
     * @param scopeId scopeId
     * @returns EligibilityResponse Successful request
     * @throws ApiError
     */
    public static isEligibleUsingGet(
        scopeId: string,
    ): CancelablePromise<EligibilityResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-goodies-service/{scopeId}/eligible',
            path: {
                'scopeId': scopeId,
            },
        });
    }

}