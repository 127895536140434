/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { APNWrapper } from '../models/APNWrapper';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalMobileApnControllerService {

    /**
     * Store a new set of APN on a subscription
     * User must have PUI: 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param c2BuId
     * @returns APNWrapper OK
     * @throws ApiError
     */
    public static getApnServices1(
        scopeId: string,
        c2BuId: string,
    ): CancelablePromise<APNWrapper> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/scope/{scopeId}/subscriptions/{c2buId}',
            path: {
                'scopeId': scopeId,
                'c2buId': c2BuId,
            },
        });
    }

    /**
     * Store a new set of APN on a subscription
     * User must have PUI: 'PRODUCT_ADMINISTRATION' to access this resource
     * @param scopeId
     * @param c2BuId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static setApnService1(
        scopeId: string,
        c2BuId: string,
        requestBody: APNWrapper,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal/scope/{scopeId}/subscriptions/{c2buId}',
            path: {
                'scopeId': scopeId,
                'c2buId': c2BuId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}