/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TouchpointPlusDetailsDto } from '../models/TouchpointPlusDetailsDto';
import type { TouchpointPlusUserDto } from '../models/TouchpointPlusUserDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorporateInstalledbaseSwitchboardService {

    /**
     * @param scopeId
     * @param uId
     * @returns TouchpointPlusUserDto OK
     * @throws ApiError
     */
    public static getTouchpointPlusUsers(
        scopeId: string,
        uId: string,
    ): CancelablePromise<Array<TouchpointPlusUserDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/touchpointplus/{uId}/users',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
        });
    }

    /**
     * @param scopeId
     * @param uId
     * @returns TouchpointPlusDetailsDto OK
     * @throws ApiError
     */
    public static getTouchpointPlusDetails(
        scopeId: string,
        uId: string,
    ): CancelablePromise<TouchpointPlusDetailsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/touchpointplus/{uId}/details',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
        });
    }

}