/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserInfoVO } from '../models/UserInfoVO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserControllerService {

    /**
     * Returns user's details.
     * @param scopeId
     * @returns UserInfoVO OK
     * @throws ApiError
     */
    public static getTcwssUser(
        scopeId: string,
    ): CancelablePromise<UserInfoVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/users/self',
            path: {
                'scopeId': scopeId,
            },
        });
    }

    /**
     * Returns user details for the given tcwssid.
     * @param tcwssid
     * @returns UserInfoVO OK
     * @throws ApiError
     */
    public static getTcwssUserInternal(
        tcwssid: string,
    ): CancelablePromise<UserInfoVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/users/tcwss/{tcwssid}',
            path: {
                'tcwssid': tcwssid,
            },
        });
    }

}