/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommandDTO } from '../models/CommandDTO';
import type { SurfBlasterDetailsDTO } from '../models/SurfBlasterDetailsDTO';
import type { TerminalOutputDTO } from '../models/TerminalOutputDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorpCustomerSurfBlasterService {

    /**
     * Send a command to the server
     * @param scopeId
     * @param requestBody
     * @returns string Successful operation send request command
     * @throws ApiError
     */
    public static send(
        scopeId: string,
        requestBody: CommandDTO,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/{scopeId}/send',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `No connection from telnet client to SurfBlaster server`,
            },
        });
    }

    /**
     * Performs a blocking long-poll on the output buffer
     * @param scopeId
     * @returns TerminalOutputDTO Successful operation poll response from surfblaster.
     * @throws ApiError
     */
    public static poll(
        scopeId: string,
    ): CancelablePromise<Array<TerminalOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/poll',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                404: `No connection telnet client with surfblaster server.`,
            },
        });
    }

    /**
     * Get some details
     * @param scopeId
     * @returns SurfBlasterDetailsDTO Successfully connected telnet client
     * @throws ApiError
     */
    public static getDetails(
        scopeId: string,
    ): CancelablePromise<SurfBlasterDetailsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/getdetails',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                401: `Invalid username and password`,
                404: `Invalid telnet configuration`,
            },
        });
    }

    /**
     * Disconnect from the remote SSH server
     * @param scopeId
     * @returns string Successful operation disconnect from telnet client.
     * @throws ApiError
     */
    public static disconnect(
        scopeId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/disconnect',
            path: {
                'scopeId': scopeId,
            },
        });
    }

    /**
     * Check connection status - is connection to surfblaster alive before sending command
     * @param scopeId
     * @returns boolean Successful operation get telnet client connection status.
     * @throws ApiError
     */
    public static getConnectionStatusBeforeSendingReq(
        scopeId: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/connection/status',
            path: {
                'scopeId': scopeId,
            },
        });
    }

    /**
     * Connect to the remote SSH server
     * @param scopeId
     * @returns string Successfully connected telnet client
     * @throws ApiError
     */
    public static connect(
        scopeId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/connect',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                401: `Invalid username and password`,
                404: `Invalid telnet configuration`,
            },
        });
    }

}