/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StatusMessage } from '../models/StatusMessage';
import type { Tooltip } from '../models/Tooltip';
import type { TooltipSettings } from '../models/TooltipSettings';
import type { TooltipShown } from '../models/TooltipShown';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublicControllerService {

    /**
     * Save tooltip setting on user
     * @param scopeId
     * @param requestBody
     * @returns StatusMessage Success
     * @throws ApiError
     */
    public static putTooltipSetting(
        scopeId: string,
        requestBody: Tooltip,
    ): CancelablePromise<StatusMessage> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/settings/{scopeId}/tooltip',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                403: `Access denied.`,
                500: `Internal server error.`,
            },
        });
    }

    /**
     * Get tooltip settings on user
     * @param scopeId
     * @param tooltipId
     * @returns TooltipShown Success
     * @throws ApiError
     */
    public static getTooltip(
        scopeId: string,
        tooltipId: string,
    ): CancelablePromise<TooltipShown> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/{scopeId}/{tooltipId}/tooltip',
            path: {
                'scopeId': scopeId,
                'tooltipId': tooltipId,
            },
            errors: {
                400: `Bad request`,
                403: `Access denied.`,
                500: `Internal server error.`,
            },
        });
    }

    /**
     * Get tooltip settings on user
     * @param scopeId
     * @returns TooltipSettings Success
     * @throws ApiError
     */
    public static getTooltips(
        scopeId: string,
    ): CancelablePromise<TooltipSettings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/{scopeId}/tooltips',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                400: `Bad request`,
                403: `Access denied.`,
                500: `Internal server error.`,
            },
        });
    }

    /**
     * ping database
     * @param scopeId
     * @returns string Answer from ping
     * @throws ApiError
     */
    public static publicPing(
        scopeId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/{scopeId}/ping',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                403: `Access denied.`,
                500: `Internal server error.`,
            },
        });
    }

}