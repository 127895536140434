/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddonsUI } from '../models/AddonsUI';
import type { AddonUI } from '../models/AddonUI';
import type { SubscriptionAddonGroup } from '../models/SubscriptionAddonGroup';
import type { UpdateAddonsDTO } from '../models/UpdateAddonsDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileAddonsControllerService {

    /**
     * Activate addon connected to given c2buid. User must have 'PRODUCT_ADMINISTRATION' to access this resource
     * @param scopeId
     * @param c2BuId
     * @param productCode
     * @returns AddonsUI Successful request
     * @throws ApiError
     */
    public static activateBasicService(
        scopeId: string,
        c2BuId: string,
        productCode: string,
    ): CancelablePromise<AddonsUI> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/subscriptions/{c2buId}/{productCode}',
            path: {
                'scopeId': scopeId,
                'c2buId': c2BuId,
                'productCode': productCode,
            },
        });
    }

    /**
     * Removes a GSM-AHS service on given c2buid. User must have PUI: 'PRODUCT_ADMINISTRATION'
     * @param scopeId
     * @param c2BuId
     * @param productCode
     * @returns AddonsUI Successful request
     * @throws ApiError
     */
    public static deactivateBasicService(
        scopeId: string,
        c2BuId: string,
        productCode: string,
    ): CancelablePromise<AddonsUI> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/public/scope/{scopeId}/subscriptions/{c2buId}/{productCode}',
            path: {
                'scopeId': scopeId,
                'c2buId': c2BuId,
                'productCode': productCode,
            },
            errors: {
                400: `Invalid parameters passed`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a GSM-AHS service on given c2buid. User must have PUI: 'PRODUCT_ADMINISTRATION'
     * @param scopeId
     * @param c2BuId
     * @param productCode
     * @returns AddonsUI Successful request
     * @throws ApiError
     */
    public static updateBasicService(
        scopeId: string,
        c2BuId: string,
        productCode: string,
    ): CancelablePromise<AddonsUI> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/public/scope/{scopeId}/subscriptions/{c2buId}/{productCode}',
            path: {
                'scopeId': scopeId,
                'c2buId': c2BuId,
                'productCode': productCode,
            },
            errors: {
                400: `Invalid parameters passed`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a GSM-AHS service on given c2buid. User must have PUI: 'PRODUCT_ADMINISTRATION'
     * @param scopeId
     * @param c2BuId
     * @param requestBody
     * @returns string Successful request
     * @throws ApiError
     */
    public static updateAddons(
        scopeId: string,
        c2BuId: string,
        requestBody: UpdateAddonsDTO,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/public/scope/{scopeId}/subscriptions/{c2buId}/update',
            path: {
                'scopeId': scopeId,
                'c2buId': c2BuId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid parameters passed`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @deprecated
     * Gets addons connected to given c2buid. User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param c2BuId
     * @returns AddonsUI Successful request
     * @throws ApiError
     */
    public static getAddons(
        scopeId: string,
        c2BuId: string,
    ): CancelablePromise<AddonsUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/subscriptions/{c2buId}',
            path: {
                'scopeId': scopeId,
                'c2buId': c2BuId,
            },
        });
    }

    /**
     * Gets addons connected to given c2buid. User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param c2BuId
     * @returns AddonUI Successful request
     * @throws ApiError
     */
    public static getAddonsList(
        scopeId: string,
        c2BuId: string,
    ): CancelablePromise<Array<AddonUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/subscriptions/{c2buId}/getAddons',
            path: {
                'scopeId': scopeId,
                'c2buId': c2BuId,
            },
        });
    }

    /**
     * Gets addons connected to given c2buid. User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param c2BuId
     * @returns SubscriptionAddonGroup Successful request
     * @throws ApiError
     */
    public static getAddonsTitleList(
        scopeId: string,
        c2BuId: string,
    ): CancelablePromise<Array<SubscriptionAddonGroup>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/subscriptions/{c2buId}/V1/addons',
            path: {
                'scopeId': scopeId,
                'c2buId': c2BuId,
            },
        });
    }

}