/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TPPPermissions } from '../models/TPPPermissions';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TppOrderPermissionsControllerService {

    /**
     * Get TPP order permissions for user
     * @param scopeId
     * @returns TPPPermissions Successful request
     * @throws ApiError
     */
    public static getTppUserPermissions(
        scopeId: string,
    ): CancelablePromise<TPPPermissions> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/admin/tpp/{scopeId}',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal server error`,
            },
        });
    }

}