/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationsWithServiceWebsDTO } from '../models/OrganizationsWithServiceWebsDTO';
import type { ServiceWebListDTO } from '../models/ServiceWebListDTO';
import type { UpdateServiceWebsRequestDTO } from '../models/UpdateServiceWebsRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ServiceWebControllerService {

    /**
     * @deprecated
     * @param scopeId
     * @param groupId
     * @param userId
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static setServiceWebsForUserProfile(
        scopeId: string,
        groupId: number,
        userId: string,
        requestBody: UpdateServiceWebsRequestDTO,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/{scopeId}/customergroups/{groupId}/users/tcwss/{userId}/servicewebs',
            path: {
                'scopeId': scopeId,
                'groupId': groupId,
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param scopeId
     * @param accessProfileId
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static setServiceWebsForUserProfile1(
        scopeId: string,
        accessProfileId: string,
        requestBody: UpdateServiceWebsRequestDTO,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/{scopeId}/accessprofiles/{accessProfileId}/servicewebs',
            path: {
                'scopeId': scopeId,
                'accessProfileId': accessProfileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all available Service Webs for a given user profile
     * @param scopeId
     * @param groupId
     * @param userId
     * @returns ServiceWebListDTO List of available service webs
     * @throws ApiError
     */
    public static getServiceWebs(
        scopeId: string,
        groupId: number,
        userId: string,
    ): CancelablePromise<ServiceWebListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/users/{userId}/servicewebs',
            path: {
                'scopeId': scopeId,
                'groupId': groupId,
                'userId': userId,
            },
        });
    }

    /**
     * @param scopeId
     * @param groupId
     * @returns OrganizationsWithServiceWebsDTO OK
     * @throws ApiError
     */
    public static getServiceWebsForAccessibleOrganizationsInGroup(
        scopeId: string,
        groupId: number,
    ): CancelablePromise<OrganizationsWithServiceWebsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/servicewebs',
            path: {
                'scopeId': scopeId,
                'groupId': groupId,
            },
        });
    }

}