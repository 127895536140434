/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateInviteResponseDTO } from '../models/CreateInviteResponseDTO';
import type { CreateInviteV2DTO } from '../models/CreateInviteV2DTO';
import type { GetAvailableAccessDTO } from '../models/GetAvailableAccessDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InviteV2ControllerService {

    /**
     * Create an invite
     * @param scopeId
     * @param customergroup Customer Group ID
     * @param requestBody
     * @returns CreateInviteResponseDTO invite id and version
     * @throws ApiError
     */
    public static createInvite(
        scopeId: string,
        customergroup: number,
        requestBody: CreateInviteV2DTO,
    ): CancelablePromise<CreateInviteResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/{scopeId}/v2/customergroups/{customergroup}/invites',
            path: {
                'scopeId': scopeId,
                'customergroup': customergroup,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get available access
     * @param scopeId
     * @returns GetAvailableAccessDTO OK
     * @throws ApiError
     */
    public static getAvailableAccess(
        scopeId: string,
    ): CancelablePromise<GetAvailableAccessDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/v2/customergroups/access',
            path: {
                'scopeId': scopeId,
            },
        });
    }

}