/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetManageableOptionResponseDTO } from '../models/GetManageableOptionResponseDTO';
import type { OrderIdDTO } from '../models/OrderIdDTO';
import type { SelectOptionRequestDTO } from '../models/SelectOptionRequestDTO';
import type { SelectOptionResponseDTO } from '../models/SelectOptionResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ManageableOptionsControllerService {

    /**
     * Submit chosen options for subscription
     * Submit chosen options for subscription. Throws exception if something along the way fails
     * @param scopeId
     * @param c2BUid
     * @param basketId
     * @returns OrderIdDTO Submit was successful and returns order id
     * @throws ApiError
     */
    public static submitManageableOptions(
        scopeId: string,
        c2BUid: string,
        basketId: string,
    ): CancelablePromise<OrderIdDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/{c2bUid}/submit/{basketId}',
            path: {
                'scopeId': scopeId,
                'c2bUid': c2BUid,
                'basketId': basketId,
            },
        });
    }

    /**
     * Select manageable options for the subscription
     * Select manageable option for the subscription
     * @param scopeId
     * @param c2BUid
     * @param requestBody
     * @returns SelectOptionResponseDTO Patches in the selected option for the subscription in a temporary basket
     * @throws ApiError
     */
    public static selectOption(
        scopeId: string,
        c2BUid: string,
        requestBody: SelectOptionRequestDTO,
    ): CancelablePromise<SelectOptionResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/{c2bUid}/manage/select',
            path: {
                'scopeId': scopeId,
                'c2bUid': c2BUid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get manageable options for the subscription
     * Get manageable options and available choices for the subscription.
     * @param scopeId
     * @param c2BUid
     * @param lang
     * @returns GetManageableOptionResponseDTO A list of manageable options
     * @throws ApiError
     */
    public static getManageableOptions(
        scopeId: string,
        c2BUid: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<GetManageableOptionResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/{c2bUid}/manage',
            path: {
                'scopeId': scopeId,
                'c2bUid': c2BUid,
            },
            query: {
                'lang': lang,
            },
        });
    }

}