/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConsentRequest } from '../models/ConsentRequest';
import type { ConsentResponse } from '../models/ConsentResponse';
import type { ConsentResponseDTO } from '../models/ConsentResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorpConsentService {

    /**
     * Set corporate consent for customer
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns ConsentResponse Successfully set consent choices
     * @throws ApiError
     */
    public static setCorporateConsent(
        scopeId: string,
        tscid: string,
        requestBody: ConsentRequest,
    ): CancelablePromise<ConsentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/{scopeId}/setconsent/{tscid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denied`,
                500: `Internal server error`,
                502: `Bad Gateway`,
            },
        });
    }

    /**
     * Get corporate consent for customer
     * @param scopeId
     * @param tscid
     * @returns ConsentResponseDTO Successful operation
     * @throws ApiError
     */
    public static getConsent(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<Array<ConsentResponseDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/getconsent/{tscid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            errors: {
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * ping database
     * @param scopeId scopeId
     * @returns string Answer from ping
     * @throws ApiError
     */
    public static ping(
        scopeId: any,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/ping/{scopeId}',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                403: `Access denied.`,
                500: `Internal server error.`,
            },
        });
    }

}