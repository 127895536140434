/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InitiateCancellationDTO } from '../models/InitiateCancellationDTO';
import type { SetCancellationDateDTO } from '../models/SetCancellationDateDTO';
import type { SubmitCancellationResponseDTO } from '../models/SubmitCancellationResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CancellationControllerService {

    /**
     * Submit order for cancellation
     * User must have PUI 'TERMINATE_INTERNET_ACCESS' to access this resource
     * @param scopeId
     * @param cancellationId
     * @returns SubmitCancellationResponseDTO Returns pending cancellation date
     * @throws ApiError
     */
    public static submitCancellation(
        scopeId: string,
        cancellationId: string,
    ): CancelablePromise<SubmitCancellationResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/{cancellationId}/cancel/submit',
            path: {
                'scopeId': scopeId,
                'cancellationId': cancellationId,
            },
        });
    }

    /**
     * Set end date on cancellation
     * User must have PUI 'TERMINATE_INTERNET_ACCESS' to access this resource
     * @param scopeId
     * @param cancellationId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static setCancellationDate(
        scopeId: string,
        cancellationId: string,
        requestBody: SetCancellationDateDTO,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/{cancellationId}/cancel/setdate',
            path: {
                'scopeId': scopeId,
                'cancellationId': cancellationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Initiate an order for cancellation
     * User must have PUI 'TERMINATE_INTERNET_ACCESS' to access this resource
     * @param scopeId
     * @param c2BUid
     * @returns InitiateCancellationDTO Returns cancellationId that can be used for adding information about and submitting the cancellation
     * @throws ApiError
     */
    public static initiateCancellation(
        scopeId: string,
        c2BUid: string,
    ): CancelablePromise<InitiateCancellationDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/{c2bUid}/cancel/initiate',
            path: {
                'scopeId': scopeId,
                'c2bUid': c2BUid,
            },
        });
    }

}