/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderDTO } from '../models/OrderDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrderControllerService {

    /**
     * Fetch an order
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param orderId
     * @returns OrderDTO Returns order
     * @throws ApiError
     */
    public static getOrder(
        scopeId: string,
        tscid: string,
        orderId: string,
    ): CancelablePromise<OrderDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/order/{orderId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'orderId': orderId,
            },
            errors: {
                404: `Order not found`,
            },
        });
    }

}