/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DatacomDeliveriesCountUI } from '../models/DatacomDeliveriesCountUI';
import type { DatacomDeliveriesUI } from '../models/DatacomDeliveriesUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DeliveriesControllerImplService {

    /**
     * Returns all datacom deliveries.
     * @param scopeId Customer identification number
     * @returns DatacomDeliveriesUI Success
     * @throws ApiError
     */
    public static getDatacomDeliveriesUsingGet(
        scopeId: string,
    ): CancelablePromise<DatacomDeliveriesUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/deliveries/',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Returns the total open deliveries.
     * @param scopeId Customer identification number
     * @returns DatacomDeliveriesCountUI Success
     * @throws ApiError
     */
    public static getDatacomDeliveriesCountUsingGet(
        scopeId: string,
    ): CancelablePromise<DatacomDeliveriesCountUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/deliveries/opencount',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Returns all datacom deliveries.
     * @param deliverId deliverId
     * @param scopeId Customer identification number
     * @returns DatacomDeliveriesUI Success
     * @throws ApiError
     */
    public static getDatacomDeliveryByIdUsingGet(
        deliverId: string,
        scopeId: string,
    ): CancelablePromise<DatacomDeliveriesUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/deliveries/{deliverId}',
            path: {
                'deliverId': deliverId,
                'scopeId': scopeId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}