/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetManageUserInfoDTO } from '../models/GetManageUserInfoDTO';
import type { GetServiceWebsDTO } from '../models/GetServiceWebsDTO';
import type { UpdateManageUserInfoDTO } from '../models/UpdateManageUserInfoDTO';
import type { UpdateServiceWebsDTO } from '../models/UpdateServiceWebsDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ManageUserControllerService {

    /**
     * @param scopeId
     * @param accessProfileId
     * @returns GetManageUserInfoDTO OK
     * @throws ApiError
     */
    public static getAccessProfileUserInfo1(
        scopeId: number,
        accessProfileId: number,
    ): CancelablePromise<GetManageUserInfoDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/accessprofiles/{accessProfileId}/manageuserinfo',
            path: {
                'scopeId': scopeId,
                'accessProfileId': accessProfileId,
            },
        });
    }

    /**
     * @param scopeId
     * @param accessProfileId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateAccessProfileUserInfo(
        scopeId: number,
        accessProfileId: number,
        requestBody: UpdateManageUserInfoDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/{scopeId}/accessprofiles/{accessProfileId}/manageuserinfo',
            path: {
                'scopeId': scopeId,
                'accessProfileId': accessProfileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param scopeId
     * @param accessProfileId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteProfile(
        scopeId: number,
        accessProfileId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/public/{scopeId}/accessprofiles/{accessProfileId}/manageuserinfo',
            path: {
                'scopeId': scopeId,
                'accessProfileId': accessProfileId,
            },
        });
    }

    /**
     * @param scopeId
     * @param accessProfileId
     * @returns any OK
     * @throws ApiError
     */
    public static setAsSuperuser(
        scopeId: number,
        accessProfileId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/{scopeId}/accessprofiles/{accessProfileId}/manageuserinfo/superuser',
            path: {
                'scopeId': scopeId,
                'accessProfileId': accessProfileId,
            },
        });
    }

    /**
     * @param scopeId
     * @param accessProfileId
     * @returns GetServiceWebsDTO OK
     * @throws ApiError
     */
    public static getServiceWebs(
        scopeId: number,
        accessProfileId: number,
    ): CancelablePromise<GetServiceWebsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/accessprofiles/{accessProfileId}/manageuserinfo/servicewebs',
            path: {
                'scopeId': scopeId,
                'accessProfileId': accessProfileId,
            },
        });
    }

    /**
     * @param scopeId
     * @param accessProfileId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateServiceWebs(
        scopeId: number,
        accessProfileId: number,
        requestBody: UpdateServiceWebsDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/{scopeId}/accessprofiles/{accessProfileId}/manageuserinfo/servicewebs',
            path: {
                'scopeId': scopeId,
                'accessProfileId': accessProfileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}