/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InternalCpeInfoResponseUI } from '../models/InternalCpeInfoResponseUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalCpeInfoControllerImplService {

    /**
     * Returns all internal cpes on subscription.
     * @param scopeId Customer identification number
     * @param uId uId
     * @returns InternalCpeInfoResponseUI Success
     * @throws ApiError
     */
    public static getInternalCpesUsingGet(
        scopeId: string,
        uId: string,
    ): CancelablePromise<InternalCpeInfoResponseUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/teliaadmin/scope/{scopeId}/subscription/{uId}/internalcpes',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}