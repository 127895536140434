/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IndividualResponseDTO } from '../models/IndividualResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GetPartyByTscidControllerService {

    /**
     * Get party from PIM based on TSCID
     * User must have PUI 'B_EXPRESS_CHECKOUT' to access this resource
     * @param tscid
     * @returns IndividualResponseDTO Returns partyId
     * @throws ApiError
     */
    public static getIndividualByTscid(
        tscid: string,
    ): CancelablePromise<IndividualResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/party/organisation/{tscid}',
            path: {
                'tscid': tscid,
            },
        });
    }

}