/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IsAgreementInScopeDto } from '../models/IsAgreementInScopeDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AgreementsControllerService {

    /**
     * @param scopeId
     * @param agreementNumber
     * @returns IsAgreementInScopeDto OK
     * @throws ApiError
     */
    public static isAgreementInScope(
        scopeId: string,
        agreementNumber: string,
    ): CancelablePromise<IsAgreementInScopeDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/scope/{scopeId}/agreements/{agreementNumber}/is-in-scope',
            path: {
                'scopeId': scopeId,
                'agreementNumber': agreementNumber,
            },
        });
    }

}