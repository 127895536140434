/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookNumberDTO } from '../models/BookNumberDTO';
import type { GetOperatorForMsisdnDTO } from '../models/GetOperatorForMsisdnDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PrivateNumberBookingControllerService {

    /**
     * @param msisdn
     * @returns GetOperatorForMsisdnDTO OK
     * @throws ApiError
     */
    public static getOperatorForMsisdn(
        msisdn: string,
    ): CancelablePromise<GetOperatorForMsisdnDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-number-booking/v1/get-operator/{msisdn}',
            path: {
                'msisdn': msisdn,
            },
        });
    }

    /**
     * @param type
     * @returns BookNumberDTO OK
     * @throws ApiError
     */
    public static bookNumber(
        type: 'VOICE' | 'MBB' = 'VOICE',
    ): CancelablePromise<BookNumberDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-number-booking/v1/book-number',
            query: {
                'type': type,
            },
        });
    }

}