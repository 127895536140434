/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetAddressFromPointIdResponseDTO } from '../models/GetAddressFromPointIdResponseDTO';
import type { GetSubAddressDTO } from '../models/GetSubAddressDTO';
import type { LegalAddressResponseDTO } from '../models/LegalAddressResponseDTO';
import type { SearchResponseDTO } from '../models/SearchResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AddressLookupControllerService {

    /**
     * Get address from pointId
     * @param scopeId
     * @param pointId
     * @returns GetAddressFromPointIdResponseDTO OK
     * @throws ApiError
     */
    public static getAddressFromPointId(
        scopeId: string,
        pointId: string,
    ): CancelablePromise<GetAddressFromPointIdResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/address/{pointId}',
            path: {
                'scopeId': scopeId,
                'pointId': pointId,
            },
        });
    }

    /**
     * Get subaddresses from pointId
     * @param scopeId
     * @param pointId
     * @returns GetSubAddressDTO OK
     * @throws ApiError
     */
    public static getSubAddressesFromPointId(
        scopeId: string,
        pointId: string,
    ): CancelablePromise<GetSubAddressDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/customers/{scopeId}/subaddresses/{pointId}',
            path: {
                'scopeId': scopeId,
                'pointId': pointId,
            },
        });
    }

    /**
     * Get subaddresses from pointId
     * @param scopeId
     * @param pointId
     * @returns GetSubAddressDTO OK
     * @throws ApiError
     */
    public static getSubAddressesFromPointId1(
        scopeId: string,
        pointId: string,
    ): CancelablePromise<GetSubAddressDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/subaddresses/{pointId}',
            path: {
                'scopeId': scopeId,
                'pointId': pointId,
            },
        });
    }

    /**
     * search for an address
     * User must have PUI 'MYBUSINESS_ACCESS' to access this resource
     * @param scopeId
     * @param searchValue
     * @param bestHitsOnly
     * @param maxResults
     * @returns SearchResponseDTO OK
     * @throws ApiError
     */
    public static searchAddress(
        scopeId: string,
        searchValue: string,
        bestHitsOnly?: boolean,
        maxResults?: number,
    ): CancelablePromise<Array<SearchResponseDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/customers/{scopeId}/search',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'searchValue': searchValue,
                'bestHitsOnly': bestHitsOnly,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * search for an address
     * User must have PUI 'MYBUSINESS_ACCESS' to access this resource
     * @param scopeId
     * @param searchValue
     * @param bestHitsOnly
     * @param maxResults
     * @returns SearchResponseDTO OK
     * @throws ApiError
     */
    public static searchAddress1(
        scopeId: string,
        searchValue: string,
        bestHitsOnly?: boolean,
        maxResults?: number,
    ): CancelablePromise<Array<SearchResponseDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/search',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'searchValue': searchValue,
                'bestHitsOnly': bestHitsOnly,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * get the legal address of the organization for the given TSCID
     * User must have PUI 'MYBUSINESS_ACCESS' to access this resource
     * @param scopeId
     * @param tscid
     * @param structured
     * @returns LegalAddressResponseDTO OK
     * @throws ApiError
     */
    public static getLegalAddress(
        scopeId: string,
        tscid: string,
        structured: boolean = false,
    ): CancelablePromise<LegalAddressResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/customers/{scopeId}/organizations/{tscid}/legaladdress',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'structured': structured,
            },
        });
    }

    /**
     * get the legal address of the organization for the given TSCID
     * User must have PUI 'MYBUSINESS_ACCESS' to access this resource
     * @param scopeId
     * @param tscid
     * @param structured
     * @returns LegalAddressResponseDTO OK
     * @throws ApiError
     */
    public static getLegalAddress1(
        scopeId: string,
        tscid: string,
        structured: boolean = false,
    ): CancelablePromise<LegalAddressResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/legaladdress',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'structured': structured,
            },
        });
    }

}