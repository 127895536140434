/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MobileCustomer } from '../models/MobileCustomer';
import type { MobileCustomerRequestBody } from '../models/MobileCustomerRequestBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileCustomerControllerService {

    /**
     * Reads all 'GSM-AHS'-users for given customer
     * User must have PUI: 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param requestBody
     * @returns MobileCustomer Successful request
     * @throws ApiError
     */
    public static getMobileCustomer(
        scopeId: string,
        requestBody: MobileCustomerRequestBody,
    ): CancelablePromise<MobileCustomer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer/{scopeId}/',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}