/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HardwareOrder } from '../models/HardwareOrder';
import type { HardwareProductBundle } from '../models/HardwareProductBundle';
import type { ProptechAccess } from '../models/ProptechAccess';
import type { TeliaNowOrderResponse } from '../models/TeliaNowOrderResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HardwareControllerService {

    /**
     * Checkout your order
     * Send Proptech order to TeliaNOW
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns TeliaNowOrderResponse Successful request
     * @throws ApiError
     */
    public static checkout(
        scopeId: string,
        tscid: string,
        requestBody: HardwareOrder,
    ): CancelablePromise<TeliaNowOrderResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/{scopeId}/checkout/{tscid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Has access to order
     * Has access to order Proptech products
     * @param scopeId
     * @param tscid
     * @returns ProptechAccess Successful request
     * @throws ApiError
     */
    public static hasAccess(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<ProptechAccess> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/hasAccess/{tscid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

    /**
     * List hardware bundles
     * List all hardware bundles available for Proptech customers
     * @param scopeId
     * @returns HardwareProductBundle Successful request
     * @throws ApiError
     */
    public static listHardwareBundles(
        scopeId: string,
    ): CancelablePromise<Array<HardwareProductBundle>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/hardware',
            path: {
                'scopeId': scopeId,
            },
        });
    }

}