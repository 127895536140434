/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactPersonResponseDTO } from '../models/ContactPersonResponseDTO';
import type { CreateAndUpdatePersonRequestDTO } from '../models/CreateAndUpdatePersonRequestDTO';
import type { CreateAndUpdatePersonResponseDTO } from '../models/CreateAndUpdatePersonResponseDTO';
import type { GetPersonResponseDTO } from '../models/GetPersonResponseDTO';
import type { NotificationRequestDTO } from '../models/NotificationRequestDTO';
import type { NotificationResponseDTO } from '../models/NotificationResponseDTO';
import type { NotificationsResponseDTO } from '../models/NotificationsResponseDTO';
import type { SlaCountUI } from '../models/SlaCountUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorpCustomerDatacomMessagingService {

    /**
     * Update contact person
     * @param scopeId
     * @param tscid
     * @param personId
     * @param requestBody
     * @returns CreateAndUpdatePersonResponseDTO Successful operation
     * @throws ApiError
     */
    public static updatePerson(
        scopeId: string,
        tscid: string,
        personId: number,
        requestBody: CreateAndUpdatePersonRequestDTO,
    ): CancelablePromise<CreateAndUpdatePersonResponseDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/{scopeId}/{tscid}/updatePerson/{personId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'personId': personId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * Update contact person
     * @param scopeId
     * @param tscid
     * @param personId
     * @param notificationId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateNotification(
        scopeId: string,
        tscid: string,
        personId: number,
        notificationId: number,
        requestBody: NotificationRequestDTO,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/{scopeId}/{tscid}/updateNotification/{personId}/{notificationId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'personId': personId,
                'notificationId': notificationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * Create contact person
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns CreateAndUpdatePersonResponseDTO Successful operation
     * @throws ApiError
     */
    public static createPerson(
        scopeId: string,
        tscid: string,
        requestBody: CreateAndUpdatePersonRequestDTO,
    ): CancelablePromise<CreateAndUpdatePersonResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/{scopeId}/{tscid}/createPerson',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * Create notification
     * @param scopeId
     * @param tscid
     * @param personId
     * @param requestBody
     * @returns NotificationResponseDTO Successful operation
     * @throws ApiError
     */
    public static createNotification(
        scopeId: string,
        tscid: string,
        personId: number,
        requestBody: NotificationRequestDTO,
    ): CancelablePromise<NotificationResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/{scopeId}/{tscid}/createNotification/{personId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'personId': personId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * Get contact persons for an organisation
     * @param scopeId
     * @param tscid
     * @returns ContactPersonResponseDTO Successful operation
     * @throws ApiError
     */
    public static listPersons(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<Array<ContactPersonResponseDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/{tscid}/listPersons',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * List notifications for contact person
     * @param scopeId
     * @param tscid
     * @param personId
     * @returns NotificationsResponseDTO Successful operation
     * @throws ApiError
     */
    public static listNotifications(
        scopeId: string,
        tscid: string,
        personId: number,
    ): CancelablePromise<Array<NotificationsResponseDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/{tscid}/listNotifications/{personId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'personId': personId,
            },
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * Get contact person for personId
     * @param scopeId
     * @param tscid
     * @param personId
     * @returns GetPersonResponseDTO Successful operation
     * @throws ApiError
     */
    public static getPerson(
        scopeId: string,
        tscid: string,
        personId: number,
    ): CancelablePromise<GetPersonResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/{tscid}/getPerson/{personId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'personId': personId,
            },
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * Get notification details for notificationId
     * @param scopeId
     * @param tscid
     * @param notificationId
     * @returns NotificationsResponseDTO Successful operation
     * @throws ApiError
     */
    public static getNotification(
        scopeId: string,
        tscid: string,
        notificationId: number,
    ): CancelablePromise<NotificationsResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/{tscid}/getNotification/{notificationId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'notificationId': notificationId,
            },
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * Returns SLAs available for message services
     * @param scopeId
     * @returns SlaCountUI Success
     * @throws ApiError
     */
    public static getSlaCount(
        scopeId: string,
    ): CancelablePromise<Array<SlaCountUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/slas',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Returns all subscriptions in DATACOM that exists in WebOSS
     * @param scopeId
     * @param limit
     * @param offset
     * @param searchString
     * @param filter
     * @param sort
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getPaginatedDatacomSubscriptionList(
        scopeId: string,
        limit: number,
        offset: number,
        searchString?: string,
        filter?: Array<string>,
        sort?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/datacomsubscriptions',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'limit': limit,
                'offset': offset,
                'searchString': searchString,
                'filter': filter,
                'sort': sort,
            },
            errors: {
                400: `Bad HTTP request check the parameters`,
                403: `Access denied`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * @deprecated
     * Returns all subscriptions in DATACOM that exists in WebOSS
     * @param scopeId
     * @param tscid
     * @param limit
     * @param offset
     * @param searchString
     * @param filter
     * @param sort
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getPaginatedDatacomSubscriptionList1(
        scopeId: string,
        tscid: string,
        limit: number,
        offset: number,
        searchString?: string,
        filter?: Array<string>,
        sort?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/datacomsubscriptions/{tscid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'limit': limit,
                'offset': offset,
                'searchString': searchString,
                'filter': filter,
                'sort': sort,
            },
            errors: {
                400: `Bad HTTP request check the parameters`,
                403: `Access denied`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * Delete contact person
     * @param scopeId
     * @param tscid
     * @param personId
     * @returns string successful operation
     * @throws ApiError
     */
    public static deletePerson(
        scopeId: string,
        tscid: string,
        personId: number,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/public/{scopeId}/{tscid}/deletePerson/{personId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'personId': personId,
            },
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * Delete notification
     * @param scopeId
     * @param tscid
     * @param notificationId
     * @returns string successful operation
     * @throws ApiError
     */
    public static deleteNotification(
        scopeId: string,
        tscid: string,
        notificationId: number,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/public/{scopeId}/{tscid}/deleteNotification/{notificationId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'notificationId': notificationId,
            },
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

}