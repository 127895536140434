/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IpAddressUI } from '../models/IpAddressUI';
import type { MSISDN } from '../models/MSISDN';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmnControllerService {

    /**
     * Gets IP address connected to given EMN mobile subscription C2BUID. Using IP address attribute from addons EMNLBO2 or EMNLBO3. User must have PUI: 'PRODUCT_DISPLAY'
     * @param scopeId
     * @param c2BuId
     * @returns IpAddressUI Successful request
     * @throws ApiError
     */
    public static getIpAddress(
        scopeId: string,
        c2BuId: string,
    ): CancelablePromise<IpAddressUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/subscriptions/{c2buId}/emn/ipaddress',
            path: {
                'scopeId': scopeId,
                'c2buId': c2BuId,
            },
        });
    }

    /**
     * Sets IP address connected to given EMN mobile subscription C2BUID. Using IP address attribute from addons EMNLBO2 or EMNLBO3. User must have PUI: 'PRODUCT_ADMINISTRATION'
     * @param scopeId
     * @param c2BuId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static setIpAddress(
        scopeId: string,
        c2BuId: string,
        requestBody: IpAddressUI,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/scope/{scopeId}/subscriptions/{c2buId}/emn/ipaddress',
            path: {
                'scopeId': scopeId,
                'c2buId': c2BuId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Gets IP address connected to given EMN mobile subscription MSISDN. Using IP address attribute from addons EMNLBO2 or EMNLBO3.
     * @param msisdn
     * @returns IpAddressUI Successful request
     * @throws ApiError
     */
    public static getIpAddress1(
        msisdn: MSISDN,
    ): CancelablePromise<IpAddressUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/msisdn/{msisdn}/emn/ipaddress',
            path: {
                'msisdn': msisdn,
            },
        });
    }

}