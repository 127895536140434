/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BalancesResponseVO } from '../models/BalancesResponseVO';
import type { DataHistoryResponseVO } from '../models/DataHistoryResponseVO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileDatapoolControllerService {

    /**
     * User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param uid
     * @returns DataHistoryResponseVO Request to list subscription usage for current month successfully sent
     * @throws ApiError
     */
    public static getSubscriptionUsageCurrentMonth(
        scopeId: string,
        uid: string,
    ): CancelablePromise<Array<DataHistoryResponseVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/history/{uid}',
            path: {
                'scopeId': scopeId,
                'uid': uid,
            },
            errors: {
                400: `No subscription usage for current month found for the user`,
            },
        });
    }

    /**
     * User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param uid
     * @returns BalancesResponseVO Request to get balance successfully sent
     * @throws ApiError
     */
    public static getBalances(
        scopeId: string,
        uid: string,
    ): CancelablePromise<BalancesResponseVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/balances/{uid}',
            path: {
                'scopeId': scopeId,
                'uid': uid,
            },
            errors: {
                400: `No balance found for the user`,
            },
        });
    }

}