/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgreementListDTO } from '../models/AgreementListDTO';
import type { AgreementsExistsResponseDTO } from '../models/AgreementsExistsResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AgreementControllerService {

    /**
     * Get all agreements that an AccessProfile has access to.
     * @param scopeId
     * @param groupId Group ID
     * @param tscids
     * @returns AgreementListDTO OK
     * @throws ApiError
     */
    public static getAgreementsByTscidsOnAccessProfile(
        scopeId: string,
        groupId: number,
        tscids: Array<string>,
    ): CancelablePromise<AgreementListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/agreements',
            path: {
                'scopeId': scopeId,
                'groupId': groupId,
            },
            query: {
                'tscids': tscids,
            },
        });
    }

    /**
     * Returns true if there are any agreements connected to the group.
     * @param scopeId
     * @param groupId Group ID
     * @returns AgreementsExistsResponseDTO OK
     * @throws ApiError
     */
    public static getAgreementsExistsOnGroup(
        scopeId: string,
        groupId: number,
    ): CancelablePromise<AgreementsExistsResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/agreements/exists',
            path: {
                'scopeId': scopeId,
                'groupId': groupId,
            },
        });
    }

}