/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetFunctionsDTO } from '../models/GetFunctionsDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FunctionControllerService {

    /**
     * Get all functions in the MyBusiness context
     * @param scopeId
     * @returns GetFunctionsDTO OK
     * @throws ApiError
     */
    public static getFunctions(
        scopeId: string,
    ): CancelablePromise<GetFunctionsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/functions',
            path: {
                'scopeId': scopeId,
            },
        });
    }

}