/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UpdateUserCredentialsRequestDTO } from '../models/UpdateUserCredentialsRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ServiceWebControllerService {

    /**
     * @param scopeId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateUserCredentials(
        scopeId: string,
        requestBody: UpdateUserCredentialsRequestDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/serviceweb/{scopeId}/usercredentials',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}