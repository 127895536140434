/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HuntingGroupsResultDTO } from '../models/HuntingGroupsResultDTO';
import type { SwitchboardIdentifierDTO } from '../models/SwitchboardIdentifierDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublicSwitchboardsControllerService {

    /**
     * @deprecated
     * @param scopeId scopeId
     * @param c2Buid
     * @returns HuntingGroupsResultDTO OK
     * @throws ApiError
     */
    public static getHuntingGroups1(
        scopeId: any,
        c2Buid: string,
    ): CancelablePromise<HuntingGroupsResultDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/switchboards/hunting-groups/{c2buid}',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
        });
    }

    /**
     * @deprecated
     * @param scopeId scopeId
     * @param tscid
     * @returns SwitchboardIdentifierDTO OK
     * @throws ApiError
     */
    public static getSingleActiveTouchpoint(
        scopeId: any,
        tscid: string,
    ): CancelablePromise<SwitchboardIdentifierDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/switchboards/touchpoint',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

}