/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FrameAgreementUI } from '../models/FrameAgreementUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalAgreementControllerService {

    /**
     * Get agreement ID
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @returns FrameAgreementUI Successful request and agreement found
     * @throws ApiError
     */
    public static getAgreement1(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<FrameAgreementUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/scope/{scopeId}/organizations/{tscid}/agreement',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

}