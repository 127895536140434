/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HasUorOrderAccessUI } from '../models/HasUorOrderAccessUI';
import type { OrderMetadataUI } from '../models/OrderMetadataUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalControllerService {

    /**
     * hasAccessToUorOrders
     * @param scopeId scopeId
     * @param uorOrderIds uorOrderIds
     * @returns HasUorOrderAccessUI OK
     * @throws ApiError
     */
    public static hasAccessToUorOrdersUsingPost(
        scopeId: string,
        uorOrderIds: Array<string>,
    ): CancelablePromise<Array<HasUorOrderAccessUI>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-order-history/internal/customers/{scopeId}/orders/hasAccessToOrders',
            path: {
                'scopeId': scopeId,
            },
            body: uorOrderIds,
        });
    }

    /**
     * hasAccessToUorOrdersV2
     * @param scopeId scopeId
     * @param uorOrderIds uorOrderIds
     * @returns HasUorOrderAccessUI OK
     * @throws ApiError
     */
    public static hasAccessToUorOrdersV2UsingPost(
        scopeId: string,
        uorOrderIds: Array<string>,
    ): CancelablePromise<Array<HasUorOrderAccessUI>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-order-history/internal/customers/{scopeId}/orders/hasAccessToOrders/v2',
            path: {
                'scopeId': scopeId,
            },
            body: uorOrderIds,
        });
    }

    /**
     * getOrderMetadata
     * @param scopeId scopeId
     * @param type type
     * @param uId uId
     * @returns OrderMetadataUI OK
     * @throws ApiError
     */
    public static getOrderMetadataUsingGet(
        scopeId: string,
        type: 'UORORDERID' | 'TSCID',
        uId: string,
    ): CancelablePromise<OrderMetadataUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-order-history/internal/customers/{scopeId}/orders/{uId}/metadata',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
            query: {
                'type': type,
            },
        });
    }

    /**
     * hasAccessToUorOrder
     * @param scopeId scopeId
     * @param uororderid uororderid
     * @param uId uId
     * @returns HasUorOrderAccessUI OK
     * @throws ApiError
     */
    public static hasAccessToUorOrderUsingGet(
        scopeId: string,
        uororderid: string,
        uId?: string,
    ): CancelablePromise<HasUorOrderAccessUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-order-history/internal/customers/{scopeId}/orders/{uororderid}/hasAccess',
            path: {
                'scopeId': scopeId,
                'uId': uId,
                'uororderid': uororderid,
            },
        });
    }

}