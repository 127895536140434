/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BroadbandListUI } from '../models/BroadbandListUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductListingControllerService {

    /**
     * Get internet access products
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param installationAddressPointId
     * @param lang
     * @returns BroadbandListUI Successful request
     * @throws ApiError
     */
    public static getBroadband(
        scopeId: string,
        tscid: string,
        installationAddressPointId: string = '146351094',
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<BroadbandListUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/broadband',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'installationAddressPointId': installationAddressPointId,
                'lang': lang,
            },
        });
    }

}