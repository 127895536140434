/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvoiceSplitUI } from '../models/InvoiceSplitUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InvoiceSplitControllerService {

    /**
     * Get invoice split addon. User must have 'PRODUCT_ADMINISTRATION' to access this resource
     * @param scopeId
     * @param c2BuId
     * @returns InvoiceSplitUI Successful request
     * @throws ApiError
     */
    public static getInvoiceSplit(
        scopeId: string,
        c2BuId: string,
    ): CancelablePromise<InvoiceSplitUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/subscriptions/{c2buId}/invoice-split',
            path: {
                'scopeId': scopeId,
                'c2buId': c2BuId,
            },
        });
    }

    /**
     * Activates invoice split on given MSISDN. User must have PUI: 'PRODUCT_ADMINISTRATION' to access this resource
     * @param scopeId
     * @param c2BuId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static setInvoiceSplit(
        scopeId: string,
        c2BuId: string,
        requestBody: InvoiceSplitUI,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/subscriptions/{c2buId}/invoice-split',
            path: {
                'scopeId': scopeId,
                'c2buId': c2BuId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Deactivates invoice split on given MSISDN. User must have PUI: 'PRODUCT_ADMINISTRATION' to access this resource
     * @param scopeId
     * @param c2BuId
     * @returns void
     * @throws ApiError
     */
    public static deactivateInvoiceSplit(
        scopeId: string,
        c2BuId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/public/scope/{scopeId}/subscriptions/{c2buId}/invoice-split',
            path: {
                'scopeId': scopeId,
                'c2buId': c2BuId,
            },
        });
    }

    /**
     * Updates invoice split on given MSISDN. User must have PUI: 'PRODUCT_ADMINISTRATION' to access this resource
     * @param scopeId
     * @param c2BuId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateInvoiceSplit(
        scopeId: string,
        c2BuId: string,
        requestBody: InvoiceSplitUI,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/public/scope/{scopeId}/subscriptions/{c2buId}/invoice-split',
            path: {
                'scopeId': scopeId,
                'c2buId': c2BuId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}