/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllApplicationRequestsResponse } from '../models/AllApplicationRequestsResponse';
import type { GetApplicationRequestDetailsResponse } from '../models/GetApplicationRequestDetailsResponse';
import type { GetApplicationRequestsStatusCountsResponse } from '../models/GetApplicationRequestsStatusCountsResponse';
import type { RerunAutoapproveOnFailedApplicationRequestsResponse } from '../models/RerunAutoapproveOnFailedApplicationRequestsResponse';
import type { SendApplicationRequestToSnowResponse } from '../models/SendApplicationRequestToSnowResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaApplicationRequestControllerService {

    /**
     * @param requestId
     * @returns SendApplicationRequestToSnowResponse OK
     * @throws ApiError
     */
    public static sendApplicationRequestToSnow(
        requestId: number,
    ): CancelablePromise<SendApplicationRequestToSnowResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/teliaadmin/applicationrequests/{requestId}/sendtoservicenow',
            path: {
                'requestId': requestId,
            },
        });
    }

    /**
     * @param requestId
     * @param sendEmail
     * @returns any OK
     * @throws ApiError
     */
    public static requestMoreInfoApplicationRequest(
        requestId: number,
        sendEmail: boolean = true,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/teliaadmin/applicationrequests/{requestId}/requestmoreinfo',
            path: {
                'requestId': requestId,
            },
            query: {
                'sendEmail': sendEmail,
            },
        });
    }

    /**
     * @param requestId
     * @returns any OK
     * @throws ApiError
     */
    public static rejectApplicationRequest(
        requestId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/teliaadmin/applicationrequests/{requestId}/reject',
            path: {
                'requestId': requestId,
            },
        });
    }

    /**
     * @param requestId
     * @returns any OK
     * @throws ApiError
     */
    public static acceptApplicationRequest(
        requestId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/teliaadmin/applicationrequests/{requestId}/approve',
            path: {
                'requestId': requestId,
            },
        });
    }

    /**
     * @returns RerunAutoapproveOnFailedApplicationRequestsResponse OK
     * @throws ApiError
     */
    public static rerunAutoapproveOnFailedApplicationRequests(): CancelablePromise<RerunAutoapproveOnFailedApplicationRequestsResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/teliaadmin/applicationrequests/rerunfailed',
        });
    }

    /**
     * @returns AllApplicationRequestsResponse OK
     * @throws ApiError
     */
    public static getAllApplicationRequests(): CancelablePromise<AllApplicationRequestsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/teliaadmin/applicationrequests',
        });
    }

    /**
     * @param requestId
     * @returns GetApplicationRequestDetailsResponse OK
     * @throws ApiError
     */
    public static getApplicationRequestDetails(
        requestId: number,
    ): CancelablePromise<GetApplicationRequestDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/teliaadmin/applicationrequests/{requestId}',
            path: {
                'requestId': requestId,
            },
        });
    }

    /**
     * @returns GetApplicationRequestsStatusCountsResponse OK
     * @throws ApiError
     */
    public static getStatusCounts(): CancelablePromise<GetApplicationRequestsStatusCountsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/teliaadmin/applicationrequests/statuscount',
        });
    }

    /**
     * @param searchQuery
     * @returns AllApplicationRequestsResponse OK
     * @throws ApiError
     */
    public static searchApplicationRequestsByOrganizationNumbers(
        searchQuery: Array<string>,
    ): CancelablePromise<AllApplicationRequestsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/teliaadmin/applicationrequests/search',
            query: {
                'searchQuery': searchQuery,
            },
        });
    }

    /**
     * @param statuses
     * @param pageSize
     * @param pageNumber
     * @param sortField
     * @param sortDirection
     * @returns AllApplicationRequestsResponse OK
     * @throws ApiError
     */
    public static getApplicationRequestsForStatuses(
        statuses: Array<string>,
        pageSize?: number,
        pageNumber?: number,
        sortField: string = 'createdTimestamp',
        sortDirection: string = 'DESC',
    ): CancelablePromise<AllApplicationRequestsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/teliaadmin/applicationrequests/bystatus',
            query: {
                'statuses': statuses,
                'pageSize': pageSize,
                'pageNumber': pageNumber,
                'sortField': sortField,
                'sortDirection': sortDirection,
            },
        });
    }

}