/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CfDTO } from '../models/CfDTO';
import type { DataUsageContactDTO } from '../models/DataUsageContactDTO';
import type { GetUserAddressResponse } from '../models/GetUserAddressResponse';
import type { UpdateUserAddressesRequestUI } from '../models/UpdateUserAddressesRequestUI';
import type { UpdateUserAddressesResponse } from '../models/UpdateUserAddressesResponse';
import type { UpdateUserAddressRequestUI } from '../models/UpdateUserAddressRequestUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileSettingsControllerService {

    /**
     * Read the user address of a subscription
     * User must have 'PRODUCT_DISPLAY' to access this resource.
     * @param scopeId
     * @param c2Buid
     * @returns GetUserAddressResponse Successful request
     * @throws ApiError
     */
    public static getUserAddress(
        scopeId: string,
        c2Buid: string,
    ): CancelablePromise<GetUserAddressResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscription/{c2buid}/settings/user-address',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
        });
    }

    /**
     * Update the user address of a subscription
     * User must have 'PRODUCT_ADMINISTRATION' to access this resource.
     * @param scopeId
     * @param c2Buid
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateUserAddress(
        scopeId: string,
        c2Buid: string,
        requestBody: UpdateUserAddressRequestUI,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/scope/{scopeId}/subscription/{c2buid}/settings/user-address',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieves the contact information that will be used for notifying if the given MSISDN has reach 90% of its data.
     * User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param c2Buid
     * @returns DataUsageContactDTO Successful request
     * @throws ApiError
     */
    public static getSubscriptionContact(
        scopeId: string,
        c2Buid: string,
    ): CancelablePromise<DataUsageContactDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscription/{c2buid}/settings/data-usage-notifications',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Set the contact information that will be used for notifying the given MSISDN has reach 90% of its data.
     * User must have 'PRODUCT_ADMINISTRATION' to access this resource
     * @param scopeId
     * @param c2Buid
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static setSubscriptionContact(
        scopeId: string,
        c2Buid: string,
        requestBody: DataUsageContactDTO,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/scope/{scopeId}/subscription/{c2buid}/settings/data-usage-notifications',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update the address and user info of multiple subscriptions
     * User must have 'PRODUCT_ADMINISTRATION' to access this resource.
     * @param scopeId
     * @param requestBody
     * @returns UpdateUserAddressesResponse Successful request
     * @throws ApiError
     */
    public static updateUserAddresses(
        scopeId: string,
        requestBody: UpdateUserAddressesRequestUI,
    ): CancelablePromise<UpdateUserAddressesResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/scope/{scopeId}/subscription/settings/user-addresses',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Retrieve call forwarding settings for given c2buid.
     * User must have 'MYBUSINESS_ACCESS' to access this resource
     * @param scopeId
     * @param c2Buid
     * @returns CfDTO Successful request
     * @throws ApiError
     */
    public static getCallForwarding(
        scopeId: string,
        c2Buid: string,
    ): CancelablePromise<Array<CfDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscription/{c2buid}/settings/call-forwarding',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Retrieve call forwarding settings for given c2buid.
     * User must have 'MYBUSINESS_ACCESS' to access this resource
     * @param scopeId
     * @param c2Buid
     * @param requestBody
     * @returns CfDTO Successful request
     * @throws ApiError
     */
    public static postCallForwarding(
        scopeId: string,
        c2Buid: string,
        requestBody: CfDTO,
    ): CancelablePromise<Array<CfDTO>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}/subscription/{c2buid}/settings/call-forwarding',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

}