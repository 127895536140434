/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorpSlaControllerService {

    /**
     * Returns Service Objects for given subscription id
     * @param scopeId Customer identification number
     * @param uId Uid
     * @returns string Success
     * @throws ApiError
     */
    public static getServiceObjectsUsingGet(
        scopeId: string,
        uId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-installedbase-sla/scope/{scopeId}/subscription/{uId}/serviceobjects',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
            errors: {
                401: `Unauthorized`,
                403: `ACCESS_DENIED`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Returns SLA info for given subscription id
     * @param scopeId Customer identification number
     * @param uId Uid
     * @returns string Success
     * @throws ApiError
     */
    public static getSlaUsingGet(
        scopeId: string,
        uId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-installedbase-sla/scope/{scopeId}/subscription/{uId}/sla',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
            errors: {
                401: `Unauthorized`,
                403: `ACCESS_DENIED`,
                404: `Not Found`,
            },
        });
    }

}