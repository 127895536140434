/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetServiceWebsOnUserResponseDTO } from '../models/GetServiceWebsOnUserResponseDTO';
import type { UpdateServiceWebAttributesRequestDTO } from '../models/UpdateServiceWebAttributesRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserServiceWebControllerService {

    /**
     * Get service web profiles for a specific user on a group
     * @param groupId Customer Group ID
     * @param tcwssId TCWSS ID
     * @param serviceWeb Serviceweb
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateServiceWebProfileOnUser(
        groupId: number,
        tcwssId: string,
        serviceWeb: string,
        requestBody: UpdateServiceWebAttributesRequestDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/userserviceweb/customergroups/{groupId}/users/{tcwssId}/servicewebprofiles/{serviceWeb}',
            path: {
                'groupId': groupId,
                'tcwssId': tcwssId,
                'serviceWeb': serviceWeb,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param groupId Customer Group ID
     * @param tcwssId TCWSS ID
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateSurfBlasterServiceWebProfileOnUser(
        groupId: number,
        tcwssId: string,
        requestBody: UpdateServiceWebAttributesRequestDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/userserviceweb/customergroups/{groupId}/users/{tcwssId}/servicewebprofiles/SURFBLASTER',
            path: {
                'groupId': groupId,
                'tcwssId': tcwssId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param groupId Customer Group ID
     * @param tcwssId TCWSS ID
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateInAhsServiceWebProfileOnUser(
        groupId: number,
        tcwssId: string,
        requestBody: UpdateServiceWebAttributesRequestDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/userserviceweb/customergroups/{groupId}/users/{tcwssId}/servicewebprofiles/IN_AHS',
            path: {
                'groupId': groupId,
                'tcwssId': tcwssId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get service web profiles for a specific user on a group
     * @param groupId Customer Group ID
     * @param tcwssId TCWSS ID
     * @returns GetServiceWebsOnUserResponseDTO OK
     * @throws ApiError
     */
    public static getServiceWebProfilesOnUser(
        groupId: number,
        tcwssId: string,
    ): CancelablePromise<GetServiceWebsOnUserResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/userserviceweb/customergroups/{groupId}/users/{tcwssId}/servicewebprofiles',
            path: {
                'groupId': groupId,
                'tcwssId': tcwssId,
            },
        });
    }

}