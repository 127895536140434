/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RelationsUI } from '../models/RelationsUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RelationsControllerImplService {

    /**
     * Returns all Datacom relations
     * @param scopeId Customer identification number
     * @param uId uId
     * @returns RelationsUI Success
     * @throws ApiError
     */
    public static getRelationsUsingGet(
        scopeId: string,
        uId: string,
    ): CancelablePromise<RelationsUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscription/{uId}/relations',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}