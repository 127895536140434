/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProfileDTO } from '../models/ProfileDTO';
import type { UpdateAccessProfileRequestDTO } from '../models/UpdateAccessProfileRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccessProfileControllerService {

    /**
     * Get the accessprofile by id
     * @param scopeId
     * @param groupId Customer Group ID
     * @param id Id of the AccessProfile
     * @returns ProfileDTO Successful response
     * @throws ApiError
     */
    public static getUserProfile1(
        scopeId: string,
        groupId: number,
        id: number,
    ): CancelablePromise<ProfileDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/accessprofiles/{id}',
            path: {
                'scopeId': scopeId,
                'groupId': groupId,
                'id': id,
            },
        });
    }

    /**
     * Updates the users accessProfile
     * @param scopeId
     * @param groupId Customer Group ID
     * @param id Id of the AccessProfile to update
     * @param requestBody
     * @returns UpdateAccessProfileRequestDTO OK
     * @throws ApiError
     */
    public static updateProfile(
        scopeId: string,
        groupId: number,
        id: number,
        requestBody: UpdateAccessProfileRequestDTO,
    ): CancelablePromise<UpdateAccessProfileRequestDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/{scopeId}/customergroups/{groupId}/accessprofiles/{id}',
            path: {
                'scopeId': scopeId,
                'groupId': groupId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}