/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerGroupWithAccessDTO } from '../models/CustomerGroupWithAccessDTO';
import type { GetCustomerGroupResponseDTO } from '../models/GetCustomerGroupResponseDTO';
import type { StructureSearchResultDTO } from '../models/StructureSearchResultDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StructureControllerService {

    /**
     * Get a Customer Group
     * @param scopeId
     * @param groupId Customer Group ID
     * @returns GetCustomerGroupResponseDTO OK
     * @throws ApiError
     */
    public static getCustomerGroup(
        scopeId: string,
        groupId: number,
    ): CancelablePromise<GetCustomerGroupResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}',
            path: {
                'scopeId': scopeId,
                'groupId': groupId,
            },
        });
    }

    /**
     * List/search for units in all organizations in customer group filtered by access profile
     * @param scopeId
     * @param groupId Customer Group ID
     * @param search Search term (leave empty for all)
     * @returns StructureSearchResultDTO OK
     * @throws ApiError
     */
    public static searchUnits(
        scopeId: string,
        groupId: number,
        search?: string,
    ): CancelablePromise<StructureSearchResultDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/units',
            path: {
                'scopeId': scopeId,
                'groupId': groupId,
            },
            query: {
                'search': search,
            },
        });
    }

    /**
     * List/search for organizations in customer group
     * @param scopeId
     * @param groupId Customer Group ID
     * @param search Search term (leave empty for all)
     * @returns StructureSearchResultDTO OK
     * @throws ApiError
     */
    public static searchOrganizations(
        scopeId: string,
        groupId: number,
        search?: string,
    ): CancelablePromise<StructureSearchResultDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/organizations',
            path: {
                'scopeId': scopeId,
                'groupId': groupId,
            },
            query: {
                'search': search,
            },
        });
    }

    /**
     * List/search for cost centers in all organizations in customer group filtered by access profile
     * @param scopeId
     * @param groupId Customer Group ID
     * @param search Search term (leave empty for all)
     * @returns StructureSearchResultDTO OK
     * @throws ApiError
     */
    public static searchCostCenters(
        scopeId: string,
        groupId: number,
        search?: string,
    ): CancelablePromise<StructureSearchResultDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/costcenters',
            path: {
                'scopeId': scopeId,
                'groupId': groupId,
            },
            query: {
                'search': search,
            },
        });
    }

    /**
     * Get a Customer Group with information about what parts a user have access to
     * @param scopeId
     * @param groupId Customer Group ID
     * @param tcwssid Include access restrictions for user by TCWSSID
     * @returns CustomerGroupWithAccessDTO OK
     * @throws ApiError
     */
    public static getCustomerGroupForUser(
        scopeId: string,
        groupId: number,
        tcwssid: string,
    ): CancelablePromise<CustomerGroupWithAccessDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/access/{tcwssid}',
            path: {
                'scopeId': scopeId,
                'groupId': groupId,
                'tcwssid': tcwssid,
            },
        });
    }

}