/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductListResponse } from '../models/ProductListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductService {

    /**
     * Returns list of products
     * @param xAuthScopeId
     * @returns ProductListResponse OK
     * @throws ApiError
     */
    public static getProducts(
        xAuthScopeId: any,
    ): CancelablePromise<ProductListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/assets',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

}