/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ValidateSignatoryResponseDTO } from '../models/ValidateSignatoryResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ValidationControllerService {

    /**
     * Validates if a user can sign for a company.
     * @param organisationNumber
     * @returns ValidateSignatoryResponseDTO OK
     * @throws ApiError
     */
    public static validateSignatory(
        organisationNumber: string,
    ): CancelablePromise<ValidateSignatoryResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/organisation/validate-signatory',
            query: {
                'organisationNumber': organisationNumber,
            },
        });
    }

}