/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetAvailableScopesResponse } from '../models/GetAvailableScopesResponse';
import type { GetOrganizationResponse } from '../models/GetOrganizationResponse';
import type { GetScopeDetailsResponse } from '../models/GetScopeDetailsResponse';
import type { GetScopeForLoggedInUserResponse } from '../models/GetScopeForLoggedInUserResponse';
import type { GetServiceWebsResponse } from '../models/GetServiceWebsResponse';
import type { HasAccessToScopeResponse } from '../models/HasAccessToScopeResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ScopeControllerService {

    /**
     * Stores given scopeId for logged in user.
     * For the given scopeId the corresponding groupId is stored in EAM, so matching scopeId later could be fetched using '/{scopeId}/scopeId'.
     * @param scopeId
     * @returns any OK
     * @throws ApiError
     */
    public static setScopeForCustomer(
        scopeId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/{scopeId}/change',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Get details about the given scope.
     * The response section 'unavailable' contains actions that the user doesn't have access to in current state, e.g. not available for TCAD users 'ACTION_NOT_VALID_FOR_TCAD'.
     * @param scopeId
     * @returns GetScopeDetailsResponse OK
     * @throws ApiError
     */
    public static getScopeDetails(
        scopeId: string,
    ): CancelablePromise<GetScopeDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Gets all available servicewebs for the user and scope.
     * @param scopeId
     * @returns GetServiceWebsResponse OK
     * @throws ApiError
     */
    public static getServiceWebs(
        scopeId: string,
    ): CancelablePromise<GetServiceWebsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/servicewebs',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Gets a valid scope for the logged in user.
     * If a scope has been previously saved (in EAM) for the user using '/{scopeId}/change'; the last used scope (if valid) is returned. If no valid scope is found an empty scope is returned (i.e customerScope = null).
     * @param scopeId LOGGED_IN_USER_NO_SCOPE is the only allowed {scopeId} for this endpoint
     * @returns GetScopeForLoggedInUserResponse OK
     * @throws ApiError
     */
    public static getScopeForLoggedInUser(
        scopeId: string,
    ): CancelablePromise<GetScopeForLoggedInUserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/scopeid',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Gets all organizations for the user on the scope.
     * @param scopeId
     * @param hyphen
     * @returns GetOrganizationResponse OK
     * @throws ApiError
     */
    public static getOrganizationsForScope(
        scopeId: string,
        hyphen: string = 'false',
    ): CancelablePromise<Array<GetOrganizationResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/organizations',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'hyphen': hyphen,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Checks if user is logged in. The auth framework will throw a 401 if not, otherwise 200 OK.
     * @param scopeId LOGGED_IN_USER_NO_SCOPE is only allowed {scopeId} for this endpoint
     * @returns any OK
     * @throws ApiError
     */
    public static checkIfUserIsLoggedIn(
        scopeId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/isloggedin',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Gets all available scopes for the user and scope.
     * @param scopeId
     * @returns GetAvailableScopesResponse OK
     * @throws ApiError
     */
    public static getAvailableScopes(
        scopeId: string,
    ): CancelablePromise<GetAvailableScopesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/available',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * Checks if user has access to given scopeIdToVerifyAccess.
     * @param scopeId LOGGED_IN_USER_NO_SCOPE is the only allowed {scopeId} for this endpoint
     * @param scopeIdToVerifyAccess
     * @returns HasAccessToScopeResponse OK
     * @throws ApiError
     */
    public static hasAccessToScope(
        scopeId: string,
        scopeIdToVerifyAccess: string,
    ): CancelablePromise<HasAccessToScopeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/access/{scopeIdToVerifyAccess}',
            path: {
                'scopeId': scopeId,
                'scopeIdToVerifyAccess': scopeIdToVerifyAccess,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

}