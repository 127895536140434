/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddonGroupUI } from '../models/AddonGroupUI';
import type { AddonPriceDTO } from '../models/AddonPriceDTO';
import type { ExtraUnitUI } from '../models/ExtraUnitUI';
import type { SubscriptionAddonTitle } from '../models/SubscriptionAddonTitle';
import type { SwitchboardUI } from '../models/SwitchboardUI';
import type { TouchpointUI } from '../models/TouchpointUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileAddonsControllerService {

    /**
     * @deprecated
     * Gets user's touch point information. User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param c2Buid
     * @param lang
     * @returns TouchpointUI Successful request
     * @throws ApiError
     */
    public static getTouchpoint(
        scopeId: string,
        c2Buid: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<TouchpointUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscriptions/{c2buid}/touchpoint',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            query: {
                'lang': lang,
            },
        });
    }

    /**
     * User must have 'PRODUCT_DISPLAY' to access this resource
     * Gets user's switchboard information from Amanda, EFS, C2B-C and corp-switchboards. Is used in MANAGE page to show the switchboard component. Should replace /touchpoint endpoint
     * @param scopeId
     * @param c2Buid
     * @param lang
     * @returns SwitchboardUI Successful request
     * @throws ApiError
     */
    public static getSwitchboard(
        scopeId: string,
        c2Buid: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<Array<SwitchboardUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscriptions/{c2buid}/switchboard',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            query: {
                'lang': lang,
            },
        });
    }

    /**
     * Gets purchasable extra units. User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param c2Buid
     * @param lang
     * @returns ExtraUnitUI Successful request
     * @throws ApiError
     */
    public static getExtraUnits(
        scopeId: string,
        c2Buid: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<Array<ExtraUnitUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscriptions/{c2buid}/extraunits',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            query: {
                'lang': lang,
            },
        });
    }

    /**
     * Gets addon details for all addons connected to subscription identified by c2buid. User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param c2Buid
     * @param lang
     * @returns AddonGroupUI Successful request
     * @throws ApiError
     */
    public static getAddonDetails(
        scopeId: string,
        c2Buid: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<Array<AddonGroupUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscriptions/{c2buid}/addons',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            query: {
                'lang': lang,
            },
        });
    }

    /**
     * Gets addon prices connected to subscription identified by c2buid. User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param c2Buid
     * @returns AddonPriceDTO Successful request
     * @throws ApiError
     */
    public static getPrices(
        scopeId: string,
        c2Buid: string,
    ): CancelablePromise<Array<AddonPriceDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscriptions/{c2buid}/V1/prices',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
        });
    }

    /**
     * Gets addon details as a MobileSubscriptionAddon list with titles for all addons connected to subscription identified by c2buid. User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param c2Buid
     * @param lang
     * @returns SubscriptionAddonTitle Successful request
     * @throws ApiError
     */
    public static getAddonStructure(
        scopeId: string,
        c2Buid: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<Array<SubscriptionAddonTitle>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscriptions/{c2buid}/V1/addons',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            query: {
                'lang': lang,
            },
        });
    }

}