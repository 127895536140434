/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookFixedNumberDTO } from '../models/BookFixedNumberDTO';
import type { FinalizeFixedNumberDTO } from '../models/FinalizeFixedNumberDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublicFixedNumberBookingControllerService {

    /**
     * Extend a bunch of fixed numbers
     * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
     * @param scopeId
     * @param tscid
     * @param instanceId
     * @param bookingId
     * @param switchboardType
     * @returns void
     * @throws ApiError
     */
    public static extendFixedNumbers(
        scopeId: string,
        tscid: string,
        instanceId: string,
        bookingId: string,
        switchboardType: 'TP' | 'SC' = 'TP',
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/corp-number-booking/public/scope/{scopeId}/organizations/{tscid}/instance/{instanceId}/extend-fixed-numbers/{bookingId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'instanceId': instanceId,
                'bookingId': bookingId,
            },
            query: {
                'switchboardType': switchboardType,
            },
            errors: {
                403: `Access denied`,
                404: `Number not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Release and book new fixed numbers
     * User must have PUI 'ORDER_SWITCHBOARD_USER' to access this resource
     * @param scopeId
     * @param tscid
     * @param instanceId
     * @param bookingId
     * @param switchboardType
     * @param numberToSearch
     * @returns BookFixedNumberDTO Numbers was successfully booked
     * @throws ApiError
     */
    public static releaseAndBookFixedNumber(
        scopeId: string,
        tscid: string,
        instanceId: string,
        bookingId: string,
        switchboardType: 'TP' | 'SC' = 'TP',
        numberToSearch?: string,
    ): CancelablePromise<BookFixedNumberDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-number-booking/public/scope/{scopeId}/organizations/{tscid}/instance/{instanceId}/release-and-book-fixed-numbers/{bookingId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'instanceId': instanceId,
                'bookingId': bookingId,
            },
            query: {
                'switchboardType': switchboardType,
                'numberToSearch': numberToSearch,
            },
            errors: {
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Finalize the booking of a fixed number
     * User must have PUI 'ORDER_SWITCHBOARD_USER' to access this resource
     * @param scopeId
     * @param tscid
     * @param instanceId
     * @param bookingId
     * @param requestBody
     * @param switchboardType
     * @returns void
     * @throws ApiError
     */
    public static finalizeFixedNumberBooking(
        scopeId: string,
        tscid: string,
        instanceId: string,
        bookingId: string,
        requestBody: FinalizeFixedNumberDTO,
        switchboardType: 'TP' | 'SC' = 'TP',
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-number-booking/public/scope/{scopeId}/organizations/{tscid}/instance/{instanceId}/finalize-fixed-number-booking/{bookingId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'instanceId': instanceId,
                'bookingId': bookingId,
            },
            query: {
                'switchboardType': switchboardType,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Book fixed numbers
     * User must have PUI 'ORDER_SWITCHBOARD_USER' to access this resource
     * @param scopeId
     * @param tscid
     * @param instanceId
     * @param switchboardType
     * @param searchStr
     * @returns BookFixedNumberDTO Numbers was successfully booked
     * @throws ApiError
     */
    public static bookFixedNumber(
        scopeId: string,
        tscid: string,
        instanceId: string,
        switchboardType: 'TP' | 'SC' = 'TP',
        searchStr?: string,
    ): CancelablePromise<BookFixedNumberDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-number-booking/public/scope/{scopeId}/organizations/{tscid}/instance/{instanceId}/book-fixed-numbers',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'instanceId': instanceId,
            },
            query: {
                'switchboardType': switchboardType,
                'searchStr': searchStr,
            },
            errors: {
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Release fixed numbers
     * User must have PUI 'ORDER_SWITCHBOARD_USER' to access this resource
     * @param scopeId
     * @param tscid
     * @param instanceId
     * @param bookingId
     * @param switchboardType
     * @returns any Numbers was successfully booked
     * @throws ApiError
     */
    public static releaseFixedNumber(
        scopeId: string,
        tscid: string,
        instanceId: string,
        bookingId: string,
        switchboardType: 'TP' | 'SC' = 'TP',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/corp-number-booking/public/scope/{scopeId}/organizations/{tscid}/instance/{instanceId}/release-fixed-numbers/{bookingId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'instanceId': instanceId,
                'bookingId': bookingId,
            },
            query: {
                'switchboardType': switchboardType,
            },
            errors: {
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

}