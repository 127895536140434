/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SetActiveServiceWebsOnOrganizationsRequestDTO } from '../models/SetActiveServiceWebsOnOrganizationsRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ServiceWebApiForTeliaAdminService {

    /**
     * Set which service webs are active per organization
     * @param groupId Customer Group ID
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static setActiveServiceWebsOnOrganizations(
        groupId: number,
        requestBody: SetActiveServiceWebsOnOrganizationsRequestDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/customergroups/{groupId}/servicewebs',
            path: {
                'groupId': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}