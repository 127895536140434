/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductAvailability } from '../models/ProductAvailability';
import type { RequestProduct } from '../models/RequestProduct';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StockStatusControllerService {

    /**
     * Gets stock status for specific sap ids
     * @param requestBody
     * @returns ProductAvailability Stock statuses mapped to sap ids
     * @throws ApiError
     */
    public static getMultiUsingPost(
        requestBody?: Array<RequestProduct>,
    ): CancelablePromise<Array<ProductAvailability>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-stock-status/v1',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad input, probably bad sap id`,
                500: `Server error`,
            },
        });
    }

}