/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MobileSubscriptionTypeResponse } from '../models/MobileSubscriptionTypeResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileSubscriptionTypeControllerService {

    /**
     * fetch subscription types from c2bcache based on PUI access
     * User must have PUI: 'PRODUCT_ADMINISTRATION' to access this resource
     * @param scopeId
     * @param tscId
     * @param agreementNumber
     * @param productCategories
     * @returns MobileSubscriptionTypeResponse Successful request
     * @throws ApiError
     */
    public static getMobileCustomer(
        scopeId: string,
        tscId: string,
        agreementNumber: string,
        productCategories?: Array<'M2M' | 'VOICE' | 'DATA'>,
    ): CancelablePromise<MobileSubscriptionTypeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/organizations/{tscId}/agreements/{agreementNumber}',
            path: {
                'scopeId': scopeId,
                'tscId': tscId,
                'agreementNumber': agreementNumber,
            },
            query: {
                'productCategories': productCategories,
            },
        });
    }

}