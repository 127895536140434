/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddOfferingRequestDTO } from '../models/AddOfferingRequestDTO';
import type { ConfigurationDTO } from '../models/ConfigurationDTO';
import type { StartConfigurationRequestDTO } from '../models/StartConfigurationRequestDTO';
import type { StartConfigurationResponseDTO } from '../models/StartConfigurationResponseDTO';
import type { UpdateConfigurationRequestDTO } from '../models/UpdateConfigurationRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConfigurationControllerService {

    /**
     * Update configuration
     * @param configurationId
     * @param requestBody
     * @param lang
     * @returns ConfigurationDTO Updates configuration
     * @throws ApiError
     */
    public static updateConfiguration(
        configurationId: string,
        requestBody: UpdateConfigurationRequestDTO,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<ConfigurationDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/configuration/{configurationId}/update',
            path: {
                'configurationId': configurationId,
            },
            query: {
                'lang': lang,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Calling endpoint for adding optional child offering to product configuration
     * @param configurationId
     * @param requestBody
     * @param lang
     * @returns ConfigurationDTO Returns configuration
     * @throws ApiError
     */
    public static addOffering(
        configurationId: string,
        requestBody: AddOfferingRequestDTO,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<ConfigurationDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/configuration/{configurationId}/offerings',
            path: {
                'configurationId': configurationId,
            },
            query: {
                'lang': lang,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Start a product configuration
     * @param requestBody
     * @returns StartConfigurationResponseDTO Returns the detached basket Id used for configuration
     * @throws ApiError
     */
    public static start(
        requestBody: StartConfigurationRequestDTO,
    ): CancelablePromise<StartConfigurationResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/configuration/start',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a detached configuration
     * @param configurationId
     * @param lang
     * @returns ConfigurationDTO Returns configuration
     * @throws ApiError
     */
    public static getConfigurationById(
        configurationId: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<ConfigurationDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/configuration/{configurationId}',
            path: {
                'configurationId': configurationId,
            },
            query: {
                'lang': lang,
            },
        });
    }

    /**
     * Calling endpoint for deleting optional child offering from product configuration
     * @param configurationId
     * @param itemId
     * @param lang
     * @returns ConfigurationDTO Returns configuration
     * @throws ApiError
     */
    public static deleteOffering(
        configurationId: string,
        itemId: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<ConfigurationDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/public/configuration/{configurationId}/offerings/{itemId}',
            path: {
                'configurationId': configurationId,
                'itemId': itemId,
            },
            query: {
                'lang': lang,
            },
        });
    }

}