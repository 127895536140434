/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddDataConnectionRequestUI } from '../models/AddDataConnectionRequestUI';
import type { AddMdsConnectionRequestUI } from '../models/AddMdsConnectionRequestUI';
import type { GetConnectionsResponseUI } from '../models/GetConnectionsResponseUI';
import type { GetMdsEligibilityResponseUI } from '../models/GetMdsEligibilityResponseUI';
import type { SendSmartwatchInstructionsRequestUI } from '../models/SendSmartwatchInstructionsRequestUI';
import type { SubscriptionConnectionUI } from '../models/SubscriptionConnectionUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileConnectionsControllerService {

    /**
     * Add a pending MDS-connection on given msisdn
     * User must have 'PRODUCT_ADMINISTRATION' to access this resource
     * @param scopeId
     * @param c2BUId
     * @param requestBody
     * @returns SubscriptionConnectionUI Created!
     * @throws ApiError
     */
    public static addPendingMdsConnection(
        scopeId: string,
        c2BUId: string,
        requestBody: AddMdsConnectionRequestUI,
    ): CancelablePromise<SubscriptionConnectionUI> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/scope/{scopeId}/subscriptions/{c2bUId}/mds',
            path: {
                'scopeId': scopeId,
                'c2bUId': c2BUId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Remove a pending MDS-connection on given msisdn
     * User must have 'PRODUCT_ADMINISTRATION' to access this resource
     * @param scopeId
     * @param c2BUId
     * @returns void
     * @throws ApiError
     */
    public static removePendingMdsConnection(
        scopeId: string,
        c2BUId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/scope/{scopeId}/subscriptions/{c2bUId}/mds',
            path: {
                'scopeId': scopeId,
                'c2bUId': c2BUId,
            },
            errors: {
                400: `Bad request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Gets connection information of given c2buid
     * User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param c2BUId
     * @returns GetConnectionsResponseUI Successful request
     * @throws ApiError
     */
    public static getConnections(
        scopeId: string,
        c2BUId: string,
    ): CancelablePromise<GetConnectionsResponseUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscriptions/{c2bUId}/connections',
            path: {
                'scopeId': scopeId,
                'c2bUId': c2BUId,
            },
        });
    }

    /**
     * Add a connection on given msisdn
     * User must have 'PRODUCT_ADMINISTRATION' to access this resource
     * @param scopeId
     * @param c2BUId
     * @param requestBody
     * @returns SubscriptionConnectionUI Created!
     * @throws ApiError
     */
    public static addDataConnection(
        scopeId: string,
        c2BUId: string,
        requestBody: AddDataConnectionRequestUI,
    ): CancelablePromise<SubscriptionConnectionUI> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/scope/{scopeId}/subscriptions/{c2bUId}/connections',
            path: {
                'scopeId': scopeId,
                'c2bUId': c2BUId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Send email with e-sim activation instructions for smartwatch
     * User must have 'PRODUCT_ADMINISTRATION' to access this resource
     * @param scopeId
     * @param c2BUId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static sendSmartwatchActivationInstructions(
        scopeId: string,
        c2BUId: string,
        requestBody: SendSmartwatchInstructionsRequestUI,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}/subscriptions/{c2bUId}/connections/smartwatch-instructions',
            path: {
                'scopeId': scopeId,
                'c2bUId': c2BUId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Performs a check to see if given c2buid can buy MDS.
     * Uses subscription data and esim data to see if the subscription has a pending mds activation, and whether they are entitled to one.User must have 'PRODUCT_ADMINISTRATION' to access this resource
     * @param scopeId
     * @param c2BUId
     * @returns GetMdsEligibilityResponseUI Ok!
     * @throws ApiError
     */
    public static getMdsEligibility(
        scopeId: string,
        c2BUId: string,
    ): CancelablePromise<GetMdsEligibilityResponseUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscriptions/{c2bUId}/mds/eligibility',
            path: {
                'scopeId': scopeId,
                'c2bUId': c2BUId,
            },
            errors: {
                400: `Bad request`,
                500: `Internal Server Error`,
            },
        });
    }

}