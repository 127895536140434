/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerAliasRequest } from '../models/CustomerAliasRequest';
import type { VpnCountUI } from '../models/VpnCountUI';
import type { VpnUI } from '../models/VpnUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VpnControllerService {

    /**
     * Returns a list of VPNs on a subscription.
     * @param scopeId Customer identification number
     * @param uId uId
     * @returns VpnUI Success
     * @throws ApiError
     */
    public static getVpnsOnSubscriptionUsingGet(
        scopeId: string,
        uId: string,
    ): CancelablePromise<Array<VpnUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscription/{uId}/vpns',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Returns a list of VPNs based on user and customer.
     * @param scopeId Customer identification number
     * @param tscid tscid
     * @returns VpnUI Success
     * @throws ApiError
     */
    public static getVpnsUsingGet(
        scopeId: string,
        tscid?: string,
    ): CancelablePromise<Array<VpnUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/vpns',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'tscid': tscid,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Returns count of VPNs based on user and customer.
     * @param scopeId Customer identification number
     * @returns VpnCountUI Success
     * @throws ApiError
     */
    public static getVpnCountUsingGet1(
        scopeId: string,
    ): CancelablePromise<VpnCountUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/vpns/count',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Returns a VPN based on user, customer and uId.
     * @param scopeId scopeId
     * @param uId uId
     * @returns VpnUI Success
     * @throws ApiError
     */
    public static getVpnUsingGet(
        scopeId: string,
        uId: string,
    ): CancelablePromise<VpnUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/vpns/{uId}',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Saves the alias of the VPN on the given ID.
     * @param customerAliasRequest customerAliasRequest
     * @param scopeId Customer identification number
     * @param uId uId
     * @returns any OK
     * @throws ApiError
     */
    public static putVpnAliasUsingPut(
        customerAliasRequest: CustomerAliasRequest,
        scopeId: string,
        uId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/scope/{scopeId}/vpns/{uId}/customeralias',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
            body: customerAliasRequest,
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}