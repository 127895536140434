/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExportInvoiceInformationRequest } from '../models/ExportInvoiceInformationRequest';
import type { GetInvoicePdfResponse } from '../models/GetInvoicePdfResponse';
import type { InvoiceListResponse } from '../models/InvoiceListResponse';
import type { InvoicePreview } from '../models/InvoicePreview';
import type { SubscriptionList } from '../models/SubscriptionList';
import type { SubSpecificationResponse } from '../models/SubSpecificationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorpCustomerInvoicesService {

    /**
     * Export invoices as a csv file
     * @param scopeId
     * @param tscid
     * @param exportRequest
     * @returns any Successful operation
     * @throws ApiError
     */
    public static exportInvoiceInformation(
        scopeId: string,
        tscid: string,
        exportRequest: ExportInvoiceInformationRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/getInvoiceList/{scopeId}/invoices/export/{tscid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'exportRequest': exportRequest,
            },
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * Get invoice summary
     * @param scopeId
     * @param tscid
     * @param invoiceId
     * @param billingSystemId
     * @returns InvoicePreview Successful operation
     * @throws ApiError
     */
    public static getInvoicePreview(
        scopeId: string,
        tscid: string,
        invoiceId: string,
        billingSystemId: string,
    ): CancelablePromise<InvoicePreview> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/{scopeId}/preview',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'tscid': tscid,
                'invoiceId': invoiceId,
                'billingSystemID': billingSystemId,
            },
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * Get sub specifications for an invoice preview
     * @param scopeId
     * @param tscid
     * @param invoiceId
     * @param billingSystemId
     * @returns SubSpecificationResponse Successful operation
     * @throws ApiError
     */
    public static getInvoicePreviewSubSpecs(
        scopeId: string,
        tscid: string,
        invoiceId: string,
        billingSystemId: string,
    ): CancelablePromise<SubSpecificationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/{scopeId}/preview/subspecs',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'tscid': tscid,
                'invoiceId': invoiceId,
                'billingSystemID': billingSystemId,
            },
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * Get invoice pdf
     * @param scopeId
     * @param tscid
     * @param invoiceId
     * @param billingSystemId
     * @param pageFrom
     * @param pageTo
     * @returns GetInvoicePdfResponse Successful operation
     * @throws ApiError
     */
    public static getInvoicePdf(
        scopeId: string,
        tscid: string,
        invoiceId: string,
        billingSystemId: string,
        pageFrom?: number,
        pageTo?: number,
    ): CancelablePromise<GetInvoicePdfResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/{scopeId}/pdf',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'tscid': tscid,
                'invoiceId': invoiceId,
                'billingSystemID': billingSystemId,
                'pageFrom': pageFrom,
                'pageTo': pageTo,
            },
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * Get amount of pdf pages
     * @param scopeId
     * @param tscid
     * @param invoiceId
     * @param billingSystemId
     * @returns GetInvoicePdfResponse Successful operation
     * @throws ApiError
     */
    public static getPdfSize(
        scopeId: string,
        tscid: string,
        invoiceId: string,
        billingSystemId: string,
    ): CancelablePromise<GetInvoicePdfResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/{scopeId}/pdf/size',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'tscid': tscid,
                'invoiceId': invoiceId,
                'billingSystemID': billingSystemId,
            },
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * Get full XML of an invoice in file
     * @param scopeId
     * @param tscid
     * @param invoiceId
     * @param billingSystemId
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getInvoiceXmlForDebug(
        scopeId: string,
        tscid: string,
        invoiceId: string,
        billingSystemId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/{scopeId}/debug/xml',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'tscid': tscid,
                'invoiceId': invoiceId,
                'billingSystemID': billingSystemId,
            },
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * Search invoice with subscription id
     * @param scopeId
     * @param tscid
     * @param searchParam
     * @param accountNumber
     * @returns SubscriptionList Successful operation
     * @throws ApiError
     */
    public static searchSubscription(
        scopeId: string,
        tscid: string,
        searchParam: string,
        accountNumber?: string,
    ): CancelablePromise<SubscriptionList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/searchSubscriptions/{scopeId}/subscriptions',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'tscid': tscid,
                'searchParam': searchParam,
                'accountNumber': accountNumber,
            },
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

    /**
     * Get invoice information
     * @param scopeId
     * @param tscid
     * @param fromInvoiceDate
     * @param toInvoiceDate
     * @param invoiceFrom
     * @param invoiceTo
     * @param invoiceNumber
     * @param subscriptionNumber
     * @param accountNumber
     * @returns InvoiceListResponse Successful operation
     * @throws ApiError
     */
    public static getInvoiceInformation(
        scopeId: string,
        tscid: string,
        fromInvoiceDate?: string,
        toInvoiceDate?: string,
        invoiceFrom?: number,
        invoiceTo?: number,
        invoiceNumber?: string,
        subscriptionNumber?: string,
        accountNumber?: string,
    ): CancelablePromise<InvoiceListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/getInvoiceList/{scopeId}/invoices',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'tscid': tscid,
                'fromInvoiceDate': fromInvoiceDate,
                'toInvoiceDate': toInvoiceDate,
                'invoiceFrom': invoiceFrom,
                'invoiceTo': invoiceTo,
                'invoiceNumber': invoiceNumber,
                'subscriptionNumber': subscriptionNumber,
                'accountNumber': accountNumber,
            },
            errors: {
                400: `Bad HTTP request check the parameters`,
                500: `Service unavailable`,
            },
        });
    }

}