/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CostControlControllerService {

    /**
     * Update a GSM-AHS service on given c2buid.  User must have PUI: 'PRODUCT_ADMINISTRATION'
     * @param scopeId
     * @param c2BuId
     * @param amount
     * @returns any Success
     * @throws ApiError
     */
    public static updateCostControl(
        scopeId: string,
        c2BuId: string,
        amount: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/public/scope/{scopeId}/subscriptions/{c2buId}/costcontrol/{amount}',
            path: {
                'scopeId': scopeId,
                'c2buId': c2BuId,
                'amount': amount,
            },
            errors: {
                400: `Invalid parameters passed`,
                500: `Internal server error`,
            },
        });
    }

}