/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddOrganizationRequestDTO } from '../models/AddOrganizationRequestDTO';
import type { AddOrganizationResponseDTO } from '../models/AddOrganizationResponseDTO';
import type { CreateCustomerGroupRequestDTO } from '../models/CreateCustomerGroupRequestDTO';
import type { CreateCustomerGroupResponseDTO } from '../models/CreateCustomerGroupResponseDTO';
import type { CreateCustomerGroupWithOrganizationRequest } from '../models/CreateCustomerGroupWithOrganizationRequest';
import type { CreateCustomerGroupWithOrganizationResponse } from '../models/CreateCustomerGroupWithOrganizationResponse';
import type { GetUsersResponseDTO } from '../models/GetUsersResponseDTO';
import type { SetCustomerGroupNameRequestDTO } from '../models/SetCustomerGroupNameRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerGroupControllerService {

    /**
     * @param groupId
     * @param requestBody
     * @returns AddOrganizationResponseDTO OK
     * @throws ApiError
     */
    public static addOrganization(
        groupId: number,
        requestBody: AddOrganizationRequestDTO,
    ): CancelablePromise<AddOrganizationResponseDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/customergroups/{groupId}/organizations',
            path: {
                'groupId': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param groupId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static setCustomerGroupName(
        groupId: number,
        requestBody: SetCustomerGroupNameRequestDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/customergroups/{groupId}/name',
            path: {
                'groupId': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns CreateCustomerGroupResponseDTO OK
     * @throws ApiError
     */
    public static createCustomerGroup(
        requestBody: CreateCustomerGroupRequestDTO,
    ): CancelablePromise<CreateCustomerGroupResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/customergroups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns CreateCustomerGroupWithOrganizationResponse OK
     * @throws ApiError
     */
    public static createCustomerGroupWithOrganization(
        requestBody: CreateCustomerGroupWithOrganizationRequest,
    ): CancelablePromise<CreateCustomerGroupWithOrganizationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/customergroups/createwithorganization',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param groupId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteCustomerGroup(
        groupId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/public/customergroups/{groupId}',
            path: {
                'groupId': groupId,
            },
        });
    }

    /**
     * @param groupId
     * @returns GetUsersResponseDTO OK
     * @throws ApiError
     */
    public static getUsers(
        groupId: number,
    ): CancelablePromise<GetUsersResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/customergroups/{groupId}/users',
            path: {
                'groupId': groupId,
            },
        });
    }

    /**
     * @param groupId
     * @param tscid
     * @returns any OK
     * @throws ApiError
     */
    public static deleteOrganization(
        groupId: number,
        tscid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/public/customergroups/{groupId}/organizations/{tscid}',
            path: {
                'groupId': groupId,
                'tscid': tscid,
            },
        });
    }

}