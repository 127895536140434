/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataComPermissions } from '../models/DataComPermissions';
import type { TscIdPermissionRequestBody } from '../models/TscIdPermissionRequestBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DataComOrderPermissionsControllerService {

    /**
     * Get DataCom order permissions for user
     * @param scopeId
     * @returns DataComPermissions Successful request
     * @throws ApiError
     */
    public static getDataComUserPermissions(
        scopeId: string,
    ): CancelablePromise<DataComPermissions> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/admin/datacom/{scopeId}',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal server error`,
                503: `DataCom service unavailable`,
            },
        });
    }

    /**
     * Get DataCom order permissions for user on tscId
     * @param scopeId
     * @param requestBody
     * @returns DataComPermissions Successful request
     * @throws ApiError
     */
    public static getDataComUserPermissionsOnTscId(
        scopeId: string,
        requestBody: TscIdPermissionRequestBody,
    ): CancelablePromise<DataComPermissions> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/admin/datacom/{scopeId}',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal server error`,
                503: `DataCom service unavailable`,
            },
        });
    }

}