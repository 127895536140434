/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Holidays } from '../models/Holidays';
import type { WorkDay } from '../models/WorkDay';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DatePickerControllerService {

    /**
     * Get workday from offset
     * used must be logged into MyB to access the service
     * @param scopeId
     * @param offset
     * @returns WorkDay Successful request
     * @throws ApiError
     */
    public static getNextEligibleWorkday(
        scopeId: string,
        offset: number = 1,
    ): CancelablePromise<WorkDay> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/datepicker/nextworkday',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'offset': offset,
            },
        });
    }

    /**
     * Get holidays
     * used must be logged into MyB to access the service
     * @param scopeId
     * @param year
     * @returns Holidays Successful request
     * @throws ApiError
     */
    public static holidays(
        scopeId: string,
        year?: number,
    ): CancelablePromise<Holidays> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/datepicker/holidays',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'year': year,
            },
        });
    }

}