/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddBillingAccountDTO } from '../models/AddBillingAccountDTO';
import type { AddBillingAccountRequestDTO } from '../models/AddBillingAccountRequestDTO';
import type { AvailableEmailsDTO } from '../models/AvailableEmailsDTO';
import type { ExportBillingAccountRequestDTO } from '../models/ExportBillingAccountRequestDTO';
import type { FunctionalAgreementResponse } from '../models/FunctionalAgreementResponse';
import type { GetBillingAccountDTO } from '../models/GetBillingAccountDTO';
import type { GetBillingAccountsForTscidDTO } from '../models/GetBillingAccountsForTscidDTO';
import type { GetMobileBillingAccountsForTscidDTO } from '../models/GetMobileBillingAccountsForTscidDTO';
import type { GetSourceDTO } from '../models/GetSourceDTO';
import type { MoveSubscriptionDTO } from '../models/MoveSubscriptionDTO';
import type { MoveSubscriptionsBRMRequestDTO } from '../models/MoveSubscriptionsBRMRequestDTO';
import type { MoveSubscriptionsBRMResponseDTO } from '../models/MoveSubscriptionsBRMResponseDTO';
import type { MoveSubscriptionsRequestDTO } from '../models/MoveSubscriptionsRequestDTO';
import type { MoveSubscriptionToNewBillingAccountRequestDTO } from '../models/MoveSubscriptionToNewBillingAccountRequestDTO';
import type { MoveSubscriptionToNewBrmBillingAccountRequestDTO } from '../models/MoveSubscriptionToNewBrmBillingAccountRequestDTO';
import type { SearchBillingAccountResponseDTO } from '../models/SearchBillingAccountResponseDTO';
import type { SubscriptionDTO } from '../models/SubscriptionDTO';
import type { UpdateBillingAccountDTO } from '../models/UpdateBillingAccountDTO';
import type { UpdateBillingAddressRequestDTO } from '../models/UpdateBillingAddressRequestDTO';
import type { UpdateDeliveryMethodRequestDTO } from '../models/UpdateDeliveryMethodRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BillingAccountsControllerService {

    /**
     * Update delivery method and details of a billing account
     * @param scopeId
     * @param tscid
     * @param billingAccountNumber
     * @param source
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static updateDeliveryMethod(
        scopeId: string,
        tscid: string,
        billingAccountNumber: string,
        source: string,
        requestBody: UpdateDeliveryMethodRequestDTO,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/{scopeId}/organizations/{tscid}/updatedeliverymethod/{billingAccountNumber}/{source}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'billingAccountNumber': billingAccountNumber,
                'source': source,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Move subscriptions to an existing billing account
     * @param scopeId
     * @param tscid
     * @param accountId
     * @param requestBody
     * @returns MoveSubscriptionDTO OK
     * @throws ApiError
     */
    public static moveSubscriptionsToExistingMobillBillingAccount(
        scopeId: string,
        tscid: string,
        accountId: string,
        requestBody: MoveSubscriptionsRequestDTO,
    ): CancelablePromise<MoveSubscriptionDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/{scopeId}/organizations/{tscid}/billingaccounts/{accountId}/subscriptions',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'accountId': accountId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Update billing address
     * @param scopeId
     * @param tscid
     * @param billingAccountNumber
     * @param source
     * @param requestBody
     * @returns UpdateBillingAccountDTO OK
     * @throws ApiError
     */
    public static updateBillingAddress(
        scopeId: string,
        tscid: string,
        billingAccountNumber: string,
        source: string,
        requestBody: UpdateBillingAddressRequestDTO,
    ): CancelablePromise<UpdateBillingAccountDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/{scopeId}/organizations/{tscid}/updatebillingaddress/{billingAccountNumber}/{source}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'billingAccountNumber': billingAccountNumber,
                'source': source,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Remove an email from the alpha pool for specified organization
     * @param scopeId
     * @param tscid
     * @param contactPointId
     * @returns UpdateBillingAccountDTO OK
     * @throws ApiError
     */
    public static removeEmail(
        scopeId: string,
        tscid: string,
        contactPointId: string,
    ): CancelablePromise<UpdateBillingAccountDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/{scopeId}/organizations/{tscid}/removeemail/{contactPointID}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'contactPointID': contactPointId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Add a new billing account. For development use only!
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns AddBillingAccountDTO OK
     * @throws ApiError
     */
    public static addBillingAccount(
        scopeId: string,
        tscid: string,
        requestBody: AddBillingAccountRequestDTO,
    ): CancelablePromise<AddBillingAccountDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/{scopeId}/organizations/{tscid}/billingaccounts',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Move subscriptions to a new billing account
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns MoveSubscriptionDTO OK
     * @throws ApiError
     */
    public static moveSubscriptionToNewBillingAccount(
        scopeId: string,
        tscid: string,
        requestBody: MoveSubscriptionToNewBillingAccountRequestDTO,
    ): CancelablePromise<MoveSubscriptionDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/{scopeId}/organizations/{tscid}/billingaccounts/subscriptions',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Move subscriptions to a new BRM billing account
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns MoveSubscriptionsBRMResponseDTO OK
     * @throws ApiError
     */
    public static moveSubscriptionToNewBrmBillingAccount(
        scopeId: string,
        tscid: string,
        requestBody: MoveSubscriptionToNewBrmBillingAccountRequestDTO,
    ): CancelablePromise<MoveSubscriptionsBRMResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/{scopeId}/organizations/{tscid}/billingaccounts/subscriptions/change',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Move subscriptions to an existing billing account
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns MoveSubscriptionsBRMResponseDTO OK
     * @throws ApiError
     */
    public static moveSubscriptionsToExistingBrmBillingAccount(
        scopeId: string,
        tscid: string,
        requestBody: MoveSubscriptionsBRMRequestDTO,
    ): CancelablePromise<MoveSubscriptionsBRMResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/{scopeId}/organizations/{tscid}/billingaccounts/change',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * @param scopeId
     * @param tscids
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static exportBillingAccounts(
        scopeId: string,
        tscids: Array<string>,
        requestBody: ExportBillingAccountRequestDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/{scopeId}/organizations/billingaccounts/export',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'tscids': tscids,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Get subscriptions for a billing account
     * @param scopeId
     * @param tscid
     * @param billingAccountNumber
     * @param source
     * @returns SubscriptionDTO OK
     * @throws ApiError
     */
    public static getSubscriptions(
        scopeId: string,
        tscid: string,
        billingAccountNumber: string,
        source: string,
    ): CancelablePromise<Array<SubscriptionDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/organizations/{tscid}/subscriptions/{billingAccountNumber}/{source}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'billingAccountNumber': billingAccountNumber,
                'source': source,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Get mobile billing accounts information
     * @param scopeId
     * @param tscid
     * @param billingAccountNumber
     * @returns GetMobileBillingAccountsForTscidDTO OK
     * @throws ApiError
     */
    public static getMobileBillingAccountsForTscid(
        scopeId: string,
        tscid: string,
        billingAccountNumber: string,
    ): CancelablePromise<Array<GetMobileBillingAccountsForTscidDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/organizations/{tscid}/mobilebillingaccounts/{billingAccountNumber}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'billingAccountNumber': billingAccountNumber,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Get an organisations verified email addresses
     * @param scopeId
     * @param tscid
     * @returns AvailableEmailsDTO OK
     * @throws ApiError
     */
    public static getAvailableEmails(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<Array<AvailableEmailsDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/organizations/{tscid}/getavailableemails',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Get billing account information
     * @param scopeId
     * @param tscid
     * @param billingAccountNumber
     * @param source
     * @returns GetBillingAccountDTO OK
     * @throws ApiError
     */
    public static getBillingAccount(
        scopeId: string,
        tscid: string,
        billingAccountNumber: string,
        source: string,
    ): CancelablePromise<GetBillingAccountDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/organizations/{tscid}/billingaccounts/{billingAccountNumber}/{source}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'billingAccountNumber': billingAccountNumber,
                'source': source,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Get source for billing account
     * @param scopeId
     * @param tscid
     * @param billingAccountNumber
     * @returns GetSourceDTO OK
     * @throws ApiError
     */
    public static getSource(
        scopeId: string,
        tscid: string,
        billingAccountNumber: string,
    ): CancelablePromise<GetSourceDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/organizations/{tscid}/billingaccounts/{billingAccountNumber}/source',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'billingAccountNumber': billingAccountNumber,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Get a billing account
     * @param scopeId
     * @param tscid
     * @param searchParam
     * @returns SearchBillingAccountResponseDTO OK
     * @throws ApiError
     */
    public static searchBillingAccount(
        scopeId: string,
        tscid: string,
        searchParam: string,
    ): CancelablePromise<Array<SearchBillingAccountResponseDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/organizations/{tscid}/billingaccounts/search/accountnumber',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'searchParam': searchParam,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Check if any of the given tscIds is connected to a functional agreement (using C2bCache as source)
     * @param scopeId
     * @param tscIds
     * @returns FunctionalAgreementResponse OK
     * @throws ApiError
     */
    public static checkForFunctionalAgreements(
        scopeId: string,
        tscIds: Array<string>,
    ): CancelablePromise<FunctionalAgreementResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/organizations/has-functional-agreements',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'tscIds': tscIds,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Get all billing accounts information
     * @param scopeId
     * @returns GetBillingAccountsForTscidDTO OK
     * @throws ApiError
     */
    public static getBillingAccountsForTscids(
        scopeId: string,
    ): CancelablePromise<Array<GetBillingAccountsForTscidDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/organizations/billingaccounts',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}