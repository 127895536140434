/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EnrichNewCustomerDTO } from '../models/EnrichNewCustomerDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EnrichNewCustomerControllerService {

    /**
     * Enrich new customer with party id
     * User must have PUI 'B_EXPRESS_CHECKOUT' to access this resource
     * @param tscid
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static enrich(
        tscid: string,
        requestBody: EnrichNewCustomerDTO,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal/party/enrich/{tscid}',
            path: {
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denied`,
                404: `Missing party id`,
                500: `Internal server error`,
            },
        });
    }

}