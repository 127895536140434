/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttributeTypePairUI } from '../models/AttributeTypePairUI';
import type { SubscriptionIdAccessUI } from '../models/SubscriptionIdAccessUI';
import type { XtasIdAccessUI } from '../models/XtasIdAccessUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalInstalledBaseDetailsControllerImplService {

    /**
     * @param scopeId
     * @param uId
     * @param types
     * @param allowEmptyValues
     * @returns AttributeTypePairUI OK
     * @throws ApiError
     */
    public static extractData(
        scopeId: string,
        uId: string,
        types: Array<'MSISDN' | 'SUBSCRIPTION_ID' | 'XTAS_ID' | 'ICC' | 'C2BCACHEUID' | 'VPNS' | 'TSCID' | 'BILLING_ACCOUNT_NUMBER' | 'ORG_NR' | 'MULTI_SUBSCRIPTION_NUMBER' | 'MOBILE_M_NUMBER' | 'AGREEMENT'>,
        allowEmptyValues: boolean = false,
    ): CancelablePromise<Array<AttributeTypePairUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/scope/{scopeId}/installedbase/{uId}/metadata',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
            query: {
                'types': types,
                'allowEmptyValues': allowEmptyValues,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * @param scopeId
     * @param xtasId
     * @returns XtasIdAccessUI OK
     * @throws ApiError
     */
    public static hasAccessToXtasId(
        scopeId: string,
        xtasId: string,
    ): CancelablePromise<XtasIdAccessUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/scope/{scopeId}/installedbase/functionsubscription/{xtasId}/hasAccess',
            path: {
                'scopeId': scopeId,
                'xtasId': xtasId,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * @param scopeId
     * @param subscriptionId
     * @returns SubscriptionIdAccessUI OK
     * @throws ApiError
     */
    public static hasAccessToSubscriptionId(
        scopeId: string,
        subscriptionId: string,
    ): CancelablePromise<SubscriptionIdAccessUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/scope/{scopeId}/installedbase/broadbandsubscription/{subscriptionId}/hasAccess',
            path: {
                'scopeId': scopeId,
                'subscriptionId': subscriptionId,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * @param serialCustomerId
     * @param uId
     * @param types
     * @returns AttributeTypePairUI OK
     * @throws ApiError
     */
    public static extractDataOld(
        serialCustomerId: string,
        uId: string,
        types: Array<'MSISDN' | 'SUBSCRIPTION_ID' | 'XTAS_ID' | 'ICC' | 'C2BCACHEUID' | 'VPNS' | 'TSCID' | 'BILLING_ACCOUNT_NUMBER' | 'ORG_NR' | 'MULTI_SUBSCRIPTION_NUMBER' | 'MOBILE_M_NUMBER' | 'AGREEMENT'>,
    ): CancelablePromise<Array<AttributeTypePairUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/customers/{serialCustomerId}/installedbase/{uId}/metadata',
            path: {
                'serialCustomerId': serialCustomerId,
                'uId': uId,
            },
            query: {
                'types': types,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

}