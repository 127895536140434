/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IndividualResponseDTO } from '../models/IndividualResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GetPartyControllerService {

    /**
     * Get party from PIM based on ID
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param partyId
     * @returns IndividualResponseDTO Returns partyId
     * @throws ApiError
     */
    public static getIndividualByPartyId(
        scopeId: string,
        partyId: string,
    ): CancelablePromise<IndividualResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/party/{partyId}',
            path: {
                'scopeId': scopeId,
                'partyId': partyId,
            },
        });
    }

}