/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgreementResultUI } from '../models/AgreementResultUI';
import type { OverviewResultUI } from '../models/OverviewResultUI';
import type { OverviewUI } from '../models/OverviewUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorporateInstalledBaseOverviewService {

    /**
     * Returns an overview of subscriptions
     * @param scopeId Customer identification number
     * @param selectedTscids
     * @param selectedAgreements
     * @returns OverviewResultUI OK
     * @throws ApiError
     */
    public static getOverviewV2(
        scopeId: string,
        selectedTscids?: Array<string>,
        selectedAgreements?: Array<string>,
    ): CancelablePromise<OverviewResultUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/overview',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'selectedTscids': selectedTscids,
                'selectedAgreements': selectedAgreements,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * @deprecated
     * Returns an overview of subscriptions
     * @param scopeId Customer identification number
     * @param selectedTscids
     * @param selectedAgreements
     * @returns OverviewResultUI Success
     * @throws ApiError
     */
    public static getOverviewV1(
        scopeId: string,
        selectedTscids?: Array<string>,
        selectedAgreements?: Array<string>,
    ): CancelablePromise<OverviewResultUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/overview-v1',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'selectedTscids': selectedTscids,
                'selectedAgreements': selectedAgreements,
            },
            errors: {
                403: `ACCESS_DENIED`,
                410: `Gone`,
            },
        });
    }

    /**
     * Returns an overview of subscriptions.
     * @param scopeId Customer identification number
     * @param preview
     * @returns OverviewUI Success
     * @throws ApiError
     */
    public static getSubscriptionPreview(
        scopeId: string,
        preview?: boolean,
    ): CancelablePromise<OverviewUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/installedbase/overview',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'preview': preview,
            },
            errors: {
                403: `ACCESS_DENIED`,
                410: `Gone`,
            },
        });
    }

    /**
     * Returns an overview of agreements.
     * @param scopeId Customer identification number
     * @param selectedTscids
     * @returns AgreementResultUI Success
     * @throws ApiError
     */
    public static getAgreementsV2(
        scopeId: string,
        selectedTscids?: Array<string>,
    ): CancelablePromise<Array<AgreementResultUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/agreements',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'selectedTscids': selectedTscids,
            },
            errors: {
                403: `ACCESS_DENIED`,
                410: `Gone`,
            },
        });
    }

    /**
     * @deprecated
     * Returns an overview of agreements.
     * @param scopeId Customer identification number
     * @param selectedTscids
     * @returns AgreementResultUI Success
     * @throws ApiError
     */
    public static getAgreementsV1(
        scopeId: string,
        selectedTscids?: Array<string>,
    ): CancelablePromise<Array<AgreementResultUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/agreements-v1',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'selectedTscids': selectedTscids,
            },
            errors: {
                403: `ACCESS_DENIED`,
                410: `Gone`,
            },
        });
    }

}