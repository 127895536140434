/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BroadbandListUI } from '../models/BroadbandListUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalProductListingControllerService {

    /**
     * @deprecated
     * Get internet access products
     * @param installationAddressPointId
     * @param lang
     * @returns BroadbandListUI Successful request
     * @throws ApiError
     */
    public static getBroadband2(
        installationAddressPointId: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<BroadbandListUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/broadband/installationAddress/{installationAddressPointId}',
            path: {
                'installationAddressPointId': installationAddressPointId,
            },
            query: {
                'lang': lang,
            },
        });
    }

}