/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PendingCancellationDTO } from '../models/PendingCancellationDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalCancellationControllerService {

    /**
     * Returns a pending cancellation for given c2bUid
     * User must have PUI 'VIEW_ENGAGEMENT' to access this resource
     * @param scopeId
     * @param c2BUid
     * @returns PendingCancellationDTO Success
     * @throws ApiError
     */
    public static getPendingCancellation(
        scopeId: string,
        c2BUid: string,
    ): CancelablePromise<PendingCancellationDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/scope/{scopeId}/cancellation/{c2bUid}/pending',
            path: {
                'scopeId': scopeId,
                'c2bUid': c2BUid,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}