/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountInformation } from '../models/AccountInformation';
import type { AddContactRequest } from '../models/AddContactRequest';
import type { Contact } from '../models/Contact';
import type { DeleteContactRequest } from '../models/DeleteContactRequest';
import type { UpdateContactRequest } from '../models/UpdateContactRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserAccountApiService {

    /**
     * Get all contact informations on user
     * @param scopeId
     * @returns Contact Success
     * @throws ApiError
     */
    public static getContacts(
        scopeId: string,
    ): CancelablePromise<Array<Contact>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/user/contacts',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Update a contact information on user
     * @param scopeId
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static updateContact(
        scopeId: string,
        requestBody: UpdateContactRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/scope/{scopeId}/user/contacts',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Add new contact information on user
     * @param scopeId
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static addContact(
        scopeId: string,
        requestBody: AddContactRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}/user/contacts',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Delete a contact information on user
     * @param scopeId
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static deleteContact(
        scopeId: string,
        requestBody: DeleteContactRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/scope/{scopeId}/user/contacts',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * @param scopeId
     * @returns AccountInformation OK
     * @throws ApiError
     */
    public static getAccountInformation(
        scopeId: string,
    ): CancelablePromise<AccountInformation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/user/account',
            path: {
                'scopeId': scopeId,
            },
        });
    }

}