/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePartyRequestDTO } from '../models/CreatePartyRequestDTO';
import type { CreatePartyResponseDTO } from '../models/CreatePartyResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CreatePartyControllerService {

    /**
     * Create party and return PIM id
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns CreatePartyResponseDTO Creates party and returns the id
     * @throws ApiError
     */
    public static createParty(
        scopeId: string,
        tscid: string,
        requestBody: CreatePartyRequestDTO,
    ): CancelablePromise<CreatePartyResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal/scope/{scopeId}/organizations/{tscid}/party',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}