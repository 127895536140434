/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddonServiceUI } from '../models/AddonServiceUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AddonsControllerImplService {

    /**
     * Returns all addon services.
     * @param scopeId Customer identification number
     * @param uId uId
     * @returns AddonServiceUI Success
     * @throws ApiError
     */
    public static getAddonServicesUsingGet(
        scopeId: string,
        uId: string,
    ): CancelablePromise<AddonServiceUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscription/{uId}/addonservices',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}