/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptInviteDTO } from '../models/AcceptInviteDTO';
import type { AcceptResponse } from '../models/AcceptResponse';
import type { ContactInfoDTO } from '../models/ContactInfoDTO';
import type { GetInviteHeaderResponseDTO } from '../models/GetInviteHeaderResponseDTO';
import type { NonceAndSmsTokenDTO } from '../models/NonceAndSmsTokenDTO';
import type { NonceDTO } from '../models/NonceDTO';
import type { ValidationResponseDTO } from '../models/ValidationResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NoScopeInviteControllerService {

    /**
     * @param inviteId Invite id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static requestSmsToken(
        inviteId: number,
        requestBody: NonceDTO,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/invites/{inviteId}/smstoken',
            path: {
                'inviteId': inviteId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param inviteId Invite id
     * @param requestBody
     * @returns ValidationResponseDTO Invite details and validation code
     * @throws ApiError
     */
    public static validateSmsToken(
        inviteId: number,
        requestBody: NonceAndSmsTokenDTO,
    ): CancelablePromise<ValidationResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/invites/{inviteId}/smstoken/validate',
            path: {
                'inviteId': inviteId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Accept invite
     * @param inviteId Invite id
     * @param requestBody
     * @returns AcceptResponse OK
     * @throws ApiError
     */
    public static acceptInvite(
        inviteId: number,
        requestBody: AcceptInviteDTO,
    ): CancelablePromise<AcceptResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/invites/{inviteId}/accept',
            path: {
                'inviteId': inviteId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param inviteId Invite id
     * @param nonce
     * @returns GetInviteHeaderResponseDTO Invite header information
     * @throws ApiError
     */
    public static getInviteHeader(
        inviteId: number,
        nonce: string,
    ): CancelablePromise<GetInviteHeaderResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/invites/{inviteId}/header',
            path: {
                'inviteId': inviteId,
            },
            query: {
                'nonce': nonce,
            },
        });
    }

    /**
     * Read recipient contact info
     * @param inviteId Invite id
     * @returns ContactInfoDTO OK
     * @throws ApiError
     */
    public static getRecipientContactInfo(
        inviteId: number,
    ): CancelablePromise<ContactInfoDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/invites/{inviteId}/contact-info',
            path: {
                'inviteId': inviteId,
            },
        });
    }

}