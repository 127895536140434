/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubscriptionUI } from '../models/SubscriptionUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InstalledBaseDetailsControllerService {

    /**
     * @param scopeId
     * @param uId
     * @returns SubscriptionUI OK
     * @throws ApiError
     */
    public static getSubscription(
        scopeId: string,
        uId: string,
    ): CancelablePromise<SubscriptionUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/installedbase/details/{uId}',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

    /**
     * @deprecated
     * @param serialCustomerId
     * @param uId
     * @returns SubscriptionUI OK
     * @throws ApiError
     */
    public static getSubscriptionOld(
        serialCustomerId: string,
        uId: string,
    ): CancelablePromise<SubscriptionUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{serialCustomerId}/installedbase/details/{uId}',
            path: {
                'serialCustomerId': serialCustomerId,
                'uId': uId,
            },
            errors: {
                410: `Gone`,
            },
        });
    }

}