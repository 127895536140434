/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveSwitchboardResultDTO } from '../models/ActiveSwitchboardResultDTO';
import type { SwitchboardDetailsResultDTO } from '../models/SwitchboardDetailsResultDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PrivateSwitchboardsControllerService {

    /**
     * @deprecated
     * @param scopeId scopeId
     * @param tscid
     * @returns ActiveSwitchboardResultDTO OK
     * @throws ApiError
     */
    public static getSingleActiveTouchpoint1(
        scopeId: any,
        tscid: string,
    ): CancelablePromise<ActiveSwitchboardResultDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/scope/{scopeId}/switchboards/touchpoint/{tscid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

    /**
     * @deprecated
     * @param scopeId
     * @param c2Buid
     * @returns SwitchboardDetailsResultDTO OK
     * @throws ApiError
     */
    public static getSwitchboardDetails(
        scopeId: string,
        c2Buid: string,
    ): CancelablePromise<SwitchboardDetailsResultDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/scope/{scopeId}/switchboards/details/{c2buid}',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
        });
    }

}