/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeAddonSettingsRequestUI } from '../models/ChangeAddonSettingsRequestUI';
import type { ChangeSubscriptionDetailsRequestUI } from '../models/ChangeSubscriptionDetailsRequestUI';
import type { CheckoutPortinUI } from '../models/CheckoutPortinUI';
import type { CheckoutSwitchboardRequestUI } from '../models/CheckoutSwitchboardRequestUI';
import type { ExtraUnitDataUI } from '../models/ExtraUnitDataUI';
import type { MultiCheckoutResponseUI } from '../models/MultiCheckoutResponseUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileCheckoutControllerService {

    /**
     * Checkouts one or more changes to customer mobile service towards Amanda
     * User must have 'PRODUCT_ADMINISTRATION' to access this resource
     * @param c2BUId c2bUId
     * @param fastCheckoutRequest fastCheckoutRequest
     * @param scopeId scopeId
     * @param lang lang
     * @returns MultiCheckoutResponseUI OK
     * @throws ApiError
     */
    public static changeAddonsUsingPost(
        c2BUId: string,
        fastCheckoutRequest: ChangeAddonSettingsRequestUI,
        scopeId: string,
        lang: 'EN' | 'SV' = 'SV',
    ): CancelablePromise<MultiCheckoutResponseUI> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}/subscriptions/{c2bUId}/changeaddons',
            path: {
                'c2bUId': c2BUId,
                'scopeId': scopeId,
            },
            query: {
                'lang': lang,
            },
            body: fastCheckoutRequest,
        });
    }

    /**
     * Checkouts one or more changes to customer mobile service towards Amanda
     * User must have 'PRODUCT_ADMINISTRATION' to access this resource
     * @param c2BUId c2bUId
     * @param scopeId scopeId
     * @param subscriptionDetailsRequest subscriptionDetailsRequest
     * @param lang lang
     * @returns MultiCheckoutResponseUI OK
     * @throws ApiError
     */
    public static changeSubscriptionDetailsUsingPatch(
        c2BUId: string,
        scopeId: string,
        subscriptionDetailsRequest: ChangeSubscriptionDetailsRequestUI,
        lang: 'EN' | 'SV' = 'SV',
    ): CancelablePromise<MultiCheckoutResponseUI> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/scope/{scopeId}/subscriptions/{c2bUId}/changesubscriptiondetails',
            path: {
                'c2bUId': c2BUId,
                'scopeId': scopeId,
            },
            query: {
                'lang': lang,
            },
            body: subscriptionDetailsRequest,
        });
    }

    /**
     * checkoutPortin
     * @param c2BUId c2bUId
     * @param requestUi requestUI
     * @param scopeId scopeId
     * @param lang lang
     * @returns MultiCheckoutResponseUI OK
     * @throws ApiError
     */
    public static checkoutPortinUsingPost(
        c2BUId: string,
        requestUi: CheckoutPortinUI,
        scopeId: string,
        lang: 'EN' | 'SV' = 'SV',
    ): CancelablePromise<MultiCheckoutResponseUI> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}/subscriptions/{c2bUId}/checkoutportin',
            path: {
                'c2bUId': c2BUId,
                'scopeId': scopeId,
            },
            query: {
                'lang': lang,
            },
            body: requestUi,
        });
    }

    /**
     * checkoutExtraUnits
     * @param c2BUId c2bUId
     * @param extraUnitsData extraUnitsData
     * @param scopeId scopeId
     * @param lang lang
     * @returns MultiCheckoutResponseUI OK
     * @throws ApiError
     */
    public static checkoutExtraUnitsUsingPost(
        c2BUId: string,
        extraUnitsData: Array<ExtraUnitDataUI>,
        scopeId: string,
        lang: 'EN' | 'SV' = 'SV',
    ): CancelablePromise<MultiCheckoutResponseUI> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}/subscriptions/{c2bUId}/checkoutextraunits',
            path: {
                'c2bUId': c2BUId,
                'scopeId': scopeId,
            },
            query: {
                'lang': lang,
            },
            body: extraUnitsData,
        });
    }

    /**
     * checkoutTouchpoint
     * @param c2BUId c2bUId
     * @param requestUi requestUI
     * @param scopeId scopeId
     * @param lang lang
     * @returns MultiCheckoutResponseUI OK
     * @throws ApiError
     */
    public static checkoutTouchpointUsingPost(
        c2BUId: string,
        requestUi: CheckoutSwitchboardRequestUI,
        scopeId: string,
        lang: 'EN' | 'SV' = 'SV',
    ): CancelablePromise<MultiCheckoutResponseUI> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}/subscriptions/{c2bUId}/checkouttouchpoint',
            path: {
                'c2bUId': c2BUId,
                'scopeId': scopeId,
            },
            query: {
                'lang': lang,
            },
            body: requestUi,
        });
    }

    /**
     * Cancels the main mobile subscription via Amanda. If datasims are tied to the subscriptions, these also are cancelled unless they have binding time left.
     * @param c2BUId c2bUId
     * @param scopeId scopeId
     * @param lang lang
     * @param subscriptionC2BUIds subscriptionC2bUIds
     * @returns MultiCheckoutResponseUI Subscription successfully cancelled.
     * @throws ApiError
     */
    public static cancelSubscriptionUsingDelete(
        c2BUId: string,
        scopeId: string,
        lang: 'EN' | 'SV' = 'SV',
        subscriptionC2BUIds?: Array<string>,
    ): CancelablePromise<MultiCheckoutResponseUI> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/scope/{scopeId}/subscriptions/{c2bUId}/cancelsubscription',
            path: {
                'c2bUId': c2BUId,
                'scopeId': scopeId,
            },
            query: {
                'lang': lang,
            },
            body: subscriptionC2BUIds,
        });
    }

    /**
     * checkoutSwitchboard
     * @param c2BUId c2bUId
     * @param requestUi requestUI
     * @param scopeId scopeId
     * @param lang lang
     * @returns MultiCheckoutResponseUI OK
     * @throws ApiError
     */
    public static checkoutSwitchboardUsingPost(
        c2BUId: string,
        requestUi: CheckoutSwitchboardRequestUI,
        scopeId: string,
        lang: 'EN' | 'SV' = 'SV',
    ): CancelablePromise<MultiCheckoutResponseUI> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}/subscriptions/{c2bUId}/checkoutswitchboard',
            path: {
                'c2bUId': c2BUId,
                'scopeId': scopeId,
            },
            query: {
                'lang': lang,
            },
            body: requestUi,
        });
    }

}