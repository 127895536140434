/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetUserResponse } from '../models/GetUserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserControllerService {

    /**
     * @returns GetUserResponse OK
     * @throws ApiError
     */
    public static getUser(): CancelablePromise<GetUserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/user',
        });
    }

}