/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FinishConfigurationRequestDTO } from '../models/FinishConfigurationRequestDTO';
import type { FinishConfigurationResponseDTO } from '../models/FinishConfigurationResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FinishDetachedConfigurationControllerService {

    /**
     * @param scopeId
     * @param tscid
     * @param configurationId
     * @param requestBody
     * @param lang
     * @returns FinishConfigurationResponseDTO OK
     * @throws ApiError
     */
    public static finishDetachedConfiguration(
        scopeId: string,
        tscid: string,
        configurationId: string,
        requestBody: FinishConfigurationRequestDTO,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<FinishConfigurationResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/organizations/{tscid}/detached/configuration/{configurationId}/finish',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'configurationId': configurationId,
            },
            query: {
                'lang': lang,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}