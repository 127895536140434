/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderEsimRequest } from '../models/OrderEsimRequest';
import type { OrderSimcardRequest } from '../models/OrderSimcardRequest';
import type { PinPukUI } from '../models/PinPukUI';
import type { QRCodeLinkUI } from '../models/QRCodeLinkUI';
import type { SendQRCodeForNewSubscriptionRequest } from '../models/SendQRCodeForNewSubscriptionRequest';
import type { SendQRCodeRequest } from '../models/SendQRCodeRequest';
import type { SetSimcardStatusRequest } from '../models/SetSimcardStatusRequest';
import type { SimcardStatusUI } from '../models/SimcardStatusUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileSimcardsControllerService {

    /**
     * sendQRCodeEmailForNewSubscription
     * @param request request
     * @param scopeId scopeId
     * @returns any OK
     * @throws ApiError
     */
    public static sendQrCodeEmailForNewSubscriptionUsingPost(
        request: SendQRCodeForNewSubscriptionRequest,
        scopeId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-mobile-simcards/customers/{scopeId}/mobile-simcards/newsubscription/qrcode',
            path: {
                'scopeId': scopeId,
            },
            body: request,
        });
    }

    /**
     * Orders new simcard for given c2buid.
     * @param c2Buid c2buid
     * @param request request
     * @param scopeId scopeId
     * @returns void
     * @throws ApiError
     */
    public static orderSimcardUsingPost(
        c2Buid: string,
        request: OrderSimcardRequest,
        scopeId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-mobile-simcards/customers/{scopeId}/mobile-simcards/{c2buid}',
            path: {
                'c2buid': c2Buid,
                'scopeId': scopeId,
            },
            body: request,
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Orders new E-SIM for given c2buid. If email is provided a QR-code is sent, if email is empty or null a link to the QR-code is returned
     * @param c2Buid c2buid
     * @param request request
     * @param scopeId scopeId
     * @returns QRCodeLinkUI Success, link to QR-code returned
     * @throws ApiError
     */
    public static orderEsimUsingPost(
        c2Buid: string,
        request: OrderEsimRequest,
        scopeId: string,
    ): CancelablePromise<QRCodeLinkUI> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-mobile-simcards/customers/{scopeId}/mobile-simcards/{c2buid}/esim',
            path: {
                'c2buid': c2Buid,
                'scopeId': scopeId,
            },
            body: request,
            errors: {
                403: `ACCESS_DENIED`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Returns Pin and Puk for given c2buid.
     * @param c2Buid c2buid
     * @param scopeId scopeId
     * @returns PinPukUI Success
     * @throws ApiError
     */
    public static getPukBasedOnC2BCacheUsingGet(
        c2Buid: string,
        scopeId: string,
    ): CancelablePromise<PinPukUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-mobile-simcards/customers/{scopeId}/mobile-simcards/{c2buid}/puk',
            path: {
                'c2buid': c2Buid,
                'scopeId': scopeId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Get a link to a QR-code for ESIM
     * @param c2Buid c2buid
     * @param scopeId scopeId
     * @returns QRCodeLinkUI Success
     * @throws ApiError
     */
    public static getQrCodeLinkUsingGet(
        c2Buid: string,
        scopeId: string,
    ): CancelablePromise<QRCodeLinkUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-mobile-simcards/customers/{scopeId}/mobile-simcards/{c2buid}/qrcode',
            path: {
                'c2buid': c2Buid,
                'scopeId': scopeId,
            },
            errors: {
                403: `ACCESS_DENIED`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * sendQRCodeEmail
     * @param c2Buid c2buid
     * @param request request
     * @param scopeId scopeId
     * @returns any OK
     * @throws ApiError
     */
    public static sendQrCodeEmailUsingPost(
        c2Buid: string,
        request: SendQRCodeRequest,
        scopeId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-mobile-simcards/customers/{scopeId}/mobile-simcards/{c2buid}/qrcode',
            path: {
                'c2buid': c2Buid,
                'scopeId': scopeId,
            },
            body: request,
        });
    }

    /**
     * Returns simcard traffic status for given c2buid.
     * @param c2Buid c2buid
     * @param scopeId scopeId
     * @returns SimcardStatusUI Success
     * @throws ApiError
     */
    public static getStatusBasedOnC2BCacheUsingGet(
        c2Buid: string,
        scopeId: string,
    ): CancelablePromise<SimcardStatusUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-mobile-simcards/customers/{scopeId}/mobile-simcards/{c2buid}/status',
            path: {
                'c2buid': c2Buid,
                'scopeId': scopeId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Change the simcard status connected to given c2buid. Use status BLOCKED_THEFT or OPEN.
     * @param c2Buid c2buid
     * @param request request
     * @param scopeId scopeId
     * @returns void
     * @throws ApiError
     */
    public static changeStatusUsingPut(
        c2Buid: string,
        request: SetSimcardStatusRequest,
        scopeId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/corp-mobile-simcards/customers/{scopeId}/mobile-simcards/{c2buid}/status',
            path: {
                'c2buid': c2Buid,
                'scopeId': scopeId,
            },
            body: request,
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}