/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetOrganisationResponse } from '../models/GetOrganisationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GetOrganisationControllerService {

    /**
     * Get the tscid from the organisation number
     * User must have PUI 'B_EXPRESS_CHECKOUT' to access this resource
     * @param orgNo
     * @returns GetOrganisationResponse OK
     * @throws ApiError
     */
    public static getOrganisation(
        orgNo: string,
    ): CancelablePromise<GetOrganisationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/organisation/{orgNo}',
            path: {
                'orgNo': orgNo,
            },
        });
    }

}