/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubscriptionInformationUI } from '../models/SubscriptionInformationUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileSubscriptionInfoControllerService {

    /**
     * Gets subscription information for subscription identified by c2buid
     * User must have 'PRODUCT_DISPLAY' to access this resource
     * @param c2Buid c2buid
     * @param scopeId scopeId
     * @returns SubscriptionInformationUI Successful request
     * @throws ApiError
     */
    public static getSubscriptionInfo(
        c2Buid: string,
        scopeId: string,
    ): CancelablePromise<SubscriptionInformationUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscriptions/{c2buid}/info',
            path: {
                'c2buid': c2Buid,
                'scopeId': scopeId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}