/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookedReservedNumberDTO } from '../models/BookedReservedNumberDTO';
import type { BookNumberDTO } from '../models/BookNumberDTO';
import type { ReservedNumberDTO } from '../models/ReservedNumberDTO';
import type { ReservedNumberStatusDTO } from '../models/ReservedNumberStatusDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublicNumberBookingControllerService {

    /**
     * Extend the booking for a bookingId, only supports VOICE
     * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
     * @param scopeId
     * @param tscid
     * @param bookingId
     * @returns void
     * @throws ApiError
     */
    public static extendBooking(
        scopeId: string,
        tscid: string,
        bookingId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/corp-number-booking/public/scope/{scopeId}/organizations/{tscid}/extend-booking/{bookingId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'bookingId': bookingId,
            },
            errors: {
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * release booked reserved number
     * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
     * @param scopeId
     * @param tscid
     * @param msisdn
     * @param customerNumber
     * @returns void
     * @throws ApiError
     */
    public static releaseReservedNumber(
        scopeId: string,
        tscid: string,
        msisdn: string,
        customerNumber?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-number-booking/public/scope/{scopeId}/organizations/{tscid}/release-reserved-number/{msisdn}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'msisdn': msisdn,
            },
            query: {
                'customerNumber': customerNumber,
            },
            errors: {
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Release a booking, only supports VOICE
     * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
     * @param scopeId
     * @param tscid
     * @param bookingId
     * @returns void
     * @throws ApiError
     */
    public static releaseBooking(
        scopeId: string,
        tscid: string,
        bookingId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-number-booking/public/scope/{scopeId}/organizations/{tscid}/release-booking/{bookingId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'bookingId': bookingId,
            },
            errors: {
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Release a booking and book new numbers, only supports VOICE
     * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
     * @param scopeId
     * @param tscid
     * @param bookingId
     * @param msisdn
     * @returns BookNumberDTO Numbers was successfully released and booked
     * @throws ApiError
     */
    public static releaseAndBook(
        scopeId: string,
        tscid: string,
        bookingId: string,
        msisdn?: string,
    ): CancelablePromise<BookNumberDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-number-booking/public/scope/{scopeId}/organizations/{tscid}/release-and-book/{bookingId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'bookingId': bookingId,
            },
            query: {
                'msisdn': msisdn,
            },
            errors: {
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Finalize the booking of a number, only supports VOICE
     * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
     * @param scopeId
     * @param tscid
     * @param bookingId
     * @param msisdn
     * @returns void
     * @throws ApiError
     */
    public static finalizeBooking(
        scopeId: string,
        tscid: string,
        bookingId: string,
        msisdn: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-number-booking/public/scope/{scopeId}/organizations/{tscid}/finalize-booking/{bookingId}/{msisdn}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'bookingId': bookingId,
                'msisdn': msisdn,
            },
            errors: {
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Book reserved numbers
     * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
     * @param scopeId
     * @param tscid
     * @param msisdn
     * @param bookingId
     * @param customerNumber
     * @returns BookedReservedNumberDTO Number was successfully booked
     * @throws ApiError
     */
    public static bookReservedNumber(
        scopeId: string,
        tscid: string,
        msisdn: string,
        bookingId?: string,
        customerNumber?: string,
    ): CancelablePromise<BookedReservedNumberDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/corp-number-booking/public/scope/{scopeId}/organizations/{tscid}/book-reserved-number/{msisdn}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'msisdn': msisdn,
            },
            query: {
                'bookingId': bookingId,
                'customerNumber': customerNumber,
            },
            errors: {
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get status of reserved number
     * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
     * @param scopeId
     * @param tscid
     * @param msisdn
     * @returns ReservedNumberStatusDTO Status was successfully retrieved
     * @throws ApiError
     */
    public static reservedNumberStatus(
        scopeId: string,
        tscid: string,
        msisdn: string,
    ): CancelablePromise<ReservedNumberStatusDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-number-booking/public/scope/{scopeId}/organizations/{tscid}/reserved-number-status/{msisdn}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'msisdn': msisdn,
            },
            errors: {
                403: `Access denied`,
                404: `Number not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get reserved numbers
     * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
     * @param scopeId
     * @param tscid
     * @param customerNumber
     * @param maxCount
     * @param customMsisdn
     * @returns ReservedNumberDTO Numbers was successfully fetched
     * @throws ApiError
     */
    public static getReservedNumbersForTscid(
        scopeId: string,
        tscid: string,
        customerNumber: string,
        maxCount: number = 1000000,
        customMsisdn?: string,
    ): CancelablePromise<Array<ReservedNumberDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-number-booking/public/scope/{scopeId}/organizations/{tscid}/get-reserved-numbers',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'customerNumber': customerNumber,
                'maxCount': maxCount,
                'customMsisdn': customMsisdn,
            },
            errors: {
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Book numbers
     * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
     * @param scopeId
     * @param tscid
     * @param type
     * @param msisdn
     * @returns BookNumberDTO Numbers was successfully booked
     * @throws ApiError
     */
    public static bookNumber1(
        scopeId: string,
        tscid: string,
        type: 'VOICE' | 'MBB' = 'VOICE',
        msisdn?: string,
    ): CancelablePromise<BookNumberDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-number-booking/public/scope/{scopeId}/organizations/{tscid}/book-number',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'type': type,
                'msisdn': msisdn,
            },
            errors: {
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

}