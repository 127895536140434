/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BankIdUserInfoDTO } from '../models/BankIdUserInfoDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BankIdUserControllerService {

    /**
     * Returns user's details from the token.
     * @returns BankIdUserInfoDTO OK
     * @throws ApiError
     */
    public static getUserFromToken(): CancelablePromise<BankIdUserInfoDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/users/bankid/self',
        });
    }

}