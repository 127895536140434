/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Capability } from '../models/Capability';
import type { CapabilityResponse } from '../models/CapabilityResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorpCapabilitiesControllerService {

    /**
     * @param scopeId
     * @param uId
     * @returns CapabilityResponse OK
     * @throws ApiError
     */
    public static getCapabilitiesV2(
        scopeId: string,
        uId: string,
    ): CancelablePromise<CapabilityResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/{uId}/v2/capabilities',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
        });
    }

    /**
     * @param scopeId
     * @param uId
     * @returns Capability OK
     * @throws ApiError
     */
    public static getCapabilities(
        scopeId: string,
        uId: string,
    ): CancelablePromise<Array<Capability>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/{uId}/capabilities',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
        });
    }

}