/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataNetAccessV1 } from '../models/DataNetAccessV1';
import type { OrderCaptureSummary } from '../models/OrderCaptureSummary';
import type { PausedOrderRow } from '../models/PausedOrderRow';
import type { TeliaNowTicketRequest } from '../models/TeliaNowTicketRequest';
import type { TeliaNowTicketResponse } from '../models/TeliaNowTicketResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExternalDatanetControllerService {

    /**
     * Update saved new DataNet access order capture
     * @param scopeId
     * @param orderCaptureId
     * @param requestBody
     * @returns DataNetAccessV1 Successful request
     * @throws ApiError
     */
    public static updateNewOrderCapturePreSave(
        scopeId: string,
        orderCaptureId: string,
        requestBody: DataNetAccessV1,
    ): CancelablePromise<DataNetAccessV1> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/external/{scopeId}/access/new/{orderCaptureId}',
            path: {
                'scopeId': scopeId,
                'orderCaptureId': orderCaptureId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update and finalize new DataNet access order capture
     * @param scopeId
     * @param orderCaptureId
     * @param requestBody
     * @returns DataNetAccessV1 Successful request
     * @throws ApiError
     */
    public static updateNewOrderCaptureFinalize(
        scopeId: string,
        orderCaptureId: string,
        requestBody: DataNetAccessV1,
    ): CancelablePromise<DataNetAccessV1> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/external/{scopeId}/access/new/finalize/{orderCaptureId}',
            path: {
                'scopeId': scopeId,
                'orderCaptureId': orderCaptureId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update saved change DataNet access order capture
     * @param scopeId
     * @param orderCaptureId
     * @param requestBody
     * @returns DataNetAccessV1 Successful request
     * @throws ApiError
     */
    public static updateChangeOrderCapturePreSave(
        scopeId: string,
        orderCaptureId: string,
        requestBody: DataNetAccessV1,
    ): CancelablePromise<DataNetAccessV1> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/external/{scopeId}/access/change/{orderCaptureId}',
            path: {
                'scopeId': scopeId,
                'orderCaptureId': orderCaptureId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update and finalize change DataNet access order capture
     * @param scopeId
     * @param orderCaptureId
     * @param requestBody
     * @returns DataNetAccessV1 Successful request
     * @throws ApiError
     */
    public static updateChangeOrderCaptureFinalize(
        scopeId: string,
        orderCaptureId: string,
        requestBody: DataNetAccessV1,
    ): CancelablePromise<DataNetAccessV1> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/external/{scopeId}/access/change/finalize/{orderCaptureId}',
            path: {
                'scopeId': scopeId,
                'orderCaptureId': orderCaptureId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create a ticket to Telia Now
     * @param scopeId
     * @param requestBody
     * @returns TeliaNowTicketResponse Successful request
     * @throws ApiError
     */
    public static createTeliaNowTicket(
        scopeId: string,
        requestBody: TeliaNowTicketRequest,
    ): CancelablePromise<TeliaNowTicketResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/external/{scopeId}/telianow-ticket',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Save DataNet access order capture
     * @param scopeId
     * @param requestBody
     * @returns DataNetAccessV1 Successful request
     * @throws ApiError
     */
    public static addOrderCapturePreSave(
        scopeId: string,
        requestBody: DataNetAccessV1,
    ): CancelablePromise<DataNetAccessV1> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/external/{scopeId}/access',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Finalize DataNet access order capture
     * @param scopeId
     * @param requestBody
     * @returns DataNetAccessV1 Successful request
     * @throws ApiError
     */
    public static addOrderCaptureFinalize(
        scopeId: string,
        requestBody: DataNetAccessV1,
    ): CancelablePromise<DataNetAccessV1> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/external/{scopeId}/access/finalize',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get list of DataNet access paused orders
     * @param scopeId
     * @returns PausedOrderRow Successful request
     * @throws ApiError
     */
    public static getPausedOrders(
        scopeId: string,
    ): CancelablePromise<Array<PausedOrderRow>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/external/{scopeId}/paused-orders',
            path: {
                'scopeId': scopeId,
            },
        });
    }

    /**
     * Get DataNet access order capture
     * @param scopeId
     * @param type
     * @param id
     * @returns DataNetAccessV1 Successful request
     * @throws ApiError
     */
    public static getOrderCapture(
        scopeId: string,
        type: 'ORDER_CAPTURE_ID' | 'C2BUID' | 'XTAS_ID',
        id: string,
    ): CancelablePromise<DataNetAccessV1> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/external/{scopeId}/access/{type}/{id}',
            path: {
                'scopeId': scopeId,
                'type': type,
                'id': id,
            },
        });
    }

    /**
     * Delete DataNet access order capture
     * @param scopeId
     * @param orderCaptureId
     * @returns OrderCaptureSummary Successful request
     * @throws ApiError
     */
    public static deleteOrderCapture1(
        scopeId: string,
        orderCaptureId: string,
    ): CancelablePromise<OrderCaptureSummary> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/external/{scopeId}/access/{orderCaptureId}',
            path: {
                'scopeId': scopeId,
                'orderCaptureId': orderCaptureId,
            },
        });
    }

}