/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FixedNetSimpleResponse } from '../models/FixedNetSimpleResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiMarketFixedNetControllerService {

    /**
     * Check fixed net conditions on address
     * @param streetName
     * @param city
     * @param streetNumber
     * @param entrance
     * @param latitude
     * @param longitude
     * @returns FixedNetSimpleResponse Successful request
     * @throws ApiError
     */
    public static getSimpleFixedNet(
        streetName?: string,
        city?: string,
        streetNumber?: string,
        entrance?: string,
        latitude?: string,
        longitude?: string,
    ): CancelablePromise<FixedNetSimpleResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/fixednet',
            query: {
                'streetName': streetName,
                'city': city,
                'streetNumber': streetNumber,
                'entrance': entrance,
                'latitude': latitude,
                'longitude': longitude,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal server error`,
                503: `Subsystem unavailable`,
            },
        });
    }

}