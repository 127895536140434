/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RemindRequest } from '../models/RemindRequest';
import type { SetNewPassword } from '../models/SetNewPassword';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorpUserAccountEamControllerService {

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static setNewPassword(
        requestBody: SetNewPassword,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create new user account
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public static createUser(
        requestBody: User,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static reminder(
        requestBody: RemindRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/reminder',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    // Removed passwordRecovery. It is not used and does not work anymore.
    // This was just a random change just to ensure a new version is published.
    // Due to previous issue, updates were not published
    // That issue is fixed in the same PR as this code change.
    // The entire corp-user-account-eam service is planned for removal within a few days. 
}