/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetMobileAgreementsRequestDto } from '../models/GetMobileAgreementsRequestDto';
import type { GetMobileAgreementsResponseDto } from '../models/GetMobileAgreementsResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileAgreementsControllerService {

    /**
     * Get mobile agreement information
     * User must have PUI: 'READ_AGREEMENT'
     * @param scopeId
     * @param requestBody
     * @returns GetMobileAgreementsResponseDto Successful request
     * @throws ApiError
     */
    public static getMobileAgreementsNewAuth(
        scopeId: string,
        requestBody: GetMobileAgreementsRequestDto,
    ): CancelablePromise<GetMobileAgreementsResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/mobileagreements',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get mobile IOT agreement information
     * User must have PUI: 'READ_AGREEMENT'
     * @param scopeId
     * @returns GetMobileAgreementsResponseDto Successful request
     * @throws ApiError
     */
    public static getMobileIotAgreements(
        scopeId: string,
    ): CancelablePromise<GetMobileAgreementsResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/mobileagreements/iot',
            path: {
                'scopeId': scopeId,
            },
        });
    }

}