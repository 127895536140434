/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalMobileAddonsControllerService {

    /**
     * Gets addon details for all addons connected to subscription identified by c2buid. User must have 'MYBUSINESS_ACCESS' to access this resource
     * @param scopeId
     * @param c2Buid
     * @param tscId
     * @param agreementNumber
     * @returns boolean Successful request
     * @throws ApiError
     */
    public static agreementCheck(
        scopeId: string,
        c2Buid: string,
        tscId: string,
        agreementNumber: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/scope/{scopeId}/subscriptions/{c2buid}/organizations/{tscId}/agreements/{agreementNumber}/subscriptionInAgreement',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
                'tscId': tscId,
                'agreementNumber': agreementNumber,
            },
        });
    }

}