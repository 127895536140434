/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetAddressFromPointIdResponseDTO } from '../models/GetAddressFromPointIdResponseDTO';
import type { GetSubAddressDTO } from '../models/GetSubAddressDTO';
import type { SearchResponseDTO } from '../models/SearchResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OpenAddressLookupControllerService {

    /**
     * Get subaddresses from pointId
     * @param pointId
     * @returns GetSubAddressDTO OK
     * @throws ApiError
     */
    public static getSubAddressesFromPointId2(
        pointId: string,
    ): CancelablePromise<GetSubAddressDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/open/subaddresses/{pointId}',
            path: {
                'pointId': pointId,
            },
        });
    }

    /**
     * search for an address
     * @param searchValue
     * @param bestHitsOnly
     * @param maxResults
     * @returns SearchResponseDTO OK
     * @throws ApiError
     */
    public static searchAddress2(
        searchValue: string,
        bestHitsOnly?: boolean,
        maxResults?: number,
    ): CancelablePromise<Array<SearchResponseDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/open/search',
            query: {
                'searchValue': searchValue,
                'bestHitsOnly': bestHitsOnly,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * Get address from pointId
     * @param pointId
     * @returns GetAddressFromPointIdResponseDTO OK
     * @throws ApiError
     */
    public static getAddressFromPointId1(
        pointId: string,
    ): CancelablePromise<GetAddressFromPointIdResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/open/address/{pointId}',
            path: {
                'pointId': pointId,
            },
        });
    }

}