/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataNetSharedOrdersDto } from '../models/DataNetSharedOrdersDto';
import type { SharedOrdersSummary } from '../models/SharedOrdersSummary';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExternalSharedOrdersControllerService {

    /**
     * Get DataNet Shared Orders setting for a group
     * User must have pui 'READ_DATANET_SHARED_ORDERS'
     * @param scopeId scopeId
     * @returns DataNetSharedOrdersDto Ok
     * @throws ApiError
     */
    public static getDataNetSharedOrdersSettingUsingGet(
        scopeId: string,
    ): CancelablePromise<DataNetSharedOrdersDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-company-settings-datanet/external/{scopeId}/sharedorders',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                500: `Internal server error.`,
            },
        });
    }

    /**
     * Update setting for DataNet Shared Orders
     * User must have pui 'MANAGE_DATANET_SHARED_ORDERS'
     * @param dataNetSharedOrdersDtoRequest dataNetSharedOrdersDtoRequest
     * @param scopeId scopeId
     * @returns DataNetSharedOrdersDto Ok
     * @throws ApiError
     */
    public static setDataNetSharedOrdersSettingUsingPut(
        dataNetSharedOrdersDtoRequest: DataNetSharedOrdersDto,
        scopeId: string,
    ): CancelablePromise<DataNetSharedOrdersDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/corp-company-settings-datanet/external/{scopeId}/sharedorders',
            path: {
                'scopeId': scopeId,
            },
            body: dataNetSharedOrdersDtoRequest,
            errors: {
                500: `Internal server error.`,
            },
        });
    }

    /**
     * Delete DataNet Shared Orders setting for a group
     * User must have pui 'MANAGE_DATANET_SHARED_ORDERS'
     * @param scopeId scopeId
     * @returns SharedOrdersSummary Ok
     * @throws ApiError
     */
    public static deleteDataNetSharedOrdersSettingUsingDelete(
        scopeId: string,
    ): CancelablePromise<SharedOrdersSummary> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/corp-company-settings-datanet/external/{scopeId}/sharedorders',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                500: `Internal server error.`,
            },
        });
    }

}