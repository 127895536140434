/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddOfferingRequestDTO } from '../models/AddOfferingRequestDTO';
import type { ConfigurationDTO } from '../models/ConfigurationDTO';
import type { FinishConfigurationRequestDTO } from '../models/FinishConfigurationRequestDTO';
import type { FinishConfigurationResponseDTO } from '../models/FinishConfigurationResponseDTO';
import type { StartConfigurationRequestDTO } from '../models/StartConfigurationRequestDTO';
import type { StartConfigurationResponseDTO } from '../models/StartConfigurationResponseDTO';
import type { UpdateConfigurationRequestDTO } from '../models/UpdateConfigurationRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConfigurationControllerService {

    /**
     * Update configuration
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param configurationId
     * @param requestBody
     * @param lang
     * @returns ConfigurationDTO Updates configuration
     * @throws ApiError
     */
    public static updateConfiguration(
        scopeId: string,
        tscid: string,
        configurationId: string,
        requestBody: UpdateConfigurationRequestDTO,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<ConfigurationDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/organizations/{tscid}/configuration/{configurationId}/update',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'configurationId': configurationId,
            },
            query: {
                'lang': lang,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Add optional child offering to product configuration
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param configurationId
     * @param requestBody
     * @param lang
     * @returns ConfigurationDTO Returns configuration
     * @throws ApiError
     */
    public static addOffering(
        scopeId: string,
        tscid: string,
        configurationId: string,
        requestBody: AddOfferingRequestDTO,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<ConfigurationDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/organizations/{tscid}/configuration/{configurationId}/offerings',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'configurationId': configurationId,
            },
            query: {
                'lang': lang,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Finish configuration
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param configurationId
     * @param requestBody
     * @param lang
     * @returns FinishConfigurationResponseDTO Returns basket ID
     * @throws ApiError
     */
    public static finishConfiguration(
        scopeId: string,
        tscid: string,
        configurationId: string,
        requestBody: FinishConfigurationRequestDTO,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<FinishConfigurationResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/organizations/{tscid}/configuration/{configurationId}/finish',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'configurationId': configurationId,
            },
            query: {
                'lang': lang,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Start a product configuration
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns StartConfigurationResponseDTO Returns the temporary basket Id used for configuration
     * @throws ApiError
     */
    public static startConfiguration(
        scopeId: string,
        tscid: string,
        requestBody: StartConfigurationRequestDTO,
    ): CancelablePromise<StartConfigurationResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/organizations/{tscid}/configuration/start',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get configuration
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param configurationId
     * @param lang
     * @returns ConfigurationDTO Returns configuration
     * @throws ApiError
     */
    public static getConfigurationById(
        scopeId: string,
        tscid: string,
        configurationId: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<ConfigurationDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/configuration/{configurationId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'configurationId': configurationId,
            },
            query: {
                'lang': lang,
            },
        });
    }

    /**
     * Delete optional child offering from product configuration
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param configurationId
     * @param itemId
     * @param lang
     * @returns ConfigurationDTO Returns configuration
     * @throws ApiError
     */
    public static deleteOffering(
        scopeId: string,
        tscid: string,
        configurationId: string,
        itemId: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<ConfigurationDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/public/scope/{scopeId}/organizations/{tscid}/configuration/{configurationId}/offerings/{itemId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'configurationId': configurationId,
                'itemId': itemId,
            },
            query: {
                'lang': lang,
            },
        });
    }

}