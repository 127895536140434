/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FullAnalysisResponse } from '../models/FullAnalysisResponse';
import type { QuickAnalysisResponse } from '../models/QuickAnalysisResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TroubleshooterControllerService {

    /**
     * Get quick troubleshoot analysis
     * @param scopeId
     * @param tscid
     * @param c2Buid
     * @returns QuickAnalysisResponse OK
     * @throws ApiError
     */
    public static getQuickAnalysis(
        scopeId: string,
        tscid: string,
        c2Buid: string,
    ): CancelablePromise<QuickAnalysisResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/{tscid}/analyze-quick/{c2buid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'c2buid': c2Buid,
            },
            errors: {
                400: `Bad request`,
                403: `Access denied`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get full troubleshoot analysis
     * @param scopeId
     * @param tscid
     * @param c2Buid
     * @returns FullAnalysisResponse OK
     * @throws ApiError
     */
    public static getFullAnalysis(
        scopeId: string,
        tscid: string,
        c2Buid: string,
    ): CancelablePromise<FullAnalysisResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/{tscid}/analyze-full/{c2buid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'c2buid': c2Buid,
            },
            errors: {
                400: `Bad request`,
                403: `Access denied`,
                500: `Internal Server Error`,
            },
        });
    }

}