/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Update2FaUrl } from '../models/Update2FaUrl';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TwoFactorAuthorizationApiService {

    /**
     * Get url to update 2FA contact information
     * @param scopeId
     * @param redirectUri
     * @param language
     * @returns Update2FaUrl Success
     * @throws ApiError
     */
    public static get2FaUpdateUrl(
        scopeId: string,
        redirectUri: string,
        language: 'sv' | 'en',
    ): CancelablePromise<Update2FaUrl> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/update2faurl',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'redirect_uri': redirectUri,
                'language': language,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}