/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DetachedBasketDTO } from '../models/DetachedBasketDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OpenBasketControllerService {

    /**
     * Fetch a basket
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param basketId
     * @param lang
     * @returns DetachedBasketDTO Returns basket
     * @throws ApiError
     */
    public static getBasketById1(
        basketId: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<DetachedBasketDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/open/basket/{basketId}',
            path: {
                'basketId': basketId,
            },
            query: {
                'lang': lang,
            },
            errors: {
                404: `Basket not found`,
            },
        });
    }

}