/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddTppUsersRequestBody } from '../models/AddTppUsersRequestBody';
import type { DeleteTppUsersRequestBody } from '../models/DeleteTppUsersRequestBody';
import type { TouchpointPlusResponseBody } from '../models/TouchpointPlusResponseBody';
import type { UpdateTppUsersRequestBody } from '../models/UpdateTppUsersRequestBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TouchpointPlusControllerService {

    /**
     * Add Touchpoint Plus Users
     * @param body body
     * @param scopeId scopeId
     * @returns TouchpointPlusResponseBody Successful request
     * @throws ApiError
     */
    public static addTouchPointPlusUsersUsingPost(
        body: AddTppUsersRequestBody,
        scopeId: string,
    ): CancelablePromise<TouchpointPlusResponseBody> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/services/corp/touchpoint-plus/v1.0.0/{scopeId}/touchpointplus/users/add',
            path: {
                'scopeId': scopeId,
            },
            body: body,
        });
    }

    /**
     * Delete Touchpoint Plus Users
     * @param body body
     * @param scopeId scopeId
     * @returns TouchpointPlusResponseBody Successful request
     * @throws ApiError
     */
    public static removeTouchPointPlusUsersUsingPost(
        body: DeleteTppUsersRequestBody,
        scopeId: string,
    ): CancelablePromise<TouchpointPlusResponseBody> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/services/corp/touchpoint-plus/v1.0.0/{scopeId}/touchpointplus/users/remove',
            path: {
                'scopeId': scopeId,
            },
            body: body,
        });
    }

    /**
     * Update Touchpoint Plus Users
     * @param body body
     * @param scopeId scopeId
     * @returns TouchpointPlusResponseBody Successful request
     * @throws ApiError
     */
    public static updateTouchPointPlusUsersUsingPost(
        body: UpdateTppUsersRequestBody,
        scopeId: string,
    ): CancelablePromise<TouchpointPlusResponseBody> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/services/corp/touchpoint-plus/v1.0.0/{scopeId}/touchpointplus/users/update',
            path: {
                'scopeId': scopeId,
            },
            body: body,
        });
    }

}