/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetImitationInfoResponseDTO } from '../models/GetImitationInfoResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ImitationInfoControllerService {

    /**
     * Get information about user owning the access profile being imitated
     * @param accessProfileId Access Profile ID
     * @returns GetImitationInfoResponseDTO OK
     * @throws ApiError
     */
    public static getImitationInfo(
        accessProfileId: number,
    ): CancelablePromise<GetImitationInfoResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/imitationinfo/accessprofiles/{accessProfileId}/imitate-info',
            path: {
                'accessProfileId': accessProfileId,
            },
        });
    }

}