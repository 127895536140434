/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddNotificationsRequest } from '../models/AddNotificationsRequest';
import type { DeleteNotificationsRequest } from '../models/DeleteNotificationsRequest';
import type { NotificationListResponse } from '../models/NotificationListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationService {

    /**
     * Returns list of notifications
     * @param partyId
     * @param tscId
     * @param xAuthScopeId
     * @returns NotificationListResponse OK
     * @throws ApiError
     */
    public static getNotifications(
        partyId: string,
        tscId: string,
        xAuthScopeId: any,
    ): CancelablePromise<NotificationListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscribers/{partyId}/notifications',
            path: {
                'partyId': partyId,
            },
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'tscId': tscId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Creates new notifications
     * @param partyId
     * @param xAuthScopeId
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public static addNotifications(
        partyId: string,
        xAuthScopeId: any,
        requestBody: AddNotificationsRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/subscribers/{partyId}/notifications',
            path: {
                'partyId': partyId,
            },
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Deletes notifications
     * @param partyId
     * @param xAuthScopeId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static deleteNotifications(
        partyId: string,
        xAuthScopeId: any,
        requestBody: DeleteNotificationsRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/subscribers/{partyId}/notifications',
            path: {
                'partyId': partyId,
            },
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

}