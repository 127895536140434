/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessProfileInfoDTO } from '../models/AccessProfileInfoDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccessProfileControllerService {

    /**
     * @param scopeId
     * @param accessProfileId
     * @returns AccessProfileInfoDTO OK
     * @throws ApiError
     */
    public static getAccessProfileUserInfo(
        scopeId: number,
        accessProfileId: number,
    ): CancelablePromise<AccessProfileInfoDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/accessprofiles/{accessProfileId}/userinfo',
            path: {
                'scopeId': scopeId,
                'accessProfileId': accessProfileId,
            },
        });
    }

}