/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UsageResponse } from '../models/UsageResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DataUsageControllerService {

    /**
     * @param scopeId
     * @param c2BUid
     * @param from
     * @param to
     * @param usageType
     * @param dailyLookup
     * @param monthlyLookup
     * @returns UsageResponse OK
     * @throws ApiError
     */
    public static getDataUsageHistory(
        scopeId: string,
        c2BUid: string,
        from: string,
        to: string,
        usageType: 'MONTHLY' | 'DAILY' | 'COMBINED',
        dailyLookup?: number,
        monthlyLookup?: number,
    ): CancelablePromise<UsageResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/mobile-subscriptions/{c2bUid}/data-usage-history',
            path: {
                'scopeId': scopeId,
                'c2bUid': c2BUid,
            },
            query: {
                'from': from,
                'to': to,
                'dailyLookup': dailyLookup,
                'monthlyLookup': monthlyLookup,
                'usageType': usageType,
            },
        });
    }

}