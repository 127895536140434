/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DatacomDeliveriesUI } from '../models/DatacomDeliveriesUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TestControllerImplService {

    /**
     * Returns a list of VPNs based on user and customer.
     * @param scopeId Customer identification number
     * @param subscriptionId subscriptionId
     * @returns DatacomDeliveriesUI Success
     * @throws ApiError
     */
    public static getSubscriptionUsingGet(
        scopeId: string,
        subscriptionId: string,
    ): CancelablePromise<DatacomDeliveriesUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/test/{scopeId}/subscription/{subscriptionId}',
            path: {
                'scopeId': scopeId,
                'subscriptionId': subscriptionId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}