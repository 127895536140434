/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactInfoRequestBodyUI } from '../models/ContactInfoRequestBodyUI';
import type { ContactInformationUI } from '../models/ContactInformationUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LocationsControllerImplService {

    /**
     * Returns contact information on datacom locations for subscription.
     * @param scopeId Customer identification number
     * @param uId uId
     * @returns ContactInformationUI Success
     * @throws ApiError
     */
    public static getLocationsUsingGet(
        scopeId: string,
        uId: string,
    ): CancelablePromise<ContactInformationUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscription/{uId}/locations',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Updates additional contact information on datacom locations for subscription.
     * @param contactInfoRequestBody contactInfoRequestBody
     * @param id id
     * @param scopeId Customer identification number
     * @param uId uId
     * @returns any OK
     * @throws ApiError
     */
    public static updateAdditionalContactOneUsingPut(
        contactInfoRequestBody: ContactInfoRequestBodyUI,
        id: string,
        scopeId: string,
        uId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/scope/{scopeId}/subscription/{uId}/locations/{id}/additional-contact-one',
            path: {
                'id': id,
                'scopeId': scopeId,
                'uId': uId,
            },
            body: contactInfoRequestBody,
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Delete additional contact information on datacom locations for subscription.
     * @param id id
     * @param scopeId Customer identification number
     * @param uId uId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAdditionalContactOneUsingDelete(
        id: string,
        scopeId: string,
        uId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/scope/{scopeId}/subscription/{uId}/locations/{id}/additional-contact-one',
            path: {
                'id': id,
                'scopeId': scopeId,
                'uId': uId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Updates additional contact information on datacom locations for subscription.
     * @param contactInfoRequestBody contactInfoRequestBody
     * @param id id
     * @param scopeId Customer identification number
     * @param uId uId
     * @returns any OK
     * @throws ApiError
     */
    public static updateAdditionalContactTwoUsingPut(
        contactInfoRequestBody: ContactInfoRequestBodyUI,
        id: string,
        scopeId: string,
        uId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/scope/{scopeId}/subscription/{uId}/locations/{id}/additional-contact-two',
            path: {
                'id': id,
                'scopeId': scopeId,
                'uId': uId,
            },
            body: contactInfoRequestBody,
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Delete additional contact information on datacom locations for subscription.
     * @param id id
     * @param scopeId Customer identification number
     * @param uId uId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAdditionalContactTwoUsingDelete(
        id: string,
        scopeId: string,
        uId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/scope/{scopeId}/subscription/{uId}/locations/{id}/additional-contact-two',
            path: {
                'id': id,
                'scopeId': scopeId,
                'uId': uId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Updates main contact information on datacom locations for subscription.
     * @param contactInfoRequestBody contactInfoRequestBody
     * @param id id
     * @param scopeId Customer identification number
     * @param uId uId
     * @returns any OK
     * @throws ApiError
     */
    public static updateContactInformationUsingPut(
        contactInfoRequestBody: ContactInfoRequestBodyUI,
        id: string,
        scopeId: string,
        uId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/scope/{scopeId}/subscription/{uId}/locations/{id}/contact',
            path: {
                'id': id,
                'scopeId': scopeId,
                'uId': uId,
            },
            body: contactInfoRequestBody,
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}