/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedSubscriptionsUI } from '../models/PaginatedSubscriptionsUI';
import type { PaginationRequestDto } from '../models/PaginationRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ListPaginationControllerImplService {

    /**
     * @param scopeId
     * @param requestBody
     * @returns PaginatedSubscriptionsUI OK
     * @throws ApiError
     */
    public static getPaginatedSubscriptions(
        scopeId: string,
        requestBody: PaginationRequestDto,
    ): CancelablePromise<PaginatedSubscriptionsUI> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param scopeId
     * @param category
     * @param limit
     * @param offset
     * @param searchString
     * @param filter
     * @param sort
     * @returns any OK
     * @throws ApiError
     */
    public static getPaginatedSubscriptionList(
        scopeId: string,
        category: 'CLOUD_SERVICES' | 'CONF_MEET_SERVICES' | 'CONTACT_CENTER' | 'DATACOM' | 'FIXED_BROADBAND' | 'FIXED_VOICE' | 'FUNCTION_ACD' | 'FUNCTION_FIXED_EXT' | 'FUNCTION_MOBILE_EXT' | 'FUNCTION_MOBILE' | 'FUNCTION_OTHER' | 'FUNCTION_TERMINAL' | 'GOODS' | 'INSURANCE' | 'MOBILE_BROADBAND' | 'MOBILE_M2M' | 'SMS_SERVICES' | 'MOBILE_CORP_DATAPOOL' | 'MOBILE_VOICE' | 'MOBILITY_MANAGEMENT' | 'NUMBER_SERVICES' | 'BUSINESSNETWORK_OTHER' | 'SERVICE_MANAGEMENT' | 'SWITCHBOARD_ACCESS' | 'SWITCHBOARD_EXT' | 'SWITCHBOARD_UCS',
        limit: number,
        offset: number,
        searchString?: string,
        filter?: Array<string>,
        sort?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/pagination',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'category': category,
                'limit': limit,
                'offset': offset,
                'searchString': searchString,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * @param scopeId
     * @param limit
     * @param offset
     * @param searchString
     * @param filter
     * @param sort
     * @returns any OK
     * @throws ApiError
     */
    public static getPaginatedDatacomSubscriptionList(
        scopeId: string,
        limit: number,
        offset: number,
        searchString?: string,
        filter?: Array<string>,
        sort?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/datacom/pagination',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'limit': limit,
                'offset': offset,
                'searchString': searchString,
                'filter': filter,
                'sort': sort,
            },
        });
    }

}