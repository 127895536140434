/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveSwitchboardResultDTO } from '../models/ActiveSwitchboardResultDTO';
import type { SwitchboardDetailsResultDTO } from '../models/SwitchboardDetailsResultDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PrivateSwitchboardsControllerV2Service {

    /**
     * @param scopeId scopeId
     * @param tscId
     * @param switchboardType
     * @returns ActiveSwitchboardResultDTO OK
     * @throws ApiError
     */
    public static getActiveSwitchboards1(
        scopeId: any,
        tscId: string,
        switchboardType: 'touchpoint' | 'smartconnect' | 'all' = 'all',
    ): CancelablePromise<Array<ActiveSwitchboardResultDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/scope/{scopeId}/organizations/{tscId}/switchboards',
            path: {
                'scopeId': scopeId,
                'tscId': tscId,
            },
            query: {
                'switchboardType': switchboardType,
            },
        });
    }

    /**
     * @param scopeId scopeId
     * @param c2Buid
     * @returns SwitchboardDetailsResultDTO OK
     * @throws ApiError
     */
    public static getSwitchboardDetails1(
        scopeId: any,
        c2Buid: string,
    ): CancelablePromise<SwitchboardDetailsResultDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/scope/{scopeId}/organizations/{tscId}/switchboards/{c2buid}/details',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
        });
    }

}