/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveSwitchboardResultDTO } from '../models/ActiveSwitchboardResultDTO';
import type { HuntingGroupsResultDTO } from '../models/HuntingGroupsResultDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublicSwitchboardsControllerV2Service {

    /**
     * @param scopeId scopeId
     * @param c2Buid
     * @returns HuntingGroupsResultDTO OK
     * @throws ApiError
     */
    public static getHuntingGroups(
        scopeId: any,
        c2Buid: string,
    ): CancelablePromise<HuntingGroupsResultDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/switchboards/{c2buid}/hunting-groups',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
        });
    }

    /**
     * @param scopeId scopeId
     * @param tscid
     * @param switchboardType
     * @returns ActiveSwitchboardResultDTO OK
     * @throws ApiError
     */
    public static getActiveSwitchboards(
        scopeId: any,
        tscid: string,
        switchboardType: 'touchpoint' | 'smartconnect' | 'all' = 'all',
    ): CancelablePromise<Array<ActiveSwitchboardResultDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/switchboards',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'switchboardType': switchboardType,
            },
        });
    }

}