/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseFunction } from '../models/BaseFunction';
import type { RedirectFunction } from '../models/RedirectFunction';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ModifyCallForwardingControllerService {

    /**
     * updateVolteRedirectFunctionViaC2bUid
     * Update function of redirect type using c2buid
     * @param scopeId CustomerInfoRequest identification number
     * @param c2Buid c2buid
     * @param requestBody
     * @returns BaseFunction Successful request
     * @throws ApiError
     */
    public static updateVolteRedirectWithC2BUidFunction(
        scopeId: string,
        c2Buid: string,
        requestBody: RedirectFunction,
    ): CancelablePromise<BaseFunction> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/customers/{scopeId}/voltes/{c2buid}/functions/redirect',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

}