/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ModuleDisplayAsNewInfo } from '../models/ModuleDisplayAsNewInfo';
import type { ModuleSelectedInfo } from '../models/ModuleSelectedInfo';
import type { ModulesUI } from '../models/ModulesUI';
import type { StatusMessageUI } from '../models/StatusMessageUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WidgetControllerService {

    /**
     * Get module settings for scopeid
     * @param scopeId
     * @returns ModulesUI Module settings for scopeid
     * @throws ApiError
     */
    public static getModules(
        scopeId: string,
    ): CancelablePromise<ModulesUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/available-modules',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Save module settings on user
     * @param scopeId
     * @param requestBody
     * @returns StatusMessageUI Module settings saved
     * @throws ApiError
     */
    public static putModules(
        scopeId: string,
        requestBody: Array<ModuleSelectedInfo>,
    ): CancelablePromise<StatusMessageUI> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/scope/{scopeId}/available-modules',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Set module as new on user
     * @param scopeId
     * @param requestBody
     * @returns StatusMessageUI Specified modules set as new
     * @throws ApiError
     */
    public static patchModulesSelected(
        scopeId: string,
        requestBody: Array<ModuleDisplayAsNewInfo>,
    ): CancelablePromise<StatusMessageUI> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/scope/{scopeId}/available-modules/displayasnew',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

}