/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetMetadataResponse } from '../models/GetMetadataResponse';
import type { Response } from '../models/Response';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorpCustomerCorrespondenceControllerService {

    /**
     * This method return a list of correspondence
     * @param scopeId scopeId
     * @param tscid tscid
     * @returns GetMetadataResponse Successful request
     * @throws ApiError
     */
    public static getCorrespondenceUsingGet(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<GetMetadataResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-customer-correspondence-service/{scopeId}/correspondence/{tscid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

    /**
     * This method gets a correspondence file
     * @param correspondenceId correspondenceId
     * @param scopeId scopeId
     * @param tscid tscid
     * @returns Response Successful request
     * @throws ApiError
     */
    public static getCorrespondenceFileUsingGet(
        correspondenceId: string,
        scopeId: string,
        tscid: string,
    ): CancelablePromise<Response> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-customer-correspondence-service/{scopeId}/correspondence/{tscid}/file/{correspondenceId}',
            path: {
                'correspondenceId': correspondenceId,
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

}