/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PutReferenceDto } from '../models/PutReferenceDto';
import type { ReferenceUI } from '../models/ReferenceUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorporateInstalledbaseReferenceService {

    /**
     * Returns reference on a subscription
     * @param scopeId Customer identification number
     * @param uId
     * @param referenceType
     * @returns ReferenceUI Success
     * @throws ApiError
     */
    public static getReference(
        scopeId: string,
        uId: string,
        referenceType: 'DATACOM_ALIAS' | 'FUNCTION_REFERENCE' | 'MOBILE_USER',
    ): CancelablePromise<ReferenceUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscription/{uId}/reference',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
            query: {
                'referenceType': referenceType,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Set reference on a subscription
     * @param scopeId Customer identification number
     * @param uId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static putReference(
        scopeId: string,
        uId: string,
        requestBody: PutReferenceDto,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/scope/{scopeId}/subscription/{uId}/reference',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}