/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApplicationRegistrationPrecheckResponse } from '../models/ApplicationRegistrationPrecheckResponse';
import type { ApplicationRegistrationRequestV2 } from '../models/ApplicationRegistrationRequestV2';
import type { ApplicationRegistrationResponseV2 } from '../models/ApplicationRegistrationResponseV2';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApplicationRequestV2ControllerService {

    /**
     * @param requestBody
     * @returns ApplicationRegistrationResponseV2 OK
     * @throws ApiError
     */
    public static createApplication(
        requestBody: ApplicationRegistrationRequestV2,
    ): CancelablePromise<ApplicationRegistrationResponseV2> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/applicationrequests/registration',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ApplicationRegistrationPrecheckResponse OK
     * @throws ApiError
     */
    public static precheck(): CancelablePromise<ApplicationRegistrationPrecheckResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/applicationrequests/precheck',
        });
    }

}