/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HasVpnAccessUI } from '../models/HasVpnAccessUI';
import type { VpnCountUI } from '../models/VpnCountUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalVpnControllerService {

    /**
     * Returns if user has access to VPN.
     * @param scopeId Customer identification number
     * @param uId uId
     * @returns HasVpnAccessUI Success
     * @throws ApiError
     */
    public static hasAccessToVpnByUIdUsingGet(
        scopeId: string,
        uId: string,
    ): CancelablePromise<HasVpnAccessUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/scope/{scopeId}/hasAccess/vpn/uId/{uId}',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Returns if user has access to VPN.
     * @param scopeId Customer identification number
     * @param vpnId vpnId
     * @returns HasVpnAccessUI Success
     * @throws ApiError
     */
    public static hasAccessToVpnByVpnidUsingGet(
        scopeId: string,
        vpnId: string,
    ): CancelablePromise<HasVpnAccessUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/scope/{scopeId}/hasAccess/vpn/vpnId/{vpnId}',
            path: {
                'scopeId': scopeId,
                'vpnId': vpnId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Returns count of VPNs based on user and customer.
     * @param scopeId Customer identification number
     * @returns VpnCountUI Success
     * @throws ApiError
     */
    public static getVpnCountUsingGet(
        scopeId: string,
    ): CancelablePromise<VpnCountUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/scope/{scopeId}/vpns/count',
            path: {
                'scopeId': scopeId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}