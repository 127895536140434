/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FederatedSsoUrlDTO } from '../models/FederatedSsoUrlDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LoginFederatedSsoControllerService {

    /**
     * Create a login url to CIAM with an optional target URL
     * @param idp
     * @returns FederatedSsoUrlDTO Response contains url to login page
     * @throws ApiError
     */
    public static initiateFederatedSsoUrl(
        idp: string,
    ): CancelablePromise<FederatedSsoUrlDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/federatedssourl',
            query: {
                'idp': idp,
            },
        });
    }

    /**
     * Handle return from CIAM. Trade 'code' for cookie and redirect user. If 'error' query param, redirect to an error page.
     * @param code
     * @param error
     * @param errorDescription
     * @returns void
     * @throws ApiError
     */
    public static handleReturnFromCiamFederated(
        code?: string,
        error?: string,
        errorDescription?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/oidc/ciam/loginfederatedsso',
            query: {
                'code': code,
                'error': error,
                'error_description': errorDescription,
            },
            errors: {
                302: `Redirect user to expected target URL or error page`,
            },
        });
    }

}