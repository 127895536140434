/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UpgradeToBankIdRequestDTO } from '../models/UpgradeToBankIdRequestDTO';
import type { UpgradeToBankIdResponseDTO } from '../models/UpgradeToBankIdResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UpgradeToBankIdControllerService {

    /**
     * Returns an url to Ciam which initates the upgrade bankId flow.
     * @param requestBody
     * @param language
     * @returns UpgradeToBankIdResponseDTO Url that leads to Ciam's upgrade bankId flow
     * @throws ApiError
     */
    public static upgradeBankId(
        requestBody: UpgradeToBankIdRequestDTO,
        language?: string,
    ): CancelablePromise<UpgradeToBankIdResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/upgradebankid',
            query: {
                'language': language,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Handles the result of a CIAM BankID upgrade
     * @param state
     * @param code
     * @param error
     * @param errorDescription
     * @returns void
     * @throws ApiError
     */
    public static handleReturnFromCiam(
        state?: string,
        code?: string,
        error?: string,
        errorDescription?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/oidc/ciam/upgradebankid',
            query: {
                'state': state,
                'code': code,
                'error': error,
                'error_description': errorDescription,
            },
            errors: {
                302: `Redirects to foretag/mybusiness if successful. Otherwise will redirect to foretag/mybusiness/something-went-wrong`,
            },
        });
    }

}