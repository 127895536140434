/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WidgetOrderUI } from '../models/WidgetOrderUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WidgetControllerService {

    /**
     * getOrdersForWidget
     * @param scopeId scopeId
     * @param filterTscids comma separated list of 1 to many tscids
     * @param limit limit
     * @param status status
     * @returns WidgetOrderUI OK
     * @throws ApiError
     */
    public static getOrdersForWidgetUsingGet(
        scopeId: string,
        filterTscids?: string,
        limit: number = 3,
        status?: 'CANCELLED' | 'DELIVERED' | 'INCOMPLETE' | 'ONGOING' | 'RECEIVED' | 'UNKNOWN',
    ): CancelablePromise<Array<WidgetOrderUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-order-history/widget/{scopeId}/orders',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'filterTscids': filterTscids,
                'limit': limit,
                'status': status,
            },
        });
    }

}