/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LogoutResponseDto } from '../models/LogoutResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LogoutControllerService {

    /**
     * Initiate logout
     * @param sfSession
     * @returns LogoutResponseDto Response contains url to logout page
     * @throws ApiError
     */
    public static initiateLogout(
        sfSession?: string,
    ): CancelablePromise<LogoutResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/logout',
            cookies: {
                'SFSession': sfSession,
            },
        });
    }

}