/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FunctionList } from '../models/FunctionList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GetCallForwardingControllerService {

    /**
     * List functions
     * getVolteRedirectFunctions
     * @param scopeId CustomerInfoRequest identification number
     * @param c2Buid c2buid
     * @returns FunctionList Successful request
     * @throws ApiError
     */
    public static getVolteRedirectWithC2BUidFunctions(
        scopeId: string,
        c2Buid: string,
    ): CancelablePromise<FunctionList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/customers/{scopeId}/voltes/{c2buid}/functions',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

}