/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMailAccountRequestUI } from "../models/CreateMailAccountRequestUI";
import type { MailAccountUI } from "../models/MailAccountUI";
import type { WrapperResponseMailAccountUI } from "../models/WrapperResponseMailAccountUI";
import type { WrapperResponseSetMailAccountUI } from "../models/WrapperResponseSetMailAccountUI";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class MailAccountsControllerService {
  /**
   * Generate a new password for the mail account connected to given mailAccountId
   * @param scopeId
   * @param c2Buid
   * @param mailAccountId
   * @returns WrapperResponseMailAccountUI Successful request
   * @throws ApiError
   */
  public static renewPassword(
    scopeId: string,
    c2Buid: string,
    mailAccountId: string
  ): CancelablePromise<WrapperResponseMailAccountUI> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/scope/{scopeId}/subscriptions/{c2buid}/mailaccounts/{mailAccountId}/renew-password/",
      path: {
        scopeId: scopeId,
        c2buid: c2Buid,
        mailAccountId: mailAccountId,
      },
      errors: {
        400: `Bad request`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Get mail accounts connected to given c2buid
   * @param scopeId
   * @param c2Buid
   * @returns WrapperResponseSetMailAccountUI Successful request
   * @throws ApiError
   */
  public static getMailAccounts(
    scopeId: string,
    c2Buid: string
  ): CancelablePromise<WrapperResponseSetMailAccountUI> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/scope/{scopeId}/subscriptions/{c2buid}/mailaccounts/",
      path: {
        scopeId: scopeId,
        c2buid: c2Buid,
      },
      errors: {
        400: `Bad request`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Create a new mail account on given msisdn
   * @param scopeId
   * @param c2Buid
   * @param requestBody
   * @returns WrapperResponseMailAccountUI Successful request
   * @throws ApiError
   */
  public static createMailAccount(
    scopeId: string,
    c2Buid: string,
    requestBody: CreateMailAccountRequestUI
  ): CancelablePromise<WrapperResponseMailAccountUI> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/scope/{scopeId}/subscriptions/{c2buid}/mailaccounts/",
      path: {
        scopeId: scopeId,
        c2buid: c2Buid,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Updates the mail account connected to given mailAccountId
   * @param scopeId
   * @param c2Buid
   * @param mailAccountId
   * @param requestBody
   * @returns WrapperResponseMailAccountUI Successful request
   * @throws ApiError
   */
  public static patchMailAccount(
    scopeId: string,
    c2Buid: string,
    mailAccountId: string,
    requestBody: MailAccountUI
  ): CancelablePromise<WrapperResponseMailAccountUI> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/scope/{scopeId}/subscriptions/{c2buid}/mailaccounts/{mailAccountId}/",
      path: {
        scopeId: scopeId,
        c2buid: c2Buid,
        mailAccountId: mailAccountId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Removes the mail account connected to given mailAccountId
   * @param scopeId
   * @param c2Buid
   * @param mailAccountId
   * @returns WrapperResponseMailAccountUI Successful request
   * @throws ApiError
   */
  public static deleteMailAccount(
    scopeId: string,
    c2Buid: string,
    mailAccountId: string
  ): CancelablePromise<WrapperResponseMailAccountUI> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/scope/{scopeId}/subscriptions/{c2buid}/mailaccounts/{mailAccountId}",
      path: {
        scopeId: scopeId,
        c2buid: c2Buid,
        mailAccountId: mailAccountId,
      },
      errors: {
        400: `Bad request`,
        500: `Internal server error`,
      },
    });
  }
}
