/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FunctionalAgreementResponse } from '../models/FunctionalAgreementResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalControllerService {

    /**
     * Check if any of the given tscIds is connected to a functional agreement (using C2bCache as source)
     * @param tscIds
     * @returns FunctionalAgreementResponse OK
     * @throws ApiError
     */
    public static checkForFunctionalAgreements1(
        tscIds: Array<string>,
    ): CancelablePromise<FunctionalAgreementResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/has-functional-agreements',
            query: {
                'tscIds': tscIds,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}