/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StatusMessage } from '../models/StatusMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalControllerService {

    /**
     * @returns StatusMessage OK
     * @throws ApiError
     */
    public static purge(): CancelablePromise<StatusMessage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/misc/purge',
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static ping(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/misc/ping',
        });
    }

}