/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AffectedAddonServicesUI } from '../models/AffectedAddonServicesUI';
import type { NeedCreditEvaluationUI } from '../models/NeedCreditEvaluationUI';
import type { SubscriptionChangeTypeUI } from '../models/SubscriptionChangeTypeUI';
import type { UpdateSubscriptionTypeRequestUI } from '../models/UpdateSubscriptionTypeRequestUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileSubscriptionTypeControllerService {

    /**
     * Get which subscription types are available for change. User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param c2Buid
     * @param includePricePlan
     * @param includeCurrentSubscription
     * @returns SubscriptionChangeTypeUI Successful request
     * @throws ApiError
     */
    public static getPossibleSubscriptionsForChange(
        scopeId: string,
        c2Buid: string,
        includePricePlan: boolean = false,
        includeCurrentSubscription: boolean = false,
    ): CancelablePromise<SubscriptionChangeTypeUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscriptions/{c2buid}/change-subscription',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            query: {
                'includePricePlan': includePricePlan,
                'includeCurrentSubscription': includeCurrentSubscription,
            },
            errors: {
                400: `Bad request`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update the subscription type on the given c2buid. User must have 'PRODUCT_ADMINISTRATION' to access this resource
     * @param scopeId
     * @param c2Buid
     * @param requestBody
     * @returns any Successful request
     * @throws ApiError
     */
    public static updateSubscriptionType(
        scopeId: string,
        c2Buid: string,
        requestBody: UpdateSubscriptionTypeRequestUI,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}/subscriptions/{c2buid}/change-subscription',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Check if credit evaluation is needed. User must have 'PRODUCT_ADMINISTRATION' to access this resource
     * @param scopeId
     * @param c2Buid
     * @param newSubscriptionType
     * @returns NeedCreditEvaluationUI Successful request
     * @throws ApiError
     */
    public static needCreditEvaluationCheck(
        scopeId: string,
        c2Buid: string,
        newSubscriptionType: string,
    ): CancelablePromise<NeedCreditEvaluationUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscriptions/{c2buid}/credit-evaluation-check',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            query: {
                'newSubscriptionType': newSubscriptionType,
            },
            errors: {
                400: `Bad request`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get affected services on subscription change. User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param c2Buid
     * @param newSubscriptionType
     * @returns AffectedAddonServicesUI Successful request
     * @throws ApiError
     */
    public static getAffectedServicesOnSubscriptionChange(
        scopeId: string,
        c2Buid: string,
        newSubscriptionType: string,
    ): CancelablePromise<AffectedAddonServicesUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscriptions/{c2buid}/affected-services',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            query: {
                'newSubscriptionType': newSubscriptionType,
            },
        });
    }

}