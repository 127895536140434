/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetNfAgreementsResponseDto } from '../models/GetNfAgreementsResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NfAgreementsControllerService {

    /**
     * Get Agreements based on Scope, optional filter on TSCIDs
     * User must have PUI: 'READ_AGREEMENT'
     * @param scopeId
     * @param tscids
     * @param includeInactive
     * @returns GetNfAgreementsResponseDto OK
     * @throws ApiError
     */
    public static getNfAgreementsOnScope(
        scopeId: string,
        tscids?: Array<string>,
        includeInactive: boolean = false,
    ): CancelablePromise<GetNfAgreementsResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/nfagreements',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'tscids': tscids,
                'includeInactive': includeInactive,
            },
        });
    }

}