/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BroadbandListUI } from '../models/BroadbandListUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OpenProductListingControllerService {

    /**
     * Get internet access products
     * @param installationAddressPointId
     * @param lang
     * @returns BroadbandListUI Successful request
     * @throws ApiError
     */
    public static getBroadband(
        installationAddressPointId: string = '146351094',
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<BroadbandListUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corp-open-product-listing/public/broadband',
            query: {
                'installationAddressPointId': installationAddressPointId,
                'lang': lang,
            },
        });
    }

}