/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckoutDetachedBasketRequestDTO } from '../models/CheckoutDetachedBasketRequestDTO';
import type { CheckoutResponseDTO } from '../models/CheckoutResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DetachedBasketControllerService {

    /**
     * Checkout a detached basket
     * Only available for user with BankId verification
     * @param tscid
     * @param id
     * @param requestBody
     * @param lang
     * @returns CheckoutResponseDTO Successful checkout
     * @throws ApiError
     */
    public static checkoutDetachedBasket(
        tscid: string,
        id: string,
        requestBody: CheckoutDetachedBasketRequestDTO,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<CheckoutResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/organizations/{tscid}/detached/basket/{id}/checkout',
            path: {
                'tscid': tscid,
                'id': id,
            },
            query: {
                'lang': lang,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}