/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSuperUserInviteDTO } from '../models/CreateSuperUserInviteDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InviteApiForTeliaAdminService {

    /**
     * Create and send an invite that gives Super User access
     * @param groupId Customer Group ID
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createSuperUserInvite(
        groupId: number,
        requestBody: CreateSuperUserInviteDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/customergroups/{groupId}/invites/superusers',
            path: {
                'groupId': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Cancel possible Super User invite
     * @param groupId Customer Group ID
     * @returns any OK
     * @throws ApiError
     */
    public static cancelSuperUserInvite(
        groupId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/public/customergroups/{groupId}/invites/superusers',
            path: {
                'groupId': groupId,
            },
        });
    }

}