/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelMobileSubscriptionResponse } from '../models/CancelMobileSubscriptionResponse';
import type { CreateMobileSubscriptionResponse } from '../models/CreateMobileSubscriptionResponse';
import type { CreateSubscriptionRequestBody } from '../models/CreateSubscriptionRequestBody';
import type { TSCID } from '../models/TSCID';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileSubscriptionControllerService {

    /**
     * Creates new subscription in EFS-BSS
     * User must have Action: 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param requestBody
     * @returns CreateMobileSubscriptionResponse Successful request
     * @throws ApiError
     */
    public static createMobileSubscription(
        scopeId: string,
        requestBody: CreateSubscriptionRequestBody,
    ): CancelablePromise<CreateMobileSubscriptionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/subscription/{scopeId}',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                402: `Forbidden`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Cancel a bunch of subscriptions in EFS-BSS. If a main subscription is entered, main+component subscriptions are all cancelled. If a component subscription is entered, only that one is cancelled
     * User must have PUI: 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param c2Buid
     * @returns CancelMobileSubscriptionResponse Successful request
     * @throws ApiError
     */
    public static cancelMobileSubscriptionsIncludingComponentSubscriptions(
        scopeId: string,
        c2Buid: string,
    ): CancelablePromise<CancelMobileSubscriptionResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/subscription/scope/{scopeId}/subscriptions/{c2buid}',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            errors: {
                400: `Bad request`,
                402: `Forbidden`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Cancel a bunch of subscriptions in EFS-BSS
     * User must have PUI: 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns CancelMobileSubscriptionResponse Successful request
     * @throws ApiError
     */
    public static cancelMobileSubscriptions(
        scopeId: string,
        tscid: TSCID,
        requestBody: Array<string>,
    ): CancelablePromise<CancelMobileSubscriptionResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/subscription/scope/{scopeId}/organizations/{tscid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                402: `Forbidden`,
                500: `Internal server error`,
            },
        });
    }

}