/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MdsPolicyRequest } from '../models/MdsPolicyRequest';
import type { MdsPolicyResponse } from '../models/MdsPolicyResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MdsPolicyControllerService {

    /**
     * Get Policies
     * User must have access to pui: 'MANAGE_MDS_POLICY'.
     * @param scopeId
     * @param tscid
     * @returns MdsPolicyResponse Ok
     * @throws ApiError
     */
    public static getPolicies(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<Array<MdsPolicyResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/organisation/{tscid}/mds-policies',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            errors: {
                500: `Internal server error.`,
            },
        });
    }

    /**
     * Set Policy
     * User must have access to pui: 'MANAGE_MDS_POLICY'.
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns MdsPolicyResponse Ok
     * @throws ApiError
     */
    public static setPolicy(
        scopeId: string,
        tscid: string,
        requestBody: MdsPolicyRequest,
    ): CancelablePromise<MdsPolicyResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}/organisation/{tscid}/mds-policies',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal server error.`,
            },
        });
    }

}