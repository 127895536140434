/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetAllAgreementsOnScopeResponseDto } from '../models/GetAllAgreementsOnScopeResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AllAgreementsControllerService {

    /**
     * Get All Agreements based on Scope
     * User must have PUI: 'READ_AGREEMENT'
     * @param scopeId
     * @param agreementLevels
     * @param agreementTypes
     * @param category
     * @param limit
     * @param offset
     * @param includeInactive
     * @returns GetAllAgreementsOnScopeResponseDto OK
     * @throws ApiError
     */
    public static getAllAgreementsOnScope(
        scopeId: string,
        agreementLevels: string,
        agreementTypes: string,
        category: 'MOBILE_OWNER' | 'MOBILE_UTILIZER',
        limit: number = 1,
        offset?: number,
        includeInactive: boolean = false,
    ): CancelablePromise<GetAllAgreementsOnScopeResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/agreements',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'agreementLevels': agreementLevels,
                'agreementTypes': agreementTypes,
                'category': category,
                'limit': limit,
                'offset': offset,
                'includeInactive': includeInactive,
            },
        });
    }

}