/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChosenProduct } from '../models/ChosenProduct';
import type { FixedNetFilterRequest } from '../models/FixedNetFilterRequest';
import type { FixedNetFilterResponse } from '../models/FixedNetFilterResponse';
import type { FixedNetResponse } from '../models/FixedNetResponse';
import type { FixedNetValidationResponse } from '../models/FixedNetValidationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MybdFixedNetControllerService {

    /**
     * Validate fixed net conditions on address
     * @param scopeId scopeId
     * @param requestBody
     * @param streetName
     * @param city
     * @param streetNumber
     * @param entrance
     * @param latitude
     * @param longitude
     * @returns FixedNetValidationResponse Successful request
     * @throws ApiError
     */
    public static validateFixedNet(
        scopeId: any,
        requestBody: ChosenProduct,
        streetName?: string,
        city?: string,
        streetNumber?: string,
        entrance?: string,
        latitude?: string,
        longitude?: string,
    ): CancelablePromise<FixedNetValidationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/mybd/fixednet/{scopeId}/validate',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'streetName': streetName,
                'city': city,
                'streetNumber': streetNumber,
                'entrance': entrance,
                'latitude': latitude,
                'longitude': longitude,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Fetch filtered fixed net conditions
     * @param scopeId scopeId
     * @param requestBody
     * @returns FixedNetFilterResponse Successful request
     * @throws ApiError
     */
    public static filterFixedNet(
        scopeId: any,
        requestBody: FixedNetFilterRequest,
    ): CancelablePromise<FixedNetFilterResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/mybd/fixednet/{scopeId}/filter',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Check fixed net conditions on address
     * @param scopeId scopeId
     * @param streetName
     * @param city
     * @param streetNumber
     * @param entrance
     * @param latitude
     * @param longitude
     * @returns FixedNetResponse Successful request
     * @throws ApiError
     */
    public static getFixedNet(
        scopeId: any,
        streetName?: string,
        city?: string,
        streetNumber?: string,
        entrance?: string,
        latitude?: string,
        longitude?: string,
    ): CancelablePromise<FixedNetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/mybd/fixednet/{scopeId}',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'streetName': streetName,
                'city': city,
                'streetNumber': streetNumber,
                'entrance': entrance,
                'latitude': latitude,
                'longitude': longitude,
            },
        });
    }

}