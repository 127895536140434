/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListVpnsResponseDTO } from '../models/ListVpnsResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VpnControllerService {

    /**
     * Get all VPNs that an AccessProfile has access to.
     * @param scopeId
     * @param groupId Group ID
     * @returns ListVpnsResponseDTO OK
     * @throws ApiError
     */
    public static getVpnsOnAccessProfile(
        scopeId: string,
        groupId: number,
    ): CancelablePromise<ListVpnsResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/vpns',
            path: {
                'scopeId': scopeId,
                'groupId': groupId,
            },
        });
    }

}