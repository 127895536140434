/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetInAgreementIdsResponseDto } from '../models/GetInAgreementIdsResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InAgreementsControllerService {

    /**
     * Gets the IN agreements
     * User must have PUI: 'IN_AHS'
     * @param scopeId
     * @returns GetInAgreementIdsResponseDto Successful request
     * @throws ApiError
     */
    public static getInAgreements2(
        scopeId: string,
    ): CancelablePromise<GetInAgreementIdsResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/inagreements',
            path: {
                'scopeId': scopeId,
            },
        });
    }

}