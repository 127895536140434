/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmnControllerImplService {

    /**
     * Returns productItems for EMN subscriptions
     * @param scopeId Customer identification number
     * @param uId Subscription uid
     * @returns any Success
     * @throws ApiError
     */
    public static getProductItemsUsingGet(
        scopeId: string,
        uId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/addons/{uId}',
            path: {
                'scopeId': scopeId,
                'uId': uId,
            },
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}