/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangePassword } from '../models/ChangePassword';
import type { UserDetails } from '../models/UserDetails';
import type { UserDetailsBankId } from '../models/UserDetailsBankId';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ScopedUserAccountEamControllerService {

    /**
     * Change password
     * @param scopeId
     * @param requestBody
     * @returns any Successful request
     * @throws ApiError
     */
    public static userPassword(
        scopeId: string,
        requestBody: ChangePassword,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/scope/{scopeId}/userpassword',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update user details
     * @param scopeId
     * @param requestBody
     * @returns any Successful request
     * @throws ApiError
     */
    public static userDetails(
        scopeId: string,
        requestBody: UserDetails,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/scope/{scopeId}/userdetails',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update user details
     * @param scopeId
     * @param requestBody
     * @returns any Successful request
     * @throws ApiError
     */
    public static userDetailsBankId(
        scopeId: string,
        requestBody: UserDetailsBankId,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/scope/{scopeId}/userdetails/bankid',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get user details
     * @param scopeId
     * @returns any Successful request
     * @throws ApiError
     */
    public static getUser(
        scopeId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/scope/{scopeId}/user',
            path: {
                'scopeId': scopeId,
            },
        });
    }

}