/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetApplicationRequestsForUserResponse } from '../models/GetApplicationRequestsForUserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApplicationRequestControllerService {

    /**
     * @param scopeId
     * @returns GetApplicationRequestsForUserResponse OK
     * @throws ApiError
     */
    public static getApplicationsForLoggedInUser(
        scopeId: string,
    ): CancelablePromise<GetApplicationRequestsForUserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/applicationrequests',
            path: {
                'scopeId': scopeId,
            },
        });
    }

}