/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeSuperUserRequestDTO } from '../models/ChangeSuperUserRequestDTO';
import type { GetCompositionResponseDTO } from '../models/GetCompositionResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerGroupsControllerService {

    /**
     * @param groupId
     * @param scopeId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static changeSuperUser(
        groupId: number,
        scopeId: string,
        requestBody: ChangeSuperUserRequestDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/{scopeId}/customergroups/{groupId}/superuser',
            path: {
                'groupId': groupId,
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Read the composition of a CustomerGroup
     * @param groupId
     * @param scopeId
     * @returns GetCompositionResponseDTO OK
     * @throws ApiError
     */
    public static getComposition(
        groupId: number,
        scopeId: string,
    ): CancelablePromise<GetCompositionResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/{scopeId}/customergroups/{groupId}/composition',
            path: {
                'groupId': groupId,
                'scopeId': scopeId,
            },
        });
    }

}