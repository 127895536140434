/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StatusMessage } from '../models/StatusMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ValidationControllerService {

    /**
     * Validate organisation data for scopeid
     * @param scopeId
     * @param key
     * @param versionNr
     * @param requestBody
     * @returns StatusMessage OK
     * @throws ApiError
     */
    public static save1(
        scopeId: string,
        key: string,
        versionNr: string,
        requestBody: object,
    ): CancelablePromise<StatusMessage> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/misc/{scopeId}/organisation/{key}/{versionNr}',
            path: {
                'scopeId': scopeId,
                'key': key,
                'versionNr': versionNr,
            },
            body: requestBody,
            errors: {
                400: `Bad request`,
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

}