/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataUI } from '../models/DataUI';
import type { StatusMessage } from '../models/StatusMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublicControllerService {

    /**
     * Save data for scopeid
     * @param scopeId
     * @param key
     * @param versionNr
     * @param requestBody
     * @returns StatusMessage OK
     * @throws ApiError
     */
    public static save(
        scopeId: string,
        key: string,
        versionNr: string,
        requestBody: object,
    ): CancelablePromise<StatusMessage> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/misc/{scopeId}/save/{key}/{versionNr}',
            path: {
                'scopeId': scopeId,
                'key': key,
                'versionNr': versionNr,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                403: `Access denied`,
                500: `Internal server error.`,
            },
        });
    }

    /**
     * Get data for scopeid
     * @param scopeId
     * @param key
     * @param versionNr
     * @returns DataUI OK
     * @throws ApiError
     */
    public static getData(
        scopeId: string,
        key: string,
        versionNr: string,
    ): CancelablePromise<DataUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/misc/{scopeId}/get/{key}/{versionNr}',
            path: {
                'scopeId': scopeId,
                'key': key,
                'versionNr': versionNr,
            },
            errors: {
                400: `Bad request`,
                403: `Access denied`,
                500: `Internal server error`,
            },
        });
    }

}