/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ValidatorResponse } from '../models/ValidatorResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ValidationControllerService {

    /**
     * List the supported validation types
     * @param scopeId scopeId
     * @returns string Successful request
     * @throws ApiError
     */
    public static listValidationTypes(
        scopeId: any,
    ): CancelablePromise<Array<'EMAIL' | 'PERSONNUMBER' | 'ORGNUMBER' | 'IPADDRESS' | 'NETWORK' | 'PHONENUMBER' | 'SIMCARD' | 'TELIASIMCARD' | 'AGREEMENTNUMBER' | 'MSISDN'>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/validate/{scopeId}',
            path: {
                'scopeId': scopeId,
            },
        });
    }

    /**
     * Validate value of type against common-domain-lib
     * @param scopeId scopeId
     * @param requestBody
     * @returns ValidatorResponse Successful request
     * @throws ApiError
     */
    public static validate(
        scopeId: any,
        requestBody: Record<string, string>,
    ): CancelablePromise<ValidatorResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/validate/{scopeId}',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}