/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BalanceDetailsResponseDTO } from '../models/BalanceDetailsResponseDTO';
import type { BalanceItemsResponseDTO } from '../models/BalanceItemsResponseDTO';
import type { BalanceItemsResponseWrapperDTO } from '../models/BalanceItemsResponseWrapperDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileBalancesControllerService {

    /**
     * Get balances. User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param c2BUid
     * @param lang
     * @returns BalanceItemsResponseDTO Successful request
     * @throws ApiError
     */
    public static getBalancesV2(
        scopeId: string,
        c2BUid: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<BalanceItemsResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/mobile-subscriptions/{c2bUid}/v2/balances',
            path: {
                'scopeId': scopeId,
                'c2bUid': c2BUid,
            },
            query: {
                'lang': lang,
            },
        });
    }

    /**
     * @deprecated
     * Get balances. User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param c2BUid
     * @returns BalanceItemsResponseWrapperDTO Successful request
     * @throws ApiError
     */
    public static getBalances(
        scopeId: string,
        c2BUid: string,
    ): CancelablePromise<BalanceItemsResponseWrapperDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/mobile-subscriptions/{c2bUid}/balances',
            path: {
                'scopeId': scopeId,
                'c2bUid': c2BUid,
            },
        });
    }

    /**
     * Get balances details. User must have 'PRODUCT_DISPLAY' to access this resource
     * @param scopeId
     * @param c2BUid
     * @returns BalanceDetailsResponseDTO Successful request
     * @throws ApiError
     */
    public static getBalancesDetails(
        scopeId: string,
        c2BUid: string,
    ): CancelablePromise<BalanceDetailsResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/mobile-subscriptions/{c2bUid}/balances/details',
            path: {
                'scopeId': scopeId,
                'c2bUid': c2BUid,
            },
        });
    }

}