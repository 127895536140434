/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SlaReportCasesResponse } from '../models/SlaReportCasesResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SlaReportCasesService {

    /**
     * Get SLA report cases
     * @param xAuthScopeId
     * @param tscId
     * @param createdFrom Date formats: YYYY-MM-DD
     * @param createdTo Date formats: YYYY-MM-DD
     * @param resolvedFrom Date formats: YYYY-MM-DD
     * @param resolvedTo Date formats: YYYY-MM-DD
     * @param sortBy
     * @param sortDirection
     * @param size
     * @param start
     * @param searchCaseId
     * @param searchCustomerReference
     * @param searchOrganizationNumber
     * @param searchOrganizationName
     * @param searchSubscriptionProductName
     * @param searchSubscriptionId
     * @param searchSubscriptionNumber
     * @param searchServiceObjectName
     * @param searchSubscriptionAlias
     * @param searchSubscriptionStreet
     * @param searchSubscriptionCity
     * @param searchSubscriptionCountry
     * @param searchShortDescription
     * @param searchDescription
     * @param searchCloseNotes
     * @param searchCloseCode
     * @param searchCreatedOn
     * @param searchResolvedOn
     * @param searchDuration
     * @param searchBusinessElapsedTime
     * @param searchClosedOn
     * @param searchGuaranteedTime
     * @param searchReportingPerson
     * @param searchImpact
     * @param searchUrgency
     * @param searchPriority
     * @param searchCalculateSla
     * @param searchHasBreachedSla
     * @returns SlaReportCasesResponse OK
     * @throws ApiError
     */
    public static getSlaReportCases(
        xAuthScopeId: any,
        tscId?: string,
        createdFrom?: string,
        createdTo?: string,
        resolvedFrom?: string,
        resolvedTo?: string,
        sortBy?: string,
        sortDirection?: 'ASC' | 'DESC',
        size?: number,
        start?: number,
        searchCaseId?: string,
        searchCustomerReference?: string,
        searchOrganizationNumber?: string,
        searchOrganizationName?: string,
        searchSubscriptionProductName?: string,
        searchSubscriptionId?: string,
        searchSubscriptionNumber?: string,
        searchServiceObjectName?: string,
        searchSubscriptionAlias?: string,
        searchSubscriptionStreet?: string,
        searchSubscriptionCity?: string,
        searchSubscriptionCountry?: string,
        searchShortDescription?: string,
        searchDescription?: string,
        searchCloseNotes?: string,
        searchCloseCode?: string,
        searchCreatedOn?: string,
        searchResolvedOn?: string,
        searchDuration?: string,
        searchBusinessElapsedTime?: string,
        searchClosedOn?: string,
        searchGuaranteedTime?: string,
        searchReportingPerson?: string,
        searchImpact?: number,
        searchUrgency?: number,
        searchPriority?: number,
        searchCalculateSla?: boolean,
        searchHasBreachedSla?: boolean,
    ): CancelablePromise<SlaReportCasesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/sla-report-cases',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'tscId': tscId,
                'createdFrom': createdFrom,
                'createdTo': createdTo,
                'resolvedFrom': resolvedFrom,
                'resolvedTo': resolvedTo,
                'sortBy': sortBy,
                'sortDirection': sortDirection,
                'size': size,
                'start': start,
                'search.caseId': searchCaseId,
                'search.customerReference': searchCustomerReference,
                'search.organizationNumber': searchOrganizationNumber,
                'search.organizationName': searchOrganizationName,
                'search.subscriptionProductName': searchSubscriptionProductName,
                'search.subscriptionId': searchSubscriptionId,
                'search.subscriptionNumber': searchSubscriptionNumber,
                'search.serviceObjectName': searchServiceObjectName,
                'search.subscriptionAlias': searchSubscriptionAlias,
                'search.subscriptionStreet': searchSubscriptionStreet,
                'search.subscriptionCity': searchSubscriptionCity,
                'search.subscriptionCountry': searchSubscriptionCountry,
                'search.shortDescription': searchShortDescription,
                'search.description': searchDescription,
                'search.closeNotes': searchCloseNotes,
                'search.closeCode': searchCloseCode,
                'search.createdOn': searchCreatedOn,
                'search.resolvedOn': searchResolvedOn,
                'search.duration': searchDuration,
                'search.businessElapsedTime': searchBusinessElapsedTime,
                'search.closedOn': searchClosedOn,
                'search.guaranteedTime': searchGuaranteedTime,
                'search.reportingPerson': searchReportingPerson,
                'search.impact': searchImpact,
                'search.urgency': searchUrgency,
                'search.priority': searchPriority,
                'search.calculateSla': searchCalculateSla,
                'search.hasBreachedSla': searchHasBreachedSla,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Export SLA report cases
     * @param xAuthScopeId
     * @param tscId
     * @param createdFrom Date formats: YYYY-MM-DD
     * @param createdTo Date formats: YYYY-MM-DD
     * @param resolvedFrom Date formats: YYYY-MM-DD
     * @param resolvedTo Date formats: YYYY-MM-DD
     * @param sortBy
     * @param sortDirection
     * @param size
     * @param start
     * @param searchCaseId
     * @param searchCustomerReference
     * @param searchOrganizationNumber
     * @param searchOrganizationName
     * @param searchSubscriptionProductName
     * @param searchSubscriptionId
     * @param searchSubscriptionNumber
     * @param searchServiceObjectName
     * @param searchSubscriptionAlias
     * @param searchSubscriptionStreet
     * @param searchSubscriptionCity
     * @param searchSubscriptionCountry
     * @param searchShortDescription
     * @param searchDescription
     * @param searchCloseNotes
     * @param searchCloseCode
     * @param searchCreatedOn
     * @param searchResolvedOn
     * @param searchDuration
     * @param searchBusinessElapsedTime
     * @param searchClosedOn
     * @param searchGuaranteedTime
     * @param searchReportingPerson
     * @param searchImpact
     * @param searchUrgency
     * @param searchPriority
     * @param searchCalculateSla
     * @param searchHasBreachedSla
     * @returns string OK
     * @throws ApiError
     */
    public static exportSlaReportCases(
        xAuthScopeId: any,
        tscId?: string,
        createdFrom?: string,
        createdTo?: string,
        resolvedFrom?: string,
        resolvedTo?: string,
        sortBy?: string,
        sortDirection?: 'ASC' | 'DESC',
        size?: number,
        start?: number,
        searchCaseId?: string,
        searchCustomerReference?: string,
        searchOrganizationNumber?: string,
        searchOrganizationName?: string,
        searchSubscriptionProductName?: string,
        searchSubscriptionId?: string,
        searchSubscriptionNumber?: string,
        searchServiceObjectName?: string,
        searchSubscriptionAlias?: string,
        searchSubscriptionStreet?: string,
        searchSubscriptionCity?: string,
        searchSubscriptionCountry?: string,
        searchShortDescription?: string,
        searchDescription?: string,
        searchCloseNotes?: string,
        searchCloseCode?: string,
        searchCreatedOn?: string,
        searchResolvedOn?: string,
        searchDuration?: string,
        searchBusinessElapsedTime?: string,
        searchClosedOn?: string,
        searchGuaranteedTime?: string,
        searchReportingPerson?: string,
        searchImpact?: number,
        searchUrgency?: number,
        searchPriority?: number,
        searchCalculateSla?: boolean,
        searchHasBreachedSla?: boolean,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/sla-report-cases/export',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'tscId': tscId,
                'createdFrom': createdFrom,
                'createdTo': createdTo,
                'resolvedFrom': resolvedFrom,
                'resolvedTo': resolvedTo,
                'sortBy': sortBy,
                'sortDirection': sortDirection,
                'size': size,
                'start': start,
                'search.caseId': searchCaseId,
                'search.customerReference': searchCustomerReference,
                'search.organizationNumber': searchOrganizationNumber,
                'search.organizationName': searchOrganizationName,
                'search.subscriptionProductName': searchSubscriptionProductName,
                'search.subscriptionId': searchSubscriptionId,
                'search.subscriptionNumber': searchSubscriptionNumber,
                'search.serviceObjectName': searchServiceObjectName,
                'search.subscriptionAlias': searchSubscriptionAlias,
                'search.subscriptionStreet': searchSubscriptionStreet,
                'search.subscriptionCity': searchSubscriptionCity,
                'search.subscriptionCountry': searchSubscriptionCountry,
                'search.shortDescription': searchShortDescription,
                'search.description': searchDescription,
                'search.closeNotes': searchCloseNotes,
                'search.closeCode': searchCloseCode,
                'search.createdOn': searchCreatedOn,
                'search.resolvedOn': searchResolvedOn,
                'search.duration': searchDuration,
                'search.businessElapsedTime': searchBusinessElapsedTime,
                'search.closedOn': searchClosedOn,
                'search.guaranteedTime': searchGuaranteedTime,
                'search.reportingPerson': searchReportingPerson,
                'search.impact': searchImpact,
                'search.urgency': searchUrgency,
                'search.priority': searchPriority,
                'search.calculateSla': searchCalculateSla,
                'search.hasBreachedSla': searchHasBreachedSla,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                429: `Too Many Requests`,
                500: `Internal Server Error`,
            },
        });
    }

}